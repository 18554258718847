/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";
import { RegisterOptions } from "react-hook-form";

import { Popover, PopoverRef } from "common/components/popovers";
import { countryPhones } from "common/constants";
import { PhoneNumber } from "common/types";

import { validatePhoneNumber } from "../../validators";
import { InputGeneric, InputWrapper } from "../components";
import { TInputPhoneNumber } from "../types";
import {
  PhoneNumberReference,
  SearchSelect,
  PhoneNumberListItem,
} from "./components";

const phoneDataList: { data: PhoneNumber; component: JSX.Element }[] =
  Object.values(countryPhones).map((v) => ({
    data: v,
    component: <PhoneNumberListItem {...v} />,
  }));

export const InputPhoneNumber: React.FC<TInputPhoneNumber> = ({
  form,
  name,
  label,
  showSkeleton,
  isReset,
  size,
  labelSize,
  helperText,
  disabled,
  ...inputProps
}) => {
  const dialFormName = name.replace("number", "data");

  const popoverRef = useRef<PopoverRef>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const selectedDial: PhoneNumber =
    form.getValues(dialFormName) || phoneDataList[0].data;

  const { onChange: onChangeDial } = form.register(dialFormName);

  useEffect(() => {
    if (!form.getValues(dialFormName)) {
      form.clearErrors(dialFormName);
      onChangeDial({
        target: {
          name: dialFormName,
          value: phoneDataList[0].data,
        },
      });
    }
  }, []);

  const onSelect = (value: PhoneNumber) => {
    popoverRef.current?.closePopover();
    inputRef.current?.focus();
    form.clearErrors(dialFormName);
    onChangeDial({
      target: {
        name: dialFormName,
        value,
      },
    });
  };

  const registerOptions: RegisterOptions<any, string> = {
    ...inputProps.registerOptions,
    validate: {
      ...inputProps.registerOptions?.validate,
      validPhoneNumber: (value) =>
        !form.getValues(name) ||
        validatePhoneNumber(value, selectedDial.dial, selectedDial.countryCode),
    },
  };

  return (
    <InputWrapper
      form={form}
      name={name}
      label={label}
      showSkeleton={showSkeleton}
      isReset={isReset}
      size={size}
      labelSize={labelSize}
      helperText={helperText}
      disabled={disabled}
      wrapperClassName={{ "p-0": true }}
    >
      <div className="flex items-center justify-center bg-white-600">
        <Popover
          offset={[0, 7]}
          ref={popoverRef}
          testId="phone-number"
          reference={
            <PhoneNumberReference countryCode={selectedDial.countryCode} />
          }
          popover={
            <div className="w-[400px]">
              <SearchSelect<PhoneNumber>
                selected={selectedDial}
                list={phoneDataList}
                searchableFields={["countryCode", "countryName", "dial"]}
                onSelect={onSelect}
              />
            </div>
          }
        />
      </div>
      <span>{selectedDial.dial}</span>
      <InputGeneric
        form={form}
        name={name}
        disabled={disabled}
        {...inputProps}
        registerOptions={registerOptions}
        assignRef={(e) => {
          inputRef.current = e;
        }}
        type="tel"
        onKeyDown={(evt) =>
          ["e", "E", "-", "+", ".", ","].includes(evt.key) &&
          evt.preventDefault()
        }
      />
    </InputWrapper>
  );
};

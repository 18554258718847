export const Cluster: React.FC<{
  text: string;
  testId?: string;
  additionalClasses?: string;
}> = ({ text, testId = "cluster-label", additionalClasses = "w-fit" }) => (
  <div
    data-testid={testId}
    className={`cursor-default px-1.5 py-0.5 flex justify-center items-center text-10 text-gray-800 border border-white-600 rounded-[3px] bg-white-0 ${additionalClasses}`}
  >
    {text}
  </div>
);

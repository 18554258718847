import { Icon, IIcon } from "./Icon/Icon";

interface Props extends IIcon {
  label?: string | JSX.Element;
}

export const IconWithLabel: React.FC<Props> = ({ label, ...iconProps }) => (
  <div className="flex items-center gap-x-2">
    <Icon {...iconProps} />
    {label && <div>{label}</div>}
  </div>
);

import classNames from "classnames";
import { useContext } from "react";

import { Icon } from "common/components/Icon";
import { PopoverContext } from "common/components/popovers/context";
import { Country } from "common/types";

export const PhoneNumberReference: React.FC<{ countryCode: Country }> = ({
  countryCode,
}) => {
  const { isOpen } = useContext(PopoverContext);
  return (
    <div
      className={classNames(
        "transition flex items-center gap-x-3 px-4 py-[13px] text-blue-800 w-[81px]",
      )}
    >
      <Icon name={countryCode} />
      <div>
        <Icon name={isOpen ? "caretUp" : "caretDown"} />
      </div>
    </div>
  );
};

import classNames from "classnames";
import { endOfDay, startOfMonth } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./style.scss";
import { DEFAULT_BACKEND_DATE_FORMAT } from "common/constants";
import { formatMessage } from "common/internationalization";
import { CalendarParamsForm } from "common/types";
import { getDateWithinRange } from "common/utils/dateRange";
import { format, parse } from "common/utils/dates";

import { Button } from "../buttons/Button";
import { validateDatePattern } from "../form/validators";
import { Tooltip } from "../Tooltip";
import { DateInputs, DatePickerHeader } from "./components";
import { DatePickerParams, DatePickerSelectType } from "./types";
import { useHandleDateRangeErrors } from "./utils";

export const DatePicker: React.FC<DatePickerParams> = ({
  form,
  dateFormat,
  monthsShown = 1,
  onCancel,
  onApply,
  disabledDayInfo,
  minDate,
  maxDate,
  disableStart,
  disableEnd,
  changeDateCallback,
  hideFooterButtons = false,
  ...props
}) => {
  const [selects, setSelects] = useState<DatePickerSelectType>(
    !disableStart && !disableEnd
      ? DatePickerSelectType.RANGE
      : disableStart
        ? disableEnd
          ? DatePickerSelectType.DISALBED
          : DatePickerSelectType.END
        : DatePickerSelectType.START,
  );
  const [selectedDay, setSetSelectedDay] = useState<Date | null>(null);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [dateRangeError, setDateRangeError] = useState<string | null>(null);
  const handleNewDateRangeErrors = useHandleDateRangeErrors(
    form,
    dateFormat,
    props.excludeDateIntervals,
    selects,
    setDateRangeError,
  );

  const { lower, upper, selector } = form.getValues();

  const onChangeDate = (
    date: Date | Date[] | [Date | null, Date | null] | null,
    change: DatePickerSelectType | null = null,
  ) => {
    if (selects === DatePickerSelectType.DISALBED) return;

    const whatToChange = change ?? selects;
    const newDateRange: CalendarParamsForm = { lower, upper, selector };
    form.clearErrors();
    setShowMonthYearPicker(false);
    setDateRangeError(null);

    // Selects range
    if (Array.isArray(date)) {
      const [start, end] = date;

      let newLower = "";
      if (start) {
        newLower = format(
          getDateWithinRange(start, { min: minDate, max: maxDate }),
          dateFormat,
        );
      }
      newDateRange.lower = newLower;

      form.setValue("lower", newLower);

      let newUpper = "";
      if (end) {
        newUpper = format(
          getDateWithinRange(end, { min: minDate, max: maxDate }),
          dateFormat,
        );
      }
      form.setValue("upper", newUpper);
      newDateRange.upper = newUpper;

      form.setValue("selector", undefined);
      newDateRange.selector = undefined;
      setSetSelectedDay(null);
    }
    // Selects start/end
    else if (whatToChange !== DatePickerSelectType.RANGE) {
      const newDate = date
        ? getDateWithinRange(date, { min: minDate, max: maxDate })
        : null;
      const otherDateToCompare =
        whatToChange === DatePickerSelectType.START ? upper : lower;
      const isOtherDateValid =
        otherDateToCompare &&
        typeof validateDatePattern(otherDateToCompare) !== "string";
      if (
        newDate &&
        isOtherDateValid &&
        (whatToChange === DatePickerSelectType.START
          ? newDate.getTime() >
            parse(otherDateToCompare, dateFormat, new Date()).getTime()
          : newDate.getTime() <
            parse(otherDateToCompare, dateFormat, new Date()).getTime())
      ) {
        const newLower =
          whatToChange === DatePickerSelectType.START
            ? upper || ""
            : format(newDate, dateFormat);
        form.setValue("lower", newLower);
        newDateRange.lower = newLower;

        const newUpper =
          whatToChange === DatePickerSelectType.START
            ? format(newDate, dateFormat)
            : lower || "";
        form.setValue("upper", newUpper);
        newDateRange.upper = newUpper;
      } else {
        const changedDate =
          whatToChange === DatePickerSelectType.START ? "lower" : "upper";
        const newDateValue = newDate ? format(newDate, dateFormat) : "";
        form.setValue(changedDate, newDateValue);
        newDateRange[changedDate] = newDateValue;
      }

      if (newDate && !disableStart && !disableEnd)
        setSelects(DatePickerSelectType.RANGE);
      setSetSelectedDay(null);
      form.setValue("selector", undefined);
      newDateRange.selector = undefined;
    }

    const error = handleNewDateRangeErrors(newDateRange);
    changeDateCallback?.(newDateRange, error, selects);
  };

  const selectDay = (
    date: Date | Date[] | [Date | null, Date | null] | null,
  ) => {
    if (Array.isArray(date)) setSetSelectedDay(date[0] ?? date[1]);
    else setSetSelectedDay(date);
    setShowMonthYearPicker(false);
  };

  const isDateOutOfRange = useCallback(
    (date?: Date) =>
      (date && maxDate && date.getTime() > endOfDay(maxDate)?.getTime()) ||
      (date && minDate && endOfDay(date).getTime() < minDate?.getTime()),
    [minDate, maxDate],
  );

  useEffect(
    () =>
      setSetSelectedDay(lower ? parse(lower, dateFormat, new Date()) : null),
    [selector],
  );

  const renderCustomHeader = useCallback(
    (headerProps: ReactDatePickerCustomHeaderProps) => (
      <DatePickerHeader
        {...headerProps}
        monthsShown={monthsShown}
        quickFiltersLayout={props.quickFiltersLayout}
        showMonthYearPicker={showMonthYearPicker}
        setShowMonthYearPicker={setShowMonthYearPicker}
      />
    ),
    [monthsShown, showMonthYearPicker, props.quickFiltersLayout],
  );

  const renderDayContents = useCallback((dayOfMonth: number, date?: Date) => {
    let content = "";
    let isActive = false;
    if (typeof disabledDayInfo === "string") content = disabledDayInfo;
    else if (typeof disabledDayInfo === "function") {
      const info = disabledDayInfo(date);
      content = info.content;
      isActive = !!info.isActive;
    }

    const dataTestId = date
      ? `day-${format(date, DEFAULT_BACKEND_DATE_FORMAT)}`
      : "everyday";

    return (
      <div>
        <Tooltip
          testId={`${dataTestId}-wrapper`}
          classNames={{ content: "z-[2001] max-w-[170px]" }}
          content={content}
          disabled={!isDateOutOfRange(date) && !isActive}
          trigger={
            <div data-testid={dataTestId} className="day">
              {dayOfMonth}
            </div>
          }
        />
      </div>
    );
  }, []);

  return (
    <div className="w-full">
      <DateInputs
        form={form}
        monthsShown={monthsShown}
        disableStart={disableStart}
        disableEnd={disableEnd}
        dateFormat={dateFormat}
        quickFiltersLayout={props.quickFiltersLayout}
        dateInputsLayout={props.dateInputsLayout}
        requiredFields={props.requiredFields}
        dateRangeError={dateRangeError}
        setSelects={setSelects}
        onChangeDate={onChangeDate}
      />
      <div
        data-testid="date-picker-calendar"
        className={classNames("flex w-full justify-center", {
          "date-picker-error": !!dateRangeError,
        })}
      >
        <ReactDatePicker
          onChange={(date) => {
            if (showMonthYearPicker) selectDay(date);
            else onChangeDate(date);
          }}
          monthsShown={showMonthYearPicker ? 1 : monthsShown}
          showMonthYearPicker={showMonthYearPicker}
          showFourColumnMonthYearPicker
          focusSelectedMonth
          forceShowMonthNavigation
          calendarStartDay={1}
          formatWeekDay={(day) => day.slice(0, 3)}
          selected={selectedDay}
          selectsRange={
            selects === DatePickerSelectType.RANGE &&
            !showMonthYearPicker &&
            !disableStart &&
            !disableEnd
          }
          selectsStart={
            (selects === DatePickerSelectType.START && !showMonthYearPicker) ||
            (!disableStart && disableEnd)
          }
          selectsEnd={
            (selects === DatePickerSelectType.END && !showMonthYearPicker) ||
            (!disableEnd && disableStart)
          }
          minDate={
            showMonthYearPicker && minDate ? startOfMonth(minDate) : minDate
          }
          maxDate={maxDate}
          renderCustomHeader={renderCustomHeader}
          renderDayContents={renderDayContents}
          {...props}
        />
      </div>
      <div
        className={classNames(
          "flex justify-end items-center gap-x-[10px] w-full",
          {
            hidden: hideFooterButtons,
          },
        )}
      >
        <Button
          styling="blue-outline-button"
          additionalClasses="px-8 py-[10px]"
          onClick={onCancel}
          dataTestId="cancel-date-picker"
        >
          {formatMessage("words.cancel")}
        </Button>
        <Button
          styling="blue-button"
          additionalClasses="px-8 py-[10px]"
          onClick={onApply}
          dataTestId="apply-date-picker"
        >
          {formatMessage("words.apply")}
        </Button>
      </div>
    </div>
  );
};

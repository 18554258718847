/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classNames from "classnames";
import { get } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { VOWSkeleton } from "common/components/VOWSkeleton";

import { LabelWithReset } from "../../components";
import { FormHelperText } from "../../components/FormHelperText";
import { TInputWrapper } from "../types";

type Props = React.PropsWithChildren<TInputWrapper> & {
  alwaysShowHelperArea?: boolean;
  trailingComponent?: JSX.Element;
};

export const InputWrapper: React.FC<Props> = ({
  form,
  name,
  label,
  showSkeleton,
  isReset,
  size,
  labelSize = "regular",
  helperText,
  disabled,
  wrapperClassName,
  children,
  onClick = () => {},
  alwaysShowHelperArea = true,
  trailingComponent,
  hideHelperText,
}) => {
  const handleReset = () => {
    form.resetField(name);
  };
  const errors = get(form.formState.errors, name, "");

  return (
    <div
      data-testid={`${name}-input-wrapper`}
      className="relative w-full flex flex-col"
      onClick={onClick}
    >
      {showSkeleton ? (
        <>
          <VOWSkeleton className="w-1/3 mb-2.5" height={22} />
          <VOWSkeleton height={40} />
        </>
      ) : (
        <div className="flex flex-col gap-y-1">
          <label>
            <div className="flex justify-between">
              {label && (
                <LabelWithReset
                  label={label}
                  hasChanged={!!isReset}
                  labelSize={labelSize}
                  reset={handleReset}
                />
              )}
              {trailingComponent}
            </div>
            <div
              className={twMerge(
                classNames(
                  "input input-focus-within flex items-center gap-x-2 overflow-hidden relative",
                  {
                    "w-[500px]": size === "standard",
                    "w-[305px] py-2": size === "short",
                    "border-white-100 cursor-not-allowed": disabled,
                    "border-red-100": errors,
                  },
                  wrapperClassName,
                ),
              )}
            >
              {children}
            </div>
          </label>
          {!hideHelperText &&
            (helperText || errors || alwaysShowHelperArea) && (
              <FormHelperText
                helperText={helperText}
                name={name}
                errors={errors}
              />
            )}
        </div>
      )}
    </div>
  );
};

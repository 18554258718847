/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useContext } from "react";

import { PopoverContext } from "common/components/popovers/context";
import { formatMessage } from "common/internationalization";

import { DropdownType, TDropdownOption } from "../../types";
import { areAllChildrenSelected, getParent } from "../../utils";
import {
  OptionSingle,
  OptionSingleGroup,
  OptionMultiple,
  OptionMultipleGroup,
} from "./components";

type DropdownOptionsProps<T> = {
  value?: string | number | (string | number)[];
  type: DropdownType;
  filteredOptions: TDropdownOption<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  searchPhrase: string;
  selected?: TDropdownOption<T> | TDropdownOption<T>[];
  testId: string;
  hideMainCheckboxes?: boolean;
  collapsingSubOptions?: boolean;
  unselectSingleChoiceOn?: boolean;
  checkboxPosition?: "left" | "right";
  selectAll?: boolean;
};

export const DropdownOptions = <T,>({
  value,
  type,
  filteredOptions,
  onChange,
  searchPhrase,
  selected,
  testId,
  hideMainCheckboxes,
  collapsingSubOptions = false,
  unselectSingleChoiceOn = false,
  checkboxPosition,
  selectAll,
}: DropdownOptionsProps<T>) => {
  const { togglePopover, isOpen } = useContext(PopoverContext);
  const indentLevel = 0;
  const selectSingleValue = (newValue?: string | number) => {
    onChange(newValue);
    togglePopover(!isOpen);
  };

  const selectMultipleValue = (
    options: TDropdownOption<T>[],
    op: "add" | "delete",
  ) => {
    const newValue = new Set<string | number>(
      (value as (string | number)[]) || [],
    );

    options.forEach((option) => {
      switch (op) {
        case "add": {
          if (option.children) {
            option.children.forEach((opt) => newValue.add(opt.value));
          }
          newValue.add(option.value);
          break;
        }
        case "delete": {
          if (option.children) {
            (option.children || []).forEach((opt) =>
              newValue.delete(opt.value),
            );
          } else {
            // Remove parent when removing first of all children selected
            const parent = getParent(
              option.value,
              selected as TDropdownOption<T>[],
            );
            if (
              areAllChildrenSelected<T>(
                parent!,
                selected as TDropdownOption<T>[],
              )
            ) {
              newValue.delete(parent!.value);
            }
          }
          newValue.delete(option.value);
          break;
        }
        default: {
          break;
        }
      }
    });

    onChange([...newValue]);
  };

  if (!filteredOptions.length) {
    return (
      <li className="text-gray-400 pl-4 pr-4">
        {searchPhrase
          ? formatMessage("select.noResults.option")
          : formatMessage("select.empty.option")}
      </li>
    );
  }

  if (["singleChoice", "singleCreatable"].includes(type)) {
    return (
      <>
        {filteredOptions.map((option: TDropdownOption<T>) => {
          if (option.children) {
            return (
              <OptionSingleGroup<T>
                key={option.value}
                fieldTestId={testId}
                option={option}
                setSelected={selectSingleValue}
                selected={selected as TDropdownOption<T>}
                collapsingSubOptions={collapsingSubOptions}
                unselectSingleChoiceOn={unselectSingleChoiceOn}
                checkboxPosition={checkboxPosition}
              />
            );
          }
          return (
            <OptionSingle<T>
              key={option.value}
              fieldTestId={testId}
              option={option}
              setSelected={selectSingleValue}
              selected={selected as TDropdownOption<T>}
              unselectSingleChoiceOn={unselectSingleChoiceOn}
              checkboxPosition={checkboxPosition}
            />
          );
        })}
      </>
    );
  }
  if (type === "multipleChoice") {
    return (
      <div>
        {selectAll && (
          <OptionMultiple<T>
            key="select-all"
            fieldTestId={testId}
            option={{
              label: formatMessage("words.selectAll"),
              labelEl: (
                <div className="w-full text-gray-800 text-left font-bold">
                  {formatMessage("words.selectAll")}
                </div>
              ),
              value: "select-all",
              children: filteredOptions,
            }}
            setSelected={(options, op) => {
              selectMultipleValue(filteredOptions, op);
            }}
            selected={selected as TDropdownOption<T>[]}
            indentLevel={0}
            checkboxPosition={checkboxPosition}
          />
        )}
        {filteredOptions.map((option: TDropdownOption<T>) => {
          if (option.children) {
            return (
              <OptionMultipleGroup<T>
                key={option.value}
                fieldTestId={testId}
                option={option}
                setSelected={selectMultipleValue}
                selected={selected as TDropdownOption<T>[]}
                hideMainCheckboxes={hideMainCheckboxes}
                collapsingSubOptions={collapsingSubOptions}
                indentLevel={indentLevel}
                checkboxPosition={checkboxPosition}
              />
            );
          }
          return (
            <OptionMultiple<T>
              key={option.value}
              fieldTestId={testId}
              option={option}
              setSelected={selectMultipleValue}
              selected={selected as TDropdownOption<T>[]}
              indentLevel={indentLevel}
              checkboxPosition={checkboxPosition}
            />
          );
        })}
      </div>
    );
  }
  return <li>Not supported</li>;
};

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Provides interface for no form components, so we can reuse them.
 * @param selected
 * @param setSelected
 * @returns UseFormReturn-ish object
 */
export const mapNoFormToForm = <T>(
  selected: T,
  defaultValue: T,
  setSelected: (value: T) => void,
): any => ({
  formState: {
    errors: {},
    dirtyFields: {},
  },
  register: () => ({
    ref: () => {},
    onChange: (event: { target: { value: T } }) => {
      setSelected(event.target.value);
    },
  }),
  getValues: () => selected,
  setValue: (_: string, value: T) => {
    setSelected(value);
  },
  resetField: () => {
    setSelected(defaultValue);
  },
  clearErrors: () => {},
});

export const autosizeTextArea = (ref: React.MutableRefObject<any>) => {
  if (ref && ref.current) {
    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    ref.current.style.height = "0px";
    const { scrollHeight } = ref.current;

    // We then set the height directly, outside of the render loop
    // Trying to set this with state or a ref will product an incorrect value.
    ref.current.style.height = `${scrollHeight}px`;
  }
};

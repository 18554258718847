import { TDropdownOption } from "common/components/form/Dropdown/types";
import { ProductCategory } from "modules/Strategies/types";

export const mapDataObjectToCategories = (
  data: ProductCategory[],
): TDropdownOption[] =>
  Object(data).map((el: ProductCategory) => ({
    value: el.amz_id,
    label: el.name,
    children:
      el.subcategories.length > 0
        ? el.subcategories.map((subheader) => ({
            value: subheader.amz_id,
            label: subheader.name,
          }))
        : [{ value: el.amz_id, label: el.name }],
  }));

import { useEffect, useRef, useState } from "react";

import { Icon } from "common/components/Icon";
import { Expandable } from "common/components/Transitions";

import { TDropdownOption } from "../../../types";
import { OptionSingle } from "./OptionSingle";

type OptionGroupProps<T> = {
  option: TDropdownOption<T>;
  fieldTestId: string;
  setSelected: (value?: string | number) => void;
  selected?: TDropdownOption<T>;
  collapsingSubOptions?: boolean;
  unselectSingleChoiceOn?: boolean;
  checkboxPosition?: "left" | "right";
};

export const OptionSingleGroup = <T,>({
  option,
  fieldTestId,
  setSelected,
  selected,
  collapsingSubOptions = false,
  unselectSingleChoiceOn = false,
  checkboxPosition = "left",
}: OptionGroupProps<T>) => {
  const [isExpanded, setIsExpanded] = useState(!collapsingSubOptions);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // wait for Expandable to show elements
    setTimeout(() => {
      if (collapsingSubOptions && isExpanded && containerRef.current) {
        containerRef.current.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        });
      }
    }, 100);
  }, [isExpanded]);

  return (
    <div ref={containerRef}>
      <li key={option.value} className="relative pl-2 pr-2 py-2 font-bold">
        <div>{option.labelEl || option.label}</div>
        {collapsingSubOptions && (
          <button
            aria-label="expand"
            className="absolute right-2 z-10 top-1/2 -translate-y-1/2"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <Icon name={isExpanded ? "caretUp" : "caretDown"} />
          </button>
        )}
      </li>
      <Expandable isOpen={isExpanded}>
        {(option.children || []).map((opt: TDropdownOption<T>) => (
          <OptionSingle<T>
            key={opt.value}
            fieldTestId={fieldTestId}
            option={opt}
            setSelected={setSelected}
            selected={selected}
            unselectSingleChoiceOn={unselectSingleChoiceOn}
            checkboxPosition={checkboxPosition}
          />
        ))}
      </Expandable>
    </div>
  );
};

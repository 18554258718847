import {
  format as dateFnsFormat,
  parse as dateFnsParse,
  parseISO as dateFnsParseISO,
  FormatOptions,
  isValid,
  Locale,
  ParseOptions,
} from "date-fns";
import * as locales from "date-fns/locale";

import {
  DEFAULT_BACKEND_DATE_CHART_FORMAT,
  DEFAULT_BACKEND_DATE_FORMAT,
  DEFAULT_BACKEND_DATETIME_FORMAT,
  DEFAULT_BACKEND_DATETIME_FORMAT_UTC,
  DEFAULT_DATE_ISO,
} from "common/constants";

export const format = (
  date: Date | number | string,
  formatStr: string,
  options?: FormatOptions,
): string =>
  dateFnsFormat(date, formatStr, { locale: getLocale(), ...options });

export const parse = (
  dateStr: string,
  formatStr: string,
  referenceDate: Date | number | string,
  options?: ParseOptions & { silent?: boolean; withoutFallback?: boolean },
): Date => {
  const date =
    formatStr === DEFAULT_DATE_ISO
      ? dateFnsParseISO(dateStr, {
          locale: getLocale(),
          ...options,
        })
      : dateFnsParse(dateStr, formatStr, referenceDate, {
          locale: getLocale(),
          ...options,
        });
  if (isValid(date) || options?.withoutFallback) return date;
  if (!options?.silent)
    // eslint-disable-next-line no-console
    console.error(
      `Error while parsing given string: ${dateStr} with format: ${formatStr}`,
    );

  const fallbackFormats = [
    DEFAULT_BACKEND_DATE_FORMAT,
    DEFAULT_BACKEND_DATETIME_FORMAT,
    DEFAULT_BACKEND_DATETIME_FORMAT_UTC,
    DEFAULT_BACKEND_DATE_CHART_FORMAT,
  ];

  const validDate = fallbackFormats.find((f) => {
    const date = dateFnsParse(dateStr, f, referenceDate, {
      locale: getLocale(),
      ...options,
    });
    return isValid(date);
  });

  if (validDate)
    return dateFnsParse(dateStr, validDate, referenceDate, {
      locale: getLocale(),
      ...options,
    });

  return new Date(dateStr);
};

export const minDate = (d1?: Date, d2?: Date): Date => {
  if (!d1 && !d2) return new Date();
  if (!d1) return d2 as Date;
  if (!d2) return d1 as Date;
  if (d1 < d2) return d1;
  return d2;
};

export const maxDate = (d1?: Date, d2?: Date): Date => {
  if (!d1 && !d2) return new Date();
  if (!d1) return d2 as Date;
  if (!d2) return d1 as Date;
  if (d1 > d2) return d1;
  return d2;
};

export const getLocale = () => {
  const locale = navigator.language.replace("-", "");
  const rootLocale = locale.substring(0, 2);
  return (
    (locales as Record<string, Locale>)[locale] ||
    (locales as Record<string, Locale>)[rootLocale]
  );
};

export const formatLocaleDate = (
  d: Date | number,
  options: { long?: boolean; withTime?: boolean } = {
    long: false,
    withTime: false,
  },
): string => {
  if (options.withTime) return format(d, options.long ? "PPpp" : "Pp");
  return format(d, options.long ? "PPP" : "P");
};

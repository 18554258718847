import classNames from "classnames";
import { useContext } from "react";

import { Button } from "common/components/buttons/Button";
import { Tooltip } from "common/components/Tooltip";
import { useFormatMessage } from "common/internationalization";

import { ModalContext } from "../context";

type Props = {
  closeText?: string;
  closeTestId?: string;
  successText?: string;
  fullWidth?: boolean;
  isSuccessDisabled?: boolean;
  isSuccessLoading?: boolean;
  successDisabledTooltip?: string;
  closeAction?: () => void;
  mode?: "warning" | "information";
  outlineFirstButton?: boolean;
};

export const ModalFooter: React.FC<Props> = ({
  closeText,
  closeTestId,
  successText,
  fullWidth = false,
  isSuccessDisabled = false,
  isSuccessLoading = false,
  successDisabledTooltip = "",
  closeAction,
  mode = "warning",
  outlineFirstButton = false,
}) => {
  const { onClose, onSuccess } = useContext(ModalContext);
  const formatMessage = useFormatMessage();

  return (
    <div
      className={classNames("justify-end items-center gap-x-4 w-full", {
        "grid grid-cols-2": mode === "warning",
        "grid grid-cols-1": mode === "information",
      })}
    >
      <Button
        styling={
          mode === "information" && !outlineFirstButton
            ? "blue-button"
            : "blue-outline-button"
        }
        onClick={closeAction ?? onClose}
        additionalClasses={classNames({ grow: fullWidth })}
        dataTestId={closeTestId || closeText || "cancel"}
        disabled={isSuccessLoading}
      >
        {closeText || formatMessage("words.cancel")}
      </Button>
      {mode === "warning" && (
        <Tooltip
          testId="modal-success-disabled"
          content={<div className="w-[200px]">{successDisabledTooltip}</div>}
          disabled={!isSuccessDisabled || !successDisabledTooltip}
          classNames={{
            wrapper: classNames({ grow: fullWidth }),
          }}
          trigger={
            <Button
              styling="blue-button"
              onClick={onSuccess}
              additionalClasses="w-full"
              dataTestId={successText || "save"}
              disabled={isSuccessDisabled || isSuccessLoading}
              loading={isSuccessLoading}
            >
              {successText || formatMessage("words.save")}
            </Button>
          }
        />
      )}
    </div>
  );
};

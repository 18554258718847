import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

import useTailwind from "common/styles/tailwind-theme";

// must be in ascending order (from smallest to largest screen)
const screenNames = [
  "desktop-xl",
  "desktop-l",
  "desktop-m",
  "desktop-s",
  "tablet",
] as const;
const pxPattern = /^\d+(px)$/;

type ScreenName = (typeof screenNames)[number];
type ScreenEvents = Partial<Record<ScreenName, () => void>>;

export const useResizeScreenListener = (props: ScreenEvents) => {
  const tailwind = useTailwind();
  const { screens } = tailwind.theme;
  const [currentScreen, setCurrentScreen] = useState<ScreenName>();
  const { width, height } = useWindowSize();

  useEffect(() => {
    for (let i = 0; i < screenNames.length; i += 1) {
      const screenName = screenNames[i];
      const screenWidthStr = screens[screenName];
      if (pxPattern.test(screenWidthStr)) {
        const screenWidth = parseInt(screenWidthStr.slice(0, -2), 10);
        if (width >= screenWidth) {
          if (currentScreen !== screenName) {
            setCurrentScreen(screenName);
            props[screenName]?.();
          }
          break;
        }
      }
    }
  }, [width, height]);
};

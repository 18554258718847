/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserPreferences = {
  readonly id?: string;
  goal: UserPreferences.goal;
  pod_metrics?: Array<'acos' | 'added_to_shopping_cart' | 'added_to_shopping_cart_cpa' | 'application' | 'application_cpa' | 'c_roas' | 'checkout' | 'checkout_cpa' | 'click_throughs' | 'combined_product_sales' | 'combined_purchases' | 'combined_units_sold' | 'contact' | 'contact_cpa' | 'conversions' | 'cpa' | 'cpdpv' | 'cpsu' | 'cpvc' | 'ctr' | 'cvr' | 'detail_page_views' | 'dpvr' | 'ecpc' | 'ecpm' | 'impressions' | 'lead' | 'lead_cpa' | 'measurable_impressions' | 'new_to_brand_purchases' | 'new_to_brand_roas' | 'new_to_brand_sales' | 'new_to_brand_units_sold' | 'off_amazon_product_sales' | 'off_amazon_purchases' | 'off_amazon_purchases_cpa' | 'off_amazon_roas' | 'off_amazon_units_sold' | 'page_view' | 'page_view_cpa' | 'product_sales' | 'purchases' | 'roas' | 'sales' | 'search' | 'search_cpa' | 'sign_up' | 'sign_up_cpa' | 'subscribe' | 'subscribe_cpa' | 't_roas' | 'total_cost' | 'total_new_to_brand_roas' | 'total_new_to_brand_sales' | 'total_new_to_brand_units_sold' | 'total_purchases' | 'total_sales' | 'total_units_sold' | 'units_sold' | 'vcr' | 'video_complete' | 'video_start' | 'viewable_impressions' | 'vr'>;
  graph_metrics?: Array<'acos' | 'added_to_shopping_cart' | 'added_to_shopping_cart_cpa' | 'application' | 'application_cpa' | 'c_roas' | 'checkout' | 'checkout_cpa' | 'click_throughs' | 'combined_product_sales' | 'combined_purchases' | 'combined_units_sold' | 'contact' | 'contact_cpa' | 'conversions' | 'cpa' | 'cpdpv' | 'cpsu' | 'cpvc' | 'ctr' | 'cvr' | 'detail_page_views' | 'dpvr' | 'ecpc' | 'ecpm' | 'impressions' | 'lead' | 'lead_cpa' | 'measurable_impressions' | 'new_to_brand_purchases' | 'new_to_brand_roas' | 'new_to_brand_sales' | 'new_to_brand_units_sold' | 'off_amazon_product_sales' | 'off_amazon_purchases' | 'off_amazon_purchases_cpa' | 'off_amazon_roas' | 'off_amazon_units_sold' | 'page_view' | 'page_view_cpa' | 'product_sales' | 'purchases' | 'roas' | 'sales' | 'search' | 'search_cpa' | 'sign_up' | 'sign_up_cpa' | 'subscribe' | 'subscribe_cpa' | 't_roas' | 'total_cost' | 'total_new_to_brand_roas' | 'total_new_to_brand_sales' | 'total_new_to_brand_units_sold' | 'total_purchases' | 'total_sales' | 'total_units_sold' | 'units_sold' | 'vcr' | 'video_complete' | 'video_start' | 'viewable_impressions' | 'vr'>;
  table_metrics?: Array<'acos' | 'added_to_shopping_cart' | 'added_to_shopping_cart_cpa' | 'application' | 'application_cpa' | 'c_roas' | 'checkout' | 'checkout_cpa' | 'click_throughs' | 'combined_product_sales' | 'combined_purchases' | 'combined_units_sold' | 'contact' | 'contact_cpa' | 'conversions' | 'cpa' | 'cpdpv' | 'cpsu' | 'cpvc' | 'ctr' | 'cvr' | 'detail_page_views' | 'dpvr' | 'ecpc' | 'ecpm' | 'impressions' | 'lead' | 'lead_cpa' | 'measurable_impressions' | 'new_to_brand_purchases' | 'new_to_brand_roas' | 'new_to_brand_sales' | 'new_to_brand_units_sold' | 'off_amazon_product_sales' | 'off_amazon_purchases' | 'off_amazon_purchases_cpa' | 'off_amazon_roas' | 'off_amazon_units_sold' | 'page_view' | 'page_view_cpa' | 'product_sales' | 'purchases' | 'roas' | 'sales' | 'search' | 'search_cpa' | 'sign_up' | 'sign_up_cpa' | 'subscribe' | 'subscribe_cpa' | 't_roas' | 'total_cost' | 'total_new_to_brand_roas' | 'total_new_to_brand_sales' | 'total_new_to_brand_units_sold' | 'total_purchases' | 'total_sales' | 'total_units_sold' | 'units_sold' | 'vcr' | 'video_complete' | 'video_start' | 'viewable_impressions' | 'vr'>;
};
export namespace UserPreferences {
  export enum goal {
    AWARENESS = 'AWARENESS',
    CONVERSION = 'CONVERSION',
    CONSIDERATION = 'CONSIDERATION',
    OTHER = 'OTHER',
    CONVERSIONS_OFF_AMAZON = 'CONVERSIONS_OFF_AMAZON',
    ENGAGEMENT_WITH_MY_AD = 'ENGAGEMENT_WITH_MY_AD',
    CONSIDERATIONS_ON_AMAZON = 'CONSIDERATIONS_ON_AMAZON',
    PURCHASES_ON_AMAZON = 'PURCHASES_ON_AMAZON',
    MOBILE_APP_INSTALLS = 'MOBILE_APP_INSTALLS',
    PURCHASES_ON_OFF_AMAZON = 'PURCHASES_ON_OFF_AMAZON',
  }
}


import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { RemoveScroll } from "react-remove-scroll";

import { ReactPortal } from "common/components/ReactPortal";

type Props = React.PropsWithChildren<{
  isOpen: boolean;
  popoverWrapperId: string;
  popoverRef: React.MutableRefObject<HTMLDivElement | null>;
  popoverStyles: {
    [key: string]: React.CSSProperties;
  };
  popoverAttributes: {
    [key: string]:
      | {
          [key: string]: string;
        }
      | undefined;
  };
  withTransitionEnter?: boolean;
  withTransitionLeave?: boolean;
}>;

export const PopoverPortalWrapper: React.FC<Props> = ({
  isOpen,
  popoverWrapperId,
  popoverRef,
  popoverStyles,
  popoverAttributes,
  withTransitionEnter = true,
  withTransitionLeave = true,
  children,
}) => (
  <ReactPortal wrapperId={popoverWrapperId}>
    <div
      ref={popoverRef}
      style={popoverStyles.popper}
      {...popoverAttributes.popper}
      className="z-[2000]"
    >
      <Transition
        as="div"
        show={isOpen}
        className="transition ease-out"
        enter={classNames("duration-[0s]", {
          "duration-200": withTransitionEnter,
        })}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave={classNames("duration-[0s]", {
          "duration-200": withTransitionLeave,
        })}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <RemoveScroll>{children}</RemoveScroll>
      </Transition>
    </div>
  </ReactPortal>
);

/* eslint-disable react/no-unstable-nested-components */
import { uniqBy } from "lodash-es";
import { useEffect, useState } from "react";

import {
  isMessageKey,
  MessageKey,
  useFormatMessageComponent,
  useFormatMessage,
  Values,
} from "common/internationalization";
import { CallbackDefault } from "common/types";
import { eventBus } from "common/utils/eventBus";
import { uuidv4 } from "common/utils/uuid";

import { MessageTypes } from "../Message";
import { ReactPortal } from "../ReactPortal";
import { SingleSnackbar } from "./components/SingleSnackbar";

const DURATION = 5000;

export const Snackbar = () => {
  const formatMessage = useFormatMessage();
  const formatMessageComponent = useFormatMessageComponent();

  const [snackbarsStack, setSnackbarsStack] = useState<
    {
      id: string;
      duration: number;
      message: string | JSX.Element;
      type: MessageTypes;
      onClose: CallbackDefault;
      suffixComponent?: JSX.Element;
    }[]
  >([]);

  const getMessage = (
    message: MessageKey | string | JSX.Element,
    values?: Values,
  ) => {
    if (typeof message === "string") {
      if (!isMessageKey(message))
        return formatMessage(message as MessageKey, { values, silent: true });
      return formatMessageComponent(message, { values });
    }
    return message;
  };

  useEffect(() => {
    const eventHandler = (e: {
      detail: {
        message?: MessageKey | string;
        type?: MessageTypes;
        values?: Values;
        suffixComponent?: JSX.Element;
        duration?: number;
      };
    }) => {
      const id = e.detail.message
        ? e.detail.message + JSON.stringify(e.detail.values)
        : uuidv4();
      const type = e.detail.type ?? "error";
      const message = getMessage(e.detail.message || "", e.detail.values);

      setSnackbarsStack((currentStack) =>
        uniqBy(
          [
            ...currentStack,
            {
              id,
              duration: e.detail.duration ?? DURATION,
              message,
              type,
              suffixComponent: e.detail.suffixComponent,
              onClose: () => {
                setSnackbarsStack((currentStack) => [
                  ...currentStack.filter((snackbar) => snackbar.id !== id),
                ]);
              },
            },
          ],
          "id",
        ).slice(-3),
      );
    };

    eventBus.on("VOWMade:openSnackbar", eventHandler);

    return () => {
      eventBus.remove("VOWMade:openSnackbar", eventHandler);
    };
  }, []);

  return (
    <ReactPortal wrapperId="react-portal-snackbar">
      <div
        data-testid="snackbar"
        className="fixed z-[2000] left-10 bottom-2 min-w-[400px] flex flex-col gap-y-5"
      >
        {snackbarsStack.reverse().map((snackbar) => (
          <SingleSnackbar key={snackbar.id} snackbar={snackbar} />
        ))}
      </div>
    </ReactPortal>
  );
};

import { useEffect, useMemo, useState } from "react";

import { useListenOnKeyDown } from "common/hooks/useListenOnKeyDown";
import { eventBus } from "common/utils/eventBus";

import { ModalContext } from "../context";

type ModalContextProviderProps = {
  onClose: (checkDirty?: boolean) => void;
  onSuccess?: () => Promise<boolean | void>;
  children: JSX.Element;
};

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
  onSuccess,
  onClose,
  children,
}) => {
  const [show, setShow] = useState(false);

  const contextValue = useMemo(
    () => ({
      onSuccess: async () => {
        if (!onSuccess) return;

        const fulfilled = await onSuccess();
        // Prevents from closing when success is not fulfilled
        if (fulfilled) setShow(false);
      },
      onClose: () => setShow(false),
      onCloseForce: onClose,
      show,
      setShow,
    }),
    [show, onClose, onSuccess],
  );

  useListenOnKeyDown("Enter", true, async () => {
    await contextValue.onSuccess();
  });
  useListenOnKeyDown("Escape", true, contextValue.onClose);

  useEffect(() => {
    eventBus.on("VOWMade:closeAllModals", contextValue.onClose);

    return () => {
      eventBus.remove("VOWMade:closeAllModals", contextValue.onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { UserPreferences } from "../models/UserPreferences";
import {
  createReportingUserPreference,
  editReportingUserPreference,
} from "../requests/reporting/reportingUserPreferences";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UserPreferencesError = any;

export const useEditUserPreferenceMutation = () => {
  const mutation = useMutation<
    AxiosResponse<UserPreferences>,
    AxiosError<UserPreferencesError>,
    { id: string; payload: UserPreferences }
  >(editReportingUserPreference);
  return mutation;
};

export const useCreateUserPreferenceMutation = () => {
  const mutation = useMutation<
    AxiosResponse<UserPreferences>,
    AxiosError<UserPreferencesError>,
    UserPreferences
  >(createReportingUserPreference);
  return mutation;
};

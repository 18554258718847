import { DocumentHead } from "App/components/AppRoutes/components";
import { MainPageButton } from "common/components/buttons/MainPageButton";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { useFormatMessage } from "common/internationalization";
import { getInitialAdvertiser } from "common/utils/advertisers";
import { getUser, useAppSelector } from "modules/store";

const Error404Page = () => {
  const formatMessage = useFormatMessage();
  const user = useAppSelector(getUser);
  const advertiser = getInitialAdvertiser(user.advertiser_accounts, user.email);

  return (
    <>
      <DocumentHead pageName={formatMessage("notFound")} />
      <ErrorLayout
        title="404"
        subtitle={formatMessage("notFound")}
        message={formatMessage("notFound.info")}
      >
        <MainPageButton advertiser={advertiser} />
      </ErrorLayout>
    </>
  );
};

export default Error404Page;

import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  CarouselProps,
  Carousel as RRCarousel,
} from "react-responsive-carousel";

import { useCarouselContext } from "../context";

export const CarouselSlides: React.FC<Partial<CarouselProps>> = (props) => {
  const { items, selectedIndex, renderSlide } = useCarouselContext();

  return (
    <RRCarousel
      key={items.length}
      showArrows={false}
      infiniteLoop
      selectedItem={selectedIndex}
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      animationHandler="fade"
      swipeable={false}
      transitionTime={200}
      className="[&_li]:z-10"
      {...props}
    >
      {items.map(renderSlide)}
    </RRCarousel>
  );
};

import { UserPreferences } from "modules/api/models/UserPreferences";

export const Goals = UserPreferences.goal;

export enum Metrics {
  ACOS = "acos",
  ADDED_TO_SHOPPING_CART = "added_to_shopping_cart",
  ADDED_TO_SHOPPING_CART_CPA = "added_to_shopping_cart_cpa",
  APPLICATION = "application",
  APPLICATION_CPA = "application_cpa",
  C_ROAS = "c_roas",
  CHECKOUT = "checkout",
  CHECKOUT_CPA = "checkout_cpa",
  CLICK_THROUGHS = "click_throughs",
  COMBINED_PRODUCT_SALES = "combined_product_sales",
  COMBINED_PURCHASES = "combined_purchases",
  COMBINED_UNITS_SOLD = "combined_units_sold",
  CONTACT = "contact",
  CONTACT_CPA = "contact_cpa",
  CONVERSIONS = "conversions",
  CPA = "cpa",
  CPDPV = "cpdpv",
  CPSU = "cpsu",
  CPVC = "cpvc",
  CTR = "ctr",
  CVR = "cvr",
  DETAIL_PAGE_VIEWS = "detail_page_views",
  DPVR = "dpvr",
  ECPC = "ecpc",
  ECPM = "ecpm",
  IMPRESSIONS = "impressions",
  LEAD = "lead",
  LEAD_CPA = "lead_cpa",
  MEASURABLE_IMPRESSIONS = "measurable_impressions",
  NEW_TO_BRAND_PURCHASES = "new_to_brand_purchases",
  NEW_TO_BRAND_ROAS = "new_to_brand_roas",
  NEW_TO_BRAND_SALES = "new_to_brand_sales",
  NEW_TO_BRAND_UNITS_SOLD = "new_to_brand_units_sold",
  OFF_AMAZON_PRODUCT_SALES = "off_amazon_product_sales",
  OFF_AMAZON_PURCHASES = "off_amazon_purchases",
  OFF_AMAZON_PURCHASES_CPA = "off_amazon_purchases_cpa",
  OFF_AMAZON_ROAS = "off_amazon_roas",
  OFF_AMAZON_UNITS_SOLD = "off_amazon_units_sold",
  PAGE_VIEW = "page_view",
  PAGE_VIEW_CPA = "page_view_cpa",
  PRODUCT_SALES = "product_sales",
  PURCHASES = "purchases",
  ROAS = "roas",
  SALES = "sales",
  SEARCH = "search",
  SEARCH_CPA = "search_cpa",
  SIGN_UP = "sign_up",
  SIGN_UP_CPA = "sign_up_cpa",
  SUBSCRIBE = "subscribe",
  SUBSCRIBE_CPA = "subscribe_cpa",
  T_ROAS = "t_roas",
  TOTAL_COST = "total_cost",
  TOTAL_NEW_TO_BRAND_UNITS_SOLD = "total_new_to_brand_units_sold",
  TOTAL_NEW_TO_BRAND_ROAS = "total_new_to_brand_roas",
  TOTAL_PURCHASES = "total_purchases",
  TOTAL_SALES = "total_sales",
  TOTAL_UNITS_SOLD = "total_units_sold",
  UNITS_SOLD = "units_sold",
  VCR = "vcr",
  VIDEO_COMPLETE = "video_complete",
  VIDEO_START = "video_start",
  VIEWABLE_IMPRESSIONS = "viewable_impressions",
  VR = "vr",
}

export type PreferencesField = {
  goal: string;
  pod_metrics: string[];
  graph_metrics: string[];
  table_metrics: string[];
  id?: string;
};

export type UserPreferencesForm = {
  preferences: PreferencesField[];
};

import classNames from "classnames";
import { useEffect, useState } from "react";

import { Message, MessageTypes } from "common/components/Message";
import { CallbackDefault } from "common/types";

const CLOSE_TIMEOUT = 300;

type Props = {
  snackbar: {
    id: string;
    duration: number;
    message: string | JSX.Element;
    type: MessageTypes;
    onClose: CallbackDefault;
    suffixComponent?: JSX.Element;
  };
};

export const SingleSnackbar: React.FC<Props> = ({ snackbar }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);

  const close = () => {
    setIsOpen(false);
    setTimeout(() => {
      snackbar.onClose();
    }, CLOSE_TIMEOUT);
  };

  useEffect(() => {
    if (snackbar?.duration !== 0) {
      setTimeout(() => {
        close();
      }, snackbar.duration);
    }
  }, [snackbar?.onClose, snackbar?.duration]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={close}
      className={classNames(
        `w-full transition ease-out origin-left duration-[${CLOSE_TIMEOUT}ms]`,
        {
          "opacity-100": isOpen,
          "opacity-0": !isOpen,
        },
      )}
    >
      <Message
        message={snackbar.message}
        type={snackbar.type}
        suffixComponent={snackbar.suffixComponent}
      />
    </div>
  );
};

import classNames from "classnames";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import { SetState } from "common/types";

import { DatePickerParams } from "../types";

type Props = ReactDatePickerCustomHeaderProps &
  Pick<
    DatePickerParams,
    "monthsShown" | "quickFiltersLayout" | "showMonthYearPicker"
  > & {
    setShowMonthYearPicker: SetState<boolean>;
  };

export const DatePickerHeader: React.FC<Props> = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  monthsShown = 1,
  quickFiltersLayout,
  showMonthYearPicker,
  setShowMonthYearPicker,
}) => (
  <div
    className={classNames("", {
      "w-[350px]": quickFiltersLayout || monthsShown > 1,
      "w-[420px]": !monthsShown || (monthsShown === 1 && !quickFiltersLayout),
    })}
  >
    <button
      aria-label="Previous Month"
      data-testid="navigation-previous-month"
      className={classNames(
        "react-datepicker__navigation react-datepicker__navigation--previous",
        {
          invisible:
            customHeaderCount === 1 && monthsShown > 1 && !showMonthYearPicker,
        },
      )}
      onClick={showMonthYearPicker ? decreaseYear : decreaseMonth}
    />
    <button onClick={() => setShowMonthYearPicker(!showMonthYearPicker)}>
      <span
        data-testid="current-month"
        className="react-datepicker__current-month"
      >
        {monthDate.toLocaleString(
          "en-US",
          showMonthYearPicker
            ? { year: "numeric" }
            : {
                month: "long",
                year: "numeric",
              },
        )}
      </span>
    </button>
    <button
      aria-label="Next Month"
      data-testid="navigation-next-month"
      className={classNames(
        "react-datepicker__navigation react-datepicker__navigation--next",
        {
          invisible:
            customHeaderCount === 0 && monthsShown > 1 && !showMonthYearPicker,
        },
      )}
      onClick={showMonthYearPicker ? increaseYear : increaseMonth}
    />
  </div>
);

import { predefinedSelectors } from "common/components/DatePicker/consts";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_ISO } from "common/constants";
import { CalendarParams } from "common/types";
import { parse } from "common/utils/dates";

export const retrieveGlobalDateRange = (): CalendarParams => {
  const searchParams = new URLSearchParams(document.location.search);

  // Selector
  const retrievedSelector =
    searchParams.get("dateRange") ??
    (localStorage.getItem("globalDateRange") &&
      JSON.parse(localStorage.getItem("globalDateRange") || "")?.selector);
  const selector = predefinedSelectors.find(
    (selector) => selector.id === retrievedSelector,
  );
  if (selector) {
    const dateRangeObject = {
      selector: selector.id,
      lower: selector.range().lower,
      upper: selector.range().upper,
    };
    localStorage.setItem("globalDateRange", JSON.stringify(dateRangeObject));
    return dateRangeObject;
  }

  // Lower & Upper
  if (searchParams.get("dateRange")) {
    const parsedLower = parse(
      searchParams.get("dateRange")?.split("-")[0] || "",
      DEFAULT_DATE_FORMAT,
      new Date(),
    );
    const parsedUpper = parse(
      searchParams.get("dateRange")?.split("-")[1] || "",
      DEFAULT_DATE_FORMAT,
      new Date(),
    );

    const isValidLower = !Number.isNaN(parsedLower.getTime());
    const isValidUpper = !Number.isNaN(parsedUpper.getTime());

    if (isValidLower && isValidUpper) {
      const dateRangeObject = {
        lower: parsedLower,
        upper: parsedUpper,
        selector: undefined,
      };
      localStorage.setItem("globalDateRange", JSON.stringify(dateRangeObject));
      return dateRangeObject;
    }
    return {
      lower: undefined,
      upper: undefined,
      selector: "lifetime",
    };
  }

  if (
    localStorage.getItem("globalDateRange") &&
    JSON.parse(localStorage.getItem("globalDateRange") || "")?.lower &&
    JSON.parse(localStorage.getItem("globalDateRange") || "")?.upper
  ) {
    const parsedLower = parse(
      JSON.parse(localStorage.getItem("globalDateRange") || "").lower,
      DEFAULT_DATE_ISO,
      new Date(),
    );
    const parsedUpper = parse(
      JSON.parse(localStorage.getItem("globalDateRange") || "").upper,
      DEFAULT_DATE_ISO,
      new Date(),
    );

    const isValidLower = !Number.isNaN(parsedLower.getTime());
    const isValidUpper = !Number.isNaN(parsedUpper.getTime());

    if (isValidLower && isValidUpper) {
      const dateRangeObject = {
        lower: parsedLower,
        upper: parsedUpper,
        selector: undefined,
      };
      localStorage.setItem("globalDateRange", JSON.stringify(dateRangeObject));
      return dateRangeObject;
    }
  }

  // Empty
  return {
    lower: undefined,
    upper: undefined,
    selector: "lifetime",
  };
};

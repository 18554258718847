import { Scope } from "common/components/RestrictedDisplay";
import { getUser, useAppSelector } from "modules/store";

/**
 * Ensures search params maps given key and value.
 * It cleans it up on unmount.
 */
export const useUserPermissions = (scopes?: Scope[]): boolean => {
  const user = useAppSelector(getUser);

  const hasPermission = (permissions: Scope[], scopes: Scope[]) => {
    const scopesMap: { [key in Scope]?: boolean } = {};
    scopes.forEach((scope) => {
      scopesMap[scope] = true;
    });

    return permissions.some((permission) => scopesMap[permission]);
  };

  if (!user) return false;

  return scopes ? hasPermission(user.permissions, scopes) : true;
};

/* eslint-disable react/no-unstable-nested-components */
import classNames from "classnames";
import { useContext } from "react";

import { Icon, IconName } from "../Icon";
import { MessageContext } from "./context";
import { MessageTypes } from "./types";

type Props = {
  message?: string | JSX.Element;
  suffixComponent?: JSX.Element;
  trailingComponent?: JSX.Element;
  type?: MessageTypes;
};

export const Message: React.FC<Props> = ({
  message: propsMessage,
  suffixComponent,
  trailingComponent,
  type: propsType,
}) => {
  const { message: contextMessage, type: contextType } =
    useContext(MessageContext);
  const message = propsMessage || contextMessage;
  const type = propsType || contextType;

  const colours: Record<MessageTypes, { box: string; icon: string }> = {
    error: {
      box: "from-red-10 to-red-20 text-red-650",
      icon: "text-red-100",
    },
    outsideFormError: {
      box: "from-red-10 to-red-20 text-red-650",
      icon: "text-red-100",
    },
    success: {
      box: "from-green-20 to-green-30 text-green-800",
      icon: "text-blue-800",
    },
    warning: {
      box: "from-cream-400 to-cream-300 text-brown-800",
      icon: "text-orange-300",
    },
    info: {
      box: "from-blue-30 to-blue-20 text-blue-900 font-semibold",
      icon: "text-blue-300",
    },
  };

  const getIcon = (type: MessageTypes): IconName => {
    if (["outsideFormError", "error"].includes(type)) return "warningCircle";
    if (type === "warning") return "warning";
    if (type === "info") return "info";
    return "successCircle";
  };

  const getMessage = () => {
    if (typeof message !== "string") return message;

    return (
      <div data-testid={`${type}-message`} className="text-14 leading-[21px]">
        {message}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "relative w-full rounded-md bg-gradient-135",
        colours[type].box,
        {
          hidden: !message,
        },
      )}
    >
      <div
        data-testid={`${type}-message-wrapper`}
        className="flex items-center gap-x-4 p-5"
      >
        <div className={`${colours[type].icon}`}>
          <Icon name={getIcon(type)} size="small" />
        </div>
        <div>{getMessage()}</div>
        <div>{suffixComponent}</div>
      </div>
      <div className="w-full">{trailingComponent}</div>
    </div>
  );
};

type Props = {
  title: JSX.Element | string;
  linesNumber: number;
  lineMaxChars?: number;
};

export const getPreparedTitle = ({
  title,
  linesNumber,
  lineMaxChars,
}: Props) => {
  const shallShorten =
    typeof title === "string" && lineMaxChars && title.length > lineMaxChars;

  if (!shallShorten) return title;

  const maxCharacters = lineMaxChars * linesNumber;
  if (linesNumber > 1) {
    const numberOfLines = Math.min(
      Math.ceil(Math.min(title.length, maxCharacters) / lineMaxChars),
      linesNumber,
    );
    return (
      <div>
        {[...new Array(numberOfLines)].map((_, index) => {
          if (index + 1 === numberOfLines && title.length > maxCharacters) {
            const value = `${title
              .slice(index * lineMaxChars, maxCharacters - 7)
              .trim()}...${title.slice(-4).trim()}`;
            return <div key={value}>{value}</div>;
          }

          const value = `${title
            .slice(index * lineMaxChars, (index + 1) * lineMaxChars)
            .trim()}`;
          return <div key={value}>{value}</div>;
        })}
      </div>
    );
  }
  return `${title.slice(0, maxCharacters - 7).trim()}...${title
    .slice(-4)
    .trim()}`;
};

import React from "react";
import {
  createIntl,
  createIntlCache,
  IntlProvider,
  useIntl,
  FormattedMessage,
} from "react-intl";

import messages from "./en-US.json";

export type Values = Record<string, string | number | Date>;
export type MessageDict = typeof messages;
export type MessageKey<TPrefix extends string = ""> =
  Extract<
    keyof typeof messages,
    `${TPrefix}${string}`
  > extends `${TPrefix}${infer Rest}`
    ? Rest
    : never;
export const messageKeys = Object.keys(messages);
type FormatMessageOptions = {
  values?: Values;
  defaultMessage?: string;
  silent?: boolean;
};

type FormatMessageComponentOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: Values | Record<string, JSX.Element | ((x: any) => JSX.Element)>;
  defaultMessage?: string;
  silent?: boolean;
};

const locale = "en-US";

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const MessageProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <IntlProvider locale={locale} messages={messages}>
    {children}
  </IntlProvider>
);

export const isMessageKey = (
  message: MessageKey | string,
): message is MessageKey => messages[message as MessageKey] !== undefined;

export const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

export const formatMessage = (
  messageKey: MessageKey | string,
  options: FormatMessageOptions = {},
) => {
  const { values, defaultMessage = "", silent = false } = options;

  if (!isMessageKey(messageKey)) {
    // eslint-disable-next-line no-console
    if (!silent) console.error(`Couldn't find intl message key: ${messageKey}`);
    return defaultMessage ?? messageKey;
  }

  try {
    return intl.formatMessage({ id: messageKey }, values);
  } catch {
    // eslint-disable-next-line no-console
    if (!silent) console.error(`Issue with int function: ${messageKey}`);
    return defaultMessage ?? messageKey;
  }
};

export const useFormatMessage = () => {
  const { formatMessage: formatMessageIntl } = useIntl();

  // simplify formatMessage API
  const formatMessageFunc = (
    messageKey: MessageKey,
    options: FormatMessageOptions = {},
  ) => {
    const { values, defaultMessage = "", silent = false } = options;

    if (!isMessageKey(messageKey)) {
      if (!silent) {
        // eslint-disable-next-line no-console
        console.error(`Couldn't find intl message key: ${messageKey}`);
      }
      return defaultMessage || messageKey;
    }
    try {
      return formatMessageIntl({ id: messageKey }, values);
    } catch {
      if (!silent) {
        // eslint-disable-next-line no-console
        console.error(`Issue with int function: ${messageKey}`);
      }
      return defaultMessage || messageKey;
    }
  };

  return formatMessageFunc;
};

export const useFormatMessageComponent = () => {
  const formatMessageComponent = (
    messageKey: MessageKey,
    options: FormatMessageComponentOptions = {},
  ) => {
    const { values, defaultMessage = "", silent = false } = options;

    if (!isMessageKey(messageKey)) {
      if (!silent) {
        // eslint-disable-next-line no-console
        console.error(`Couldn't find intl message key: ${messageKey}`);
      }
      return <span>{defaultMessage || messageKey}</span>;
    }

    try {
      return (
        <FormattedMessage
          id={messageKey}
          values={{
            b: (chunks) => <strong className="font-bold">{chunks}</strong>,
            big: (chunks) => <h1 className="text-18 leading-6">{chunks}</h1>,
            icon: <svg />,
            br: <br />,
            ...values,
          }}
        />
      );
    } catch {
      if (!silent) {
        // eslint-disable-next-line no-console
        console.error(`Issue with intl function: ${messageKey}`);
      }
      return <span>{messageKey}</span>;
    }
  };

  return formatMessageComponent;
};

export const sortByMessage = (prefix: string) => (a: string, b: string) => {
  const translationA = formatMessage(`${prefix}.${a}`);
  const translationB = formatMessage(`${prefix}.${b}`);
  return translationA.localeCompare(translationB);
};

import { useCarouselContext } from "../context";

export const CarouselCounter = () => {
  const { items, selectedIndex } = useCarouselContext();

  return (
    <div className="text-12 whitespace-nowrap">
      <span data-testid="current-index-carousel" className="text-blue-900">
        {selectedIndex + 1}
      </span>{" "}
      <span
        data-testid="total-items-carousel"
        className="text-gray-800 whitespace-nowrap"
      >
        of {items.length}
      </span>
    </div>
  );
};

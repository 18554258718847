import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { createTransport } from "@sentry/react";
import { TransportRequest } from "@sentry/types";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { post } from "common/api";

export const ENV = {
  isProduction: window.location.hostname.endsWith("vowmade.com"),
  isStaging: window.location.hostname.endsWith("staging.vowmade.dev"),
  isDemo: window.location.hostname.endsWith("demo.vowmade.dev"),
  isLoad: window.location.hostname.endsWith("load.vowmade.dev"),
  isBBD: window.location.hostname.endsWith(".vowmade.dev"),
  isLocal: window.location.hostname === "localhost",
};

let environment = "";
if (ENV.isProduction) {
  environment = "production";
} else {
  [environment] = window.location.hostname.split(".");
}

export const getGoogleClientId = () => {
  if (ENV.isProduction)
    return "19974779635-ei9kk8v9nillr16ic447a2cdr9t6k0l1.apps.googleusercontent.com";
  return "19974779635-iuv1tmp2sepgicafk9bbfk9vpiguk4o6.apps.googleusercontent.com";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sentryTransport = (options: any) => {
  const makeRequest = async (request: TransportRequest) => {
    // this is where your sending logic goes
    const req = {
      body: request.body,
      url: options.url,
    };

    const sendRequestToBackend = (
      req: Pick<TransportRequest, "body"> & { url: string },
    ) =>
      post(`${req.url}`, req.body, {
        headers: { "Content-Type": "text/plain" },
      });

    const response = await sendRequestToBackend(req);
    return {
      headers: {
        "x-sentry-rate-limits": response.headers["X-Sentry-Rate-Limits"],
        "retry-after": response.headers["Retry-After"],
      },
      statusCode: response.status ?? 500,
      status: "success",
    };
  };
  return createTransport(options, makeRequest);
};

const sentryDsn =
  "https://02946a9842cb4548abcbc09109247fda@o4504004609966080.ingest.sentry.io/4505125740609536";

export const initializeSentry = () => {
  if (ENV.isLocal) return;

  Sentry.init({
    dsn: sentryDsn,
    release: import.meta.env.VITE_TAG,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ["error", "assert"],
      }),
    ],
    tracesSampleRate: 1.0,
    environment,
    tunnel: "/sentry-tunnel",
    transport: sentryTransport,
    attachStacktrace: true,
  });

  // eslint-disable-next-line no-console
  console.info("Sentry initialised.");
};

const datadogAppId = "e7ca2dd5-a94a-4041-90d6-f9e4d476f960";
const datadogToken = "pub8acea7a9254c7aa1e6b694656d077d8f";

export const initializeDataDog = () => {
  if (!ENV.isProduction) return;

  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogToken,
    site: "datadoghq.eu",
    service: "vowmade-browser",
    env: environment,
    version: import.meta.env.VITE_TAG,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enableExperimentalFeatures: ["clickmap"],
  });

  // eslint-disable-next-line no-console
  console.info("Datadog initialised.");
};

export const initializeGoogleAnalytics = () => {
  const TRACKING_ID = "G-ZLKGF1ECPJ";
  if (ENV.isProduction) {
    ReactGA.initialize(TRACKING_ID);
  }
};

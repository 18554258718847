import { Button } from "common/components/buttons/Button";
import { MainPageButton } from "common/components/buttons/MainPageButton";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { useBrand } from "common/hooks/useBrand";
import { useFormatMessage } from "common/internationalization";
import { getInitialAdvertiser } from "common/utils/advertisers";
import { sendHelpCenterMail } from "common/utils/sendHelpCenterMail";
import { getUser, useAppSelector } from "modules/store";

type Props = {
  modal: boolean;
  onClose?: () => void;
};

export const UnexpectedError: React.FC<Props> = ({ modal, onClose }) => {
  const formatMessage = useFormatMessage();
  const user = useAppSelector(getUser);
  const advertiser = user
    ? getInitialAdvertiser(user.advertiser_accounts || [], user.email)
    : null;
  const brand = useBrand();

  return (
    <ErrorLayout
      title={formatMessage("unexpectedError.title")}
      message={formatMessage("unexpectedError.info")}
      modal={modal}
      onClose={onClose}
      withClose={modal}
    >
      <div className="flex w-full">
        {brand.support_email && (
          <Button
            dataTestId="help-center"
            onClick={() => sendHelpCenterMail(brand.support_email)}
            styling="blue-outline-button"
            additionalClasses="w-full mx-2 capitalize"
          >
            {formatMessage("contactUs")}
          </Button>
        )}
        <MainPageButton advertiser={advertiser} />
      </div>
    </ErrorLayout>
  );
};

/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { isEmpty } from "lodash-es";
import { UseFormReturn } from "react-hook-form";

import { TStep } from "common/components/form/FormStepper";
import { MessageTypes } from "common/components/Message";
import { TFormModalTab } from "common/components/modals/FormModalTabs";
import { ERROR_MESSAGE_CLASS_NAME } from "common/constants";
import { formatMessage, isMessageKey } from "common/internationalization";
import { AnyObject, SetState } from "common/types";

import { eventBus } from "./eventBus";

const handleBackendErrorResponse = (
  error: any,
  errorKeysAction: (key: string, data: any) => void,
  errorStringAction: ((singleError: string) => void) | null = null,
) => {
  const isTestEnv = process.env.NODE_ENV === "test";
  if (error?.response?.status !== 400) {
    if (!isTestEnv)
      eventBus.openSnackbar("common.error.api.unexpectedErrorOccured", "error");
    return;
  }

  if (!axios.isAxiosError(error)) {
    if (!isTestEnv)
      eventBus.openSnackbar("common.error.api.unexpectedErrorOccured", "error");
    return;
  }

  const { data } = error.response;
  if (!data) {
    if (!isTestEnv)
      eventBus.openSnackbar("common.error.api.unexpectedErrorOccured", "error");
    return;
  }

  if (Array.isArray(data)) {
    if (typeof data[0] !== "object") {
      data.forEach((singleError: string) =>
        errorStringAction
          ? errorStringAction(singleError)
          : eventBus.openSnackbar(singleError, "error"),
      );
    } else {
      data.forEach(
        (element: {
          errorType: string;
          fieldName: string;
          message: string;
        }) => {
          eventBus.openSnackbar(element.message, "error");
        },
      );
    }
    return;
  }
  if (Object.keys(data).length) {
    (Object.keys(data) as string[]).forEach((key) => {
      errorKeysAction(key, data);
    });
    return;
  }
  if (!isTestEnv)
    eventBus.openSnackbar("common.error.api.unexpectedErrorOccured", "error");
};

// Set form errors on known fields and show snackbar on unexpected errors
export const notifyOnClientErrorForm = (
  error: any,
  form: UseFormReturn<any>,
  nameMap?: Record<string, string[] | Record<string, string[]>>,
) => {
  const errorKeysAction = (key: string, data: any) => {
    const resolveErrors = (
      errorObject: AnyObject,
      key: string,
      newNameMap?: AnyObject,
    ) => {
      if (typeof errorObject[key][0] === "string") {
        if (key === "non_field_errors")
          errorObject[key]?.forEach((singleError: string) =>
            eventBus.openSnackbar(singleError, "error"),
          );

        if (newNameMap && Array.isArray(newNameMap[key]))
          newNameMap[key]?.forEach((fieldName: string) => {
            form.setError(fieldName, {
              type: "custom",
              message: errorObject[key][0],
            });
          });
        else
          form.setError(key, {
            type: "custom",
            message: errorObject[key][0],
          });
      } else if (Array.isArray(errorObject[key])) {
        errorObject[key]?.forEach((el: AnyObject) => {
          if (!isEmpty(el))
            resolveErrors(
              el,
              Object.keys(el)[0],
              newNameMap ? newNameMap[key] : null,
            );
        });
      } else {
        eventBus.openSnackbar(formatMessage("unexpectedError"), "error");
      }
      return;
    };

    if (typeof data[key] === "string") {
      eventBus.openSnackbar(data[key], "error");
    } else if (key === "non_field_errors") {
      data[key]?.forEach((singleError: string) =>
        eventBus.openSnackbar(singleError, "error"),
      );
    } else if (nameMap && nameMap[key]) {
      resolveErrors(data, key, nameMap);
    } else {
      if (!(key in form.getValues())) eventBus.openSnackbar(data[key], "error");
      resolveErrors(data, key);
    }
  };
  handleBackendErrorResponse(error, errorKeysAction);
};

const getMessageWithPrefix = (message: string, prefix: string) => {
  const msg = `${prefix}${message}`;
  const isMessageKeyPrefixTranslation = isMessageKey(msg);
  return isMessageKeyPrefixTranslation ? msg : message;
};

// Show snackbar on errors
export const notifyOnClientErrorModal = (error: any, messageKeyPrefix = "") => {
  if (![401, 403, 429, 500, 503].includes(error.response?.status)) {
    const errorKeysAction = (key: string, data: any) => {
      if (data[key] && Array.isArray(data[key]))
        data[key].forEach((singleError: string | Record<string, unknown>) => {
          if (typeof singleError === "string") {
            const message = getMessageWithPrefix(singleError, messageKeyPrefix);
            eventBus.openSnackbar(message, "error");
            return;
          }

          (Object.keys(singleError) as string[]).forEach((key) => {
            errorKeysAction(key, singleError);
          });
        });
      else if (data[key]) {
        const message = getMessageWithPrefix(data[key], messageKeyPrefix);
        eventBus.openSnackbar(message, "error");
      }
    };
    handleBackendErrorResponse(error, errorKeysAction);
  } else if (!error) {
    eventBus.openSnackbar("common.error.api.unexpectedErrorOccured", "error");
  }
};

// Show custom error in login section
export const notifyOnClientErrorLogin = (
  error: any,
  setMessage: (value: string) => void,
  setType: SetState<MessageTypes>,
) => {
  const errorKeysAction = (key: string, data: any) => {
    data[key]?.forEach((singleError: string) => {
      setMessage(singleError);
      setType("error");
    });
  };
  const errorStringAction = (singleError: string) => {
    setMessage(singleError);
    setType("error");
  };
  handleBackendErrorResponse(error, errorKeysAction, errorStringAction);
};

const getErrorsRefs = (errors: any) => {
  const errorsRefs: { element: any; path: string }[] = [];
  const retrieveErrors = (
    errors: any,
    errorsRefsArray: any[],
    parentSlug?: string,
  ) =>
    Object.entries(errors).forEach(([name, value]: [string, any]) => {
      const path = parentSlug ? `${parentSlug}.${name}` : name;
      if (value?.ref) {
        errorsRefsArray.push({
          element: value.ref?.name
            ? (document.getElementsByName(value.ref?.name)[0] ??
              document.getElementById(value.ref?.name))
            : value.ref,
          path,
        });
      } else if (value && typeof value !== "string")
        retrieveErrors(value, errorsRefsArray, path);
    });
  retrieveErrors(errors, errorsRefs);
  return errorsRefs;
};

const scrollIntoElement = (element: HTMLElement) => {
  if (element) {
    element?.focus();
    if (
      document.activeElement !== element &&
      typeof element?.scrollIntoView === "function"
    ) {
      element.scrollIntoView({
        block:
          document.documentElement.scrollTop -
            element.getBoundingClientRect().bottom >
          0
            ? "center"
            : "nearest",
      });
    }
  }
};

// Focus on first error
export const focusOnFirstError = (form: UseFormReturn<any>) => {
  if (form.formState.errors) {
    const errors = getErrorsRefs(form.formState.errors)
      .filter((el) => el?.element)
      .sort(
        (a, b) =>
          (a.element?.getBoundingClientRect().top || 0) -
          (b.element?.getBoundingClientRect().top || 0),
      );

    if (errors.length > 0) {
      scrollIntoElement(errors[0].element);
      return;
    }

    // scroll to first error message if there is no error ref
    const errorMessages = document.getElementsByClassName(
      ERROR_MESSAGE_CLASS_NAME,
    );
    if (errorMessages.length) {
      scrollIntoElement(errorMessages[0] as HTMLElement);
      return;
    }
  }
};

export const focusOnFirstErrorTabs = (
  form: UseFormReturn<any>,
  tabs: TFormModalTab<AnyObject>[],
  setCurrentTab: (tab: number) => void,
) => focusOnFirstErrorStepper(form, tabs, setCurrentTab);

export const focusOnFirstErrorStepper = (
  form: UseFormReturn<any>,
  steps: TStep<AnyObject>[],
  setCurrentStep: (step: number) => void,
) => {
  if (Object.keys(form.formState.errors).length !== 0) {
    const errors = getErrorsRefs(form.formState.errors).filter((el) => el);
    const stepsErrors = steps.map((step) =>
      errors.filter((error) =>
        (step?.fieldsToValidate || []).some((prefix) =>
          error.path.startsWith(prefix),
        ),
      ),
    );
    stepsErrors.every((stepErrors, index) => {
      if (stepErrors.length) {
        setCurrentStep(index);
        setTimeout(() => {
          const preparedErrors = stepErrors
            .map((error) => ({
              ...error,
              element:
                error.element ??
                document.getElementsByName(error.path)[0] ??
                document.getElementsByClassName(error.path)[0],
            }))
            .sort(
              (a, b) =>
                (a.element?.getBoundingClientRect().top || 0) -
                (b.element?.getBoundingClientRect().top || 0),
            );
          if (preparedErrors.length > 0)
            scrollIntoElement(preparedErrors[0].element);
        }, 0);
        return false;
      }
      return true;
    });
  }
};

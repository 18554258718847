import { useContext } from "react";
import { Link } from "react-router-dom";

import { IconName, Icon } from "common/components/Icon";
import { PopoverContext } from "common/components/popovers/context";
import { MessageKey, useFormatMessage } from "common/internationalization";
import {
  getHrefNavbarLink,
  preventRedirectToSamePage,
} from "common/utils/navigation";
import { clearAllTablesData, useAppDispatch } from "modules/store";

import { MenuItemName } from "../types";

type Props = {
  name: MenuItemName;
  iconName: IconName;
  basicUrl: string;
};

export const MenuItem: React.FC<Props> = ({ iconName, name, basicUrl }) => {
  const { togglePopover } = useContext(PopoverContext);
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();

  const title = `userMenu.${name}` as MessageKey;

  const onClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    togglePopover(false);
    preventRedirectToSamePage(e, basicUrl);
    dispatch(clearAllTablesData());
  };

  return (
    <Link
      to={getHrefNavbarLink(basicUrl)}
      onClick={onClickLink}
      className="flex items-center gap-x-2 transition hover:bg-white-300 px-5 py-3"
      data-testid={`menu-option-${name.toLowerCase()}`}
    >
      <div className="text-pink-300">
        <Icon name={iconName} size="small" weight="bold" />
      </div>
      <h2 className="font-semibold text-14 text-blue-800 leading-4">
        {formatMessage(title)}
      </h2>
    </Link>
  );
};

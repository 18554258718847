import { Check, Square } from "@phosphor-icons/react";

import { colors } from "common/styles/colors";

import { CheckedProps } from "../types";

export const CheckboxChecked = ({
  checkColor = "white",
  checkBorderColor = colors.blue[300].DEFAULT,
  ...props
}: CheckedProps) => (
  <div className="relative">
    <Check
      size={props.size && +props.size > 24 ? 16.5 : 14}
      className="ml-[5px] mt-[5px] absolute"
      color={checkColor}
      weight="bold"
    />
    <Square size={24} weight="fill" color={checkBorderColor} {...props} />
  </div>
);

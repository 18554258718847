import {
  AnyAction,
  PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";

import advertiserReducer from "./advertiser/advertiserSlice";
import appReducer from "./app/appSlice";
import userReducer from "./user/userSlice";

const mainReducer = combineReducers({
  advertiser: advertiserReducer,
  app: appReducer,
  user: userReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "USER_LOGOUT") {
    return mainReducer(undefined, action);
  }

  return mainReducer(state, action);
};

const middlewareConfig = {
  serializableCheck: {
    // Ignore these action types
    ignoredActions: [
      "app/setGlobalDateRange",
      "app/setGlobalDateRangeSettings",
    ],
    // Ignore these paths in the state
    ignoredPaths: [
      "app.globalDateRange.lower",
      "app.globalDateRange.upper",
      "payload.lower",
      "payload.upper",
      "app.globalDateRangeSettings.minDate",
      "app.globalDateRangeSettings.maxDate",
      "payload.minDate",
      "payload.maxDate",
    ],
  },
};

const middlewareConfigTest = {
  immutableCheck: false,
  serializableCheck: false,
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(
        process.env.NODE_ENV === "test"
          ? middlewareConfigTest
          : middlewareConfig,
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

import { Button } from "common/components/buttons/Button";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { DEFAULT_RELOAD_PAGE } from "common/constants";
import { getBrandFromCache } from "common/utils/brand";
import { sendHelpCenterMail } from "common/utils/sendHelpCenterMail";

export const UnexpectedErrorPageRaw = () => {
  const brand = getBrandFromCache();

  return (
    <div className="h-screen">
      <ErrorLayout
        title="Unexpected error"
        message={
          "Sorry, an unexpected error occurred. \nIf the issue occurs repeatedly, please contact us through Help Center."
        }
      >
        <div className="flex w-full">
          {brand && (
            <Button
              onClick={() => sendHelpCenterMail(brand.support_email)}
              styling="blue-outline-button"
              additionalClasses="w-full mx-2 capitalize"
              dataTestId="help-center"
            >
              Help Center
            </Button>
          )}
          <Button
            onClick={() => {
              window.location.href = DEFAULT_RELOAD_PAGE;
            }}
            styling="blue-button"
            additionalClasses="w-full mx-2"
            dataTestId="main-page"
          >
            Main page
          </Button>
        </div>
      </ErrorLayout>
    </div>
  );
};

import { DocumentHead } from "App/components/AppRoutes/components";
import { MainPageButton } from "common/components/buttons/MainPageButton";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { useFormatMessage } from "common/internationalization";
import { getInitialAdvertiser } from "common/utils/advertisers";
import { getUser, useAppSelector } from "modules/store";

const Error403Page = () => {
  const formatMessage = useFormatMessage();
  const user = useAppSelector(getUser);
  const advertiser = getInitialAdvertiser(user.advertiser_accounts, user.email);

  return (
    <>
      <DocumentHead pageName={formatMessage("pageTitle.accessDenied")} />
      <ErrorLayout
        title="403"
        subtitle={formatMessage("forbidden")}
        message={
          advertiser.id === "-1"
            ? formatMessage("accessDenied.noAdvertiser.info")
            : formatMessage("accessDenied.info")
        }
      >
        <MainPageButton advertiser={advertiser} />
      </ErrorLayout>
    </>
  );
};

export default Error403Page;

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";

import { Icon, IconName } from "common/components/Icon";
import { PopoverContext } from "common/components/popovers/context";
import {
  OnChangeDefault,
  OnKeyboardEventDefault,
  SupportedCountry,
} from "common/types";

import { autosizeTextArea } from "../../utils";
import { DropdownSize, DropdownType } from "../types";

type DropdownInputProps = {
  testId: string;
  type: DropdownType;
  searchInputRef: React.MutableRefObject<any>;
  setSearchPhrase: (searchPhrase: string) => void;
  searchPhrase: string;
  onChange: (value: any) => void;
  onInputKeyDown: (
    key: React.KeyboardEvent["key"],
    onClose: () => void,
  ) => void;
  hasError: boolean;
  disabled: boolean;
  placeholder?: string;
  value?: string;
  size: DropdownSize;
  iconLeftName?: IconName | SupportedCountry;
  trailingElement?: JSX.Element;
  searchOff?: boolean;
};

export const DropdownInput = ({
  testId,
  type,
  searchInputRef,
  setSearchPhrase,
  searchPhrase = "",
  onChange,
  onInputKeyDown,
  placeholder = "",
  value,
  hasError,
  disabled,
  size,
  iconLeftName,
  trailingElement,
  searchOff,
}: DropdownInputProps) => {
  const { togglePopover, isOpen } = useContext(PopoverContext);
  const inputAsTextArea = type === "singleCreatable";

  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    const placeholderWidth = getTextWidth(placeholder);
    setWidth(placeholderWidth);
  }, [placeholder, value]);

  function getTextWidth(text: string) {
    const element = document.createElement("canvas");
    const context = element.getContext("2d");
    if (!context) return 0;
    context.font = getComputedStyle(document.body).font;
    return context.measureText(text).width;
  }

  useEffect(() => {
    if (isOpen) {
      searchInputRef.current?.focus();
      if (type === "singleCreatable") setSearchPhrase(value ?? "");
    } else {
      setSearchPhrase("");
      searchInputRef.current?.blur();
    }
  }, [isOpen]);

  const onSearchInputChange: OnChangeDefault<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => setSearchPhrase(event.currentTarget.value);
  const onKeyDown: OnKeyboardEventDefault<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    event.stopPropagation();
    const onClose = () => togglePopover(!isOpen);
    onInputKeyDown(event.key, onClose);
  };

  const renderTrailingElement = () => (
    <div className="text-inherit">
      {!isOpen && trailingElement ? (
        trailingElement
      ) : (
        <Icon
          size={size === "default" ? "medium" : "small"}
          name={isOpen ? "caretUp" : "caretDown"}
          onClick={(e) => {
            e.stopPropagation();
            togglePopover(!isOpen);
          }}
        />
      )}
    </div>
  );

  useEffect(() => {
    if (inputAsTextArea) autosizeTextArea(searchInputRef);
  }, [inputAsTextArea, searchInputRef, value, placeholder, searchPhrase]);

  return (
    <div className="text-14 h-full w-full">
      <div
        data-testid={`${testId}-dropdown-button`}
        className={classNames(
          "input leading-5 z-0 rounded-[5px] flex h-full items-center gap-x-1 sm:text-sm input-focus-within",
          {
            "border-red-100": !!hasError,
            "cursor-not-allowed text-gray-700": disabled,
            "w-full": size === "full",
            "w-[500px]": size === "default",
            "text-14 px-4 py-3": size === "default" || size === "full",
            "text-12 leading-[14px] px-4 py-[9px]": size === "small",
            "text-14 leading-[14px] px-4 py-[8.75px] font-semibold":
              size === "compact",
            "text-12 leading-[14px] pl-4 pr-2 py-[7px] font-medium mr-2":
              size === "compactSmall",
            "text-14 w-[260px] leading-[14px] px-4 py-[9px] font-bold text-blue-700":
              size === "advertiser",
          },
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;
          if (type === "singleCreatable") {
            togglePopover(true);
            return;
          }
          togglePopover(!isOpen);
        }}
        onKeyUp={(e) => {
          e.preventDefault(); // Fix issue with spacebar triggering click event
        }}
      >
        {iconLeftName && (
          <Icon
            name={iconLeftName}
            size={size === "default" ? "medium" : "small"}
          />
        )}
        {inputAsTextArea ? (
          <textarea
            ref={searchInputRef}
            style={{
              width:
                width && (size === "compact" || size === "compactSmall")
                  ? width + 10
                  : "100%",
            }}
            data-testid={`${testId}-dropdown-search`}
            placeholder={value || placeholder}
            onClick={(e) => {
              e.preventDefault();
            }}
            onKeyDown={onKeyDown}
            onBlur={(e) => {
              if (e.target.value) onChange(e.target.value);
            }}
            value={searchPhrase}
            onChange={onSearchInputChange}
            disabled={disabled}
            readOnly={searchOff}
            className={classNames(
              "resize-none bg-transparent w-full focus:outline-none",
              {
                "placeholder:text-blue-700": size === "advertiser" && !disabled,
                "placeholder:text-blue-800":
                  value && !disabled && size !== "advertiser",
                "placeholder:text-gray-800":
                  !disabled && !value && size !== "advertiser",
                "placeholder:text-gray-700": disabled,
                "cursor-not-allowed": disabled,
                "cursor-pointer": !disabled,
              },
            )}
            onWheel={(e) => e.currentTarget.blur()}
            rows={1}
          />
        ) : (
          <input
            ref={searchInputRef}
            style={{
              width:
                width && (size === "compact" || size === "compactSmall")
                  ? width + 10
                  : "100%",
            }}
            data-testid={`${testId}-dropdown-search`}
            placeholder={value || placeholder}
            onClick={(e) => {
              e.preventDefault();
            }}
            onKeyDown={onKeyDown}
            value={searchPhrase}
            onChange={onSearchInputChange}
            disabled={disabled}
            readOnly={searchOff}
            className={classNames("bg-transparent outline-none", {
              "placeholder:text-blue-700": size === "advertiser" && !disabled,
              "placeholder:text-blue-800":
                value && !disabled && size !== "advertiser",
              "placeholder:text-gray-800":
                !disabled && !value && size !== "advertiser",
              "placeholder:text-gray-700": disabled,
              "cursor-not-allowed": disabled,
              "cursor-pointer": !disabled,
            })}
          />
        )}
        {renderTrailingElement()}
      </div>
    </div>
  );
};

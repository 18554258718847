import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";

import { Tooltip } from "../Tooltip";
import { getPreparedTitle } from "./utils";

/**
 * @param lineMaxChars - can only be used with type of title = string
 */

export type TTitleShortener = {
  id?: string;
  title: JSX.Element | string;
  overflowTitle?: JSX.Element | string;
  dataTestId?: string;
  additionalClasses?: string;
  tooltip?: JSX.Element | string;
  tooltipClasses?: {
    wrapper?: string;
    content?: string;
    trigger?: string;
  };
  linesNumber?: number;
  lineMaxChars?: number;
  disableTooltip?: boolean;
};
export const TitleShortener: React.FC<TTitleShortener> = ({
  id = "title-shortener",
  title,
  overflowTitle,
  tooltip,
  tooltipClasses,
  additionalClasses = "",
  linesNumber = 1,
  dataTestId = "name",
  lineMaxChars,
  disableTooltip,
}) => {
  const ref = useRef<HTMLParagraphElement | null>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;
    const trigger = () => {
      const hasOverflow =
        current!.scrollHeight >
          Math.ceil(current!.getBoundingClientRect().height) ||
        current!.scrollWidth >
          Math.ceil(current!.getBoundingClientRect().width);

      setIsOverflow(hasOverflow);
    };

    if (current) trigger();
    if (typeof title === "string" && lineMaxChars)
      setIsOverflow(title.length > lineMaxChars * linesNumber);
  }, [ref, title]);

  // guards
  if (lineMaxChars && typeof title !== "string") {
    throw Error(
      "`lineMaxChars` prop can only be used with type of title = string.",
    );
  }

  return (
    <div key={id} className="flex items-center gap-x-0.5">
      <Tooltip
        testId={id}
        disabled={!isOverflow || disableTooltip}
        content={tooltip || title}
        classNames={tooltipClasses}
        trigger={
          <div className="grid grid-cols-[minmax(0, 1fr)_minmax(0, 1fr)]">
            <div
              data-testid={dataTestId}
              ref={ref}
              className={classNames(`${additionalClasses}`, {
                "overflow-hidden whitespace-nowrap break-keep":
                  linesNumber === 1 &&
                  !lineMaxChars &&
                  (!isOverflow || (isOverflow && !overflowTitle)),
                [`line-clamp-${linesNumber} break-words`]:
                  linesNumber > 1 &&
                  !lineMaxChars &&
                  (!isOverflow || (isOverflow && !overflowTitle)),
                "overflow-ellipsis": !lineMaxChars && isOverflow,
                "w-fit whitespace-nowrap break-keep":
                  lineMaxChars || (isOverflow && overflowTitle),
              })}
            >
              {isOverflow && overflowTitle
                ? overflowTitle
                : getPreparedTitle({
                    title,
                    linesNumber,
                    lineMaxChars,
                  })}
            </div>
          </div>
        }
      />
    </div>
  );
};

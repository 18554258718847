import classNames from "classnames";
import { useRef } from "react";

import { Icon } from "common/components/Icon";

import { InputGeneric, InputWrapper } from "./components";
import { TInput } from "./types";

const IconComponent = (props: Partial<TInput>) => (
  <button
    className={classNames({
      "text-gray-750": props.disabled,
      "text-blue-800": !props.disabled,
      "text-gray-800": props.iconPosition === "end",
    })}
    onClick={props.onIconClick}
  >
    {props.icon && <Icon name={props.icon} />}
  </button>
);

export const Input: React.FC<TInput> = ({
  form,
  name,
  label,
  icon,
  iconPosition = "start",
  showSkeleton,
  isReset,
  size,
  labelSize,
  helperText,
  trailingComponent,
  hideHelperText = false,
  onIconClick,
  wrapperClassName,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const iconProps = { ...rest, onIconClick, icon };
  const inputProps = { ...rest, form, name };

  const handleWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <InputWrapper
      form={form}
      name={name}
      label={label}
      showSkeleton={showSkeleton}
      isReset={isReset}
      size={size}
      labelSize={labelSize}
      helperText={helperText}
      onClick={handleWrapperClick}
      hideHelperText={hideHelperText}
      wrapperClassName={wrapperClassName}
    >
      {icon && iconPosition === "start" && <IconComponent {...iconProps} />}
      <InputGeneric {...inputProps} />
      {icon && iconPosition === "end" && <IconComponent {...iconProps} />}
      {trailingComponent && trailingComponent}
    </InputWrapper>
  );
};

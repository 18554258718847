import { BASENAME } from "App/components/AppRoutes/routes";

import { getAdvertiserId } from "./advertisers";

export const getHrefWithAdvertiserId = (href: string): string => {
  const advertiserId = getAdvertiserId({ silentError: true });
  if (!advertiserId) {
    return "/access-denied";
  }
  return `${href}?aid=${advertiserId}`;
};

export const checkIfNotRedirectToCurrentPage = (href: string): boolean => {
  const path = window.location.pathname.replace(BASENAME, "");
  if (
    (href.startsWith("/") && path === href) ||
    (!href.startsWith("/") && href === path.split("/").slice(-1).toString())
  ) {
    return false;
  }
  return true;
};

export const checkIfPageIsActive = (href: string): boolean => {
  const path = window.location.pathname.replace(BASENAME, "");
  if (
    (href.startsWith("/") && path.startsWith(href)) ||
    (!href.startsWith("/") && href === path.split("/").slice(-1).toString())
  ) {
    return false;
  }
  return true;
};

export const getHrefNavbarLink = (href: string): string =>
  checkIfNotRedirectToCurrentPage(href) ? getHrefWithAdvertiserId(href) : "";

export const preventRedirectToSamePage = (
  e: React.MouseEvent,
  href: string,
) => {
  if (!checkIfNotRedirectToCurrentPage(href) && !(e.ctrlKey || e.metaKey))
    e.preventDefault();
};

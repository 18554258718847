import { Country } from "common/types";

type Props = {
  name: Country;
  alt?: string;
};

export const CountryIcon: React.FC<Props> = ({ name, alt }) => (
  <img
    data-testid="country-icon"
    className="rounded border border-white h-4 w-5"
    src={`/app/icons/countries/${name.toLowerCase()}.svg`}
    alt={alt}
  />
);

import classNames from "classnames";

import { ModalProps } from "./types";

export const MODAL_BODY_ID = "modal-body-id";

export const getModalBody = () =>
  document.getElementById(MODAL_BODY_ID) || undefined;

export const ModalLayout: React.FC<ModalProps> = ({
  header,
  body,
  footer,
  leftSection,
  additionalClassNames = "",
  alwaysFullWidth = false,
  alwaysFullHeight = false,
}) => (
  <div
    className={classNames(
      "relative max-h-[94vh] flex bg-white-0 rounded-4px overflow-hidden",
      additionalClassNames,
      { "m-auto": alwaysFullWidth, "h-[94vh]": alwaysFullHeight },
    )}
  >
    {leftSection}
    <div
      className={classNames("relative py-[3vh] grid w-full", {
        "pl-[min(40px,3vw)] pr-[3vw]": leftSection,
        "px-[3vw]": !leftSection,
      })}
      style={{
        gridTemplateRows: `auto ${footer ? "minmax(auto, 5fr)" : ""}`,
      }}
    >
      {header && <div>{header}</div>}
      <div
        id={MODAL_BODY_ID}
        className="overflow-y-auto overflow-x-hidden pr-2"
        style={{ scrollbarGutter: "stable" }}
      >
        {body}
      </div>
      {footer && <div className="self-end">{footer}</div>}
    </div>
  </div>
);

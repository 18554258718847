import { useMergeRefs } from "@floating-ui/react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { forwardRef, HTMLProps } from "react";
import { twMerge } from "tailwind-merge";

import { ReactPortal } from "common/components/ReactPortal";

import { useTooltipContext } from "../context";
import { TooltipBehaviour, TooltipTheme } from "../types";

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    testId: string;
    arrow: boolean;
    contentClassName?: string;
    theme: TooltipTheme;
    behaviour: TooltipBehaviour;
    disabled: boolean;
  }
>(
  (
    {
      style,
      disabled,
      behaviour,
      theme,
      contentClassName,
      arrow,
      testId,
      ...props
    },
    propRef,
  ) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open || disabled) return null;

    const { className, children, ...floatingProps } =
      context.getFloatingProps(props);

    return (
      <ReactPortal wrapperId={`react-portal-${testId}-tooltip`}>
        <Transition
          show={context.open}
          appear
          className="relative z-[2000] transition-all duration-[400ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div
            ref={ref}
            style={{
              ...context.floatingStyles,
              ...style,
            }}
            {...floatingProps}
            className={twMerge(
              classNames(
                className as string,
                "max-w-[500px] overflow-hidden bg-transparent",
                {
                  "drop-shadow-card": theme !== "custom",
                  "p-1.5": !arrow && behaviour === "floating",
                },
                contentClassName,
              ),
            )}
          >
            <div
              data-testid={`${testId}-tooltip`}
              className={classNames(
                "text-12 p-3 relative max-h-[inherit] h-full overflow-y-auto rounded-md",
                {
                  "text-white-300 bg-blue-800": theme === "dark",
                  "text-blue-800 bg-white": theme === "light",
                },
              )}
            >
              {children as JSX.Element}
            </div>
            {arrow && context.renderFloatingArrow(theme)}
          </div>
        </Transition>
      </ReactPortal>
    );
  },
);

import { createContext } from "react";

import { CallbackSomeData } from "common/types";

export const ModalContext = createContext<{
  onClose: () => void;
  onCloseForce: () => void;
  onSuccess: CallbackSomeData;
  show: boolean;
  setShow: (show: boolean) => void;
}>({
  onClose: () => {},
  onCloseForce: () => {},
  onSuccess: () => {},
  show: false,
  setShow: () => {},
});

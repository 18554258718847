import { get, post } from "common/api";
import { TableQueryParams } from "common/components/tables/types";
import { Pagination } from "common/types";
import { CampaignAdGroup } from "modules/Strategies/SingleCampaign/types";
import { ActivationStatus, AdvancedCampaign } from "modules/Strategies/types";

export const getCampaignSummaryData = (data: {
  strategyId: string;
  campaignId: string;
}) =>
  get<AdvancedCampaign>(
    `/strategies/${data.strategyId}/campaigns/${data.campaignId}/`,
    { params: { include_archived: true } },
  );

export type AdGroupsTableParams = TableQueryParams & {
  currency_type: "market" | "primary";
  status?: string;
  audience_set_name?: string;
  inventory_source?: string;
  device?: string;
  goal?: string;
};

export type AdGroupsGroupsTableParams = AdGroupsTableParams & {
  group_by?: string;
};

export const getCampaignAdGroups = (data: {
  strategyId: string;
  campaignId: string;
  params: AdGroupsTableParams;
}) =>
  get<Pagination<CampaignAdGroup>>(
    `/strategies/${data.strategyId}/campaigns/${data.campaignId}/ad-groups/`,
    {
      params: data.params,
    },
  );

export const getCampaignAdGroupsGroups = (data: {
  strategyId: string;
  campaignId: string;
  params: AdGroupsGroupsTableParams;
}) =>
  get(
    `/strategies/${data.strategyId}/campaigns/${data.campaignId}/ad-groups/group_by/`,
    { params: data.params },
  );

export const setAdGroupStatus = (data: {
  strategyId: string;
  adGroupId: string;
  payload: { status: ActivationStatus };
}) =>
  post(
    `/strategies/${data.strategyId}/ad-groups/${data.adGroupId}/set_status/`,
    data.payload,
  );

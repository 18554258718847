import { useMemo, useState } from "react";

import { CarouselContext } from "./context";
import { TCarouselContext } from "./types";

type Props = React.PropsWithChildren<
  Pick<TCarouselContext, "items" | "renderSlide"> & {
    index?: number;
  }
>;

export const Carousel: React.FC<Props> = ({
  items,
  renderSlide,
  index = 0,
  children,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(index);

  const providerValue = useMemo(
    () => ({
      items,
      renderSlide,
      selectedIndex: Math.min(selectedIndex, items.length - 1),
      setSelectedIndex,
    }),
    [items, selectedIndex],
  );

  return (
    <CarouselContext.Provider value={providerValue}>
      {children}
    </CarouselContext.Provider>
  );
};

import React from "react";

import { BASENAME } from "App/components/AppRoutes/routes";
import { useDefaultRoute } from "common/hooks/useDefaultRoute";
import { useFormatMessage } from "common/internationalization";
import { Advertiser } from "common/types";

import { Button } from "./Button";

export type ButtonProps = {
  advertiser: Advertiser | null;
};

export const MainPageButton: React.FC<
  React.PropsWithChildren & ButtonProps
> = ({ advertiser }) => {
  const defaultRoute = useDefaultRoute();
  const formatMessage = useFormatMessage();
  const getAdvertiserQueryParam = (advertiser: Advertiser | null) =>
    advertiser?.id && advertiser.id !== "-1" ? `?aid=${advertiser.id}` : "";

  return (
    <Button
      onClick={() => {
        window.location.href = `${BASENAME}${defaultRoute}${getAdvertiserQueryParam(
          advertiser,
        )}`;
      }}
      styling="blue-button"
      additionalClasses="w-full mx-2"
      dataTestId="main-page"
    >
      {formatMessage("goToMainPage")}
    </Button>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";

import { Icon } from "common/components/Icon";
import { colors } from "common/styles/colors";
import { CallbackDefault } from "common/types";

type Props = {
  labelEl: string | JSX.Element;
  value: string | number;
  onClick?: CallbackDefault;
  hideRemove?: boolean;
  onRemoveClick?: CallbackDefault;
  selected?: boolean;
  disabled?: boolean;
  error?: boolean;
};

export const Chip: React.FC<Props> = ({
  value,
  labelEl,
  onClick,
  hideRemove,
  onRemoveClick,
  selected = true,
  disabled,
  error,
}) => (
  <div data-testid="chip" className="flex flex-col">
    <div
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
      data-testid={`${value}-dropdown-chip`}
      className={classNames("rounded-md p-2 flex items-center", {
        "cursor-not-allowed": disabled,
        "bg-blue-80 text-white": !error && selected,
        "border-gray-700 border text-blue-800": !error && !selected,
        "bg-red-300 text-red-100": !!error,
        "cursor-pointer": onClick && !disabled,
      })}
    >
      {!hideRemove && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (onRemoveClick) onRemoveClick();
          }}
          data-testid="remove-chip"
          className="mr-1.5 disabled:cursor-not-allowed"
          disabled={disabled}
        >
          <Icon
            name="close"
            size="medium"
            alt="remove"
            color={error ? colors.red[100].DEFAULT : "white"}
          />
        </button>
      )}
      {labelEl}
    </div>
  </div>
);

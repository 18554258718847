import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { TGlobalMessage } from "App/components/AppRoutes/components/types";
import { Carousel } from "common/components/Carousel";
import { CarouselSlides } from "common/components/Carousel/components";
import { useCarouselContext } from "common/components/Carousel/context";
import {
  ModalContent,
  ModalContextProvider,
} from "common/components/modals/components";

import { MessageModalFooter } from "./MessageModalFooter";
import { MessageModalHeader } from "./MessageModalHeader";

type Props = {
  severity: TGlobalMessage["severity"];
  onCancel: () => void;
  onDismiss: (msgId: string) => void;
};

export const DetailedMessageModal: React.FC<Props> = ({
  severity,
  onCancel,
  onDismiss,
}) => {
  const { items, selectedIndex } = useCarouselContext();

  return (
    <Carousel
      items={items}
      index={selectedIndex}
      renderSlide={(item: TGlobalMessage) => (
        <ReactMarkdown
          key={item.id}
          className="text-left text-blue-800 overflow-hidden [&>p]:break-words"
        >
          {item.details}
        </ReactMarkdown>
      )}
    >
      <ModalContextProvider onClose={onCancel}>
        <ModalContent
          wrapperId="detailed-message-modal"
          header={
            <div className="mb-5">
              <MessageModalHeader severity={severity} />
            </div>
          }
          body={<CarouselSlides dynamicHeight />}
          footer={
            <div className="mt-5">
              <MessageModalFooter onDismiss={onDismiss} />{" "}
            </div>
          }
          additionalClassNames="w-[720px] p-10"
        />
      </ModalContextProvider>
    </Carousel>
  );
};

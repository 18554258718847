import { ReactDatePickerProps } from "react-datepicker";
import { UseFormReturn } from "react-hook-form";

import {
  CalendarParamsForm,
  FilterCalendarParamsForm,
  Period,
} from "common/types";

export type DatePickerParams = Omit<
  ReactDatePickerProps<never, boolean>,
  "onChange" | "locale"
> & {
  form:
    | UseFormReturn<CalendarParamsForm>
    | UseFormReturn<FilterCalendarParamsForm>;
  dateFormat: string;
  disabledCalendarInfo?: string;
  disabledDayInfo?:
    | string
    | ((date?: Date) => { content: string; isActive?: boolean });
  quickFiltersLayout?: boolean;
  requiredFields?: ("lower" | "upper")[];
  disableStart?: boolean;
  disableEnd?: boolean;
  hideFooterButtons?: boolean;
  dateInputsLayout?: "row" | "column";
  error?: string;
  onCancel?: () => void;
  onApply?: () => void;
  changeDateCallback?: (
    params: CalendarParamsForm,
    error: string | null,
    selects: DatePickerSelectType,
  ) => void;
};

export type Selector = {
  id: Period;
  range: () => { lower?: Date; upper?: Date };
};

export enum DatePickerSelectType {
  RANGE = "range",
  START = "start",
  END = "end",
  DISALBED = "disabled",
}

import classNames from "classnames";
import { useContext, useEffect } from "react";

import { ENV } from "App/integrations";
import { Breadcrumb } from "common/components/Breadcrumb";
import { Expandable } from "common/components/Transitions";
import { useIsPageActive } from "common/hooks/useIsPageActive";
import { useGlobalMessages } from "modules/api/queries/general/messaging";
import {
  getAdvertiser,
  getGlobalDateRangeSettings,
  useAppSelector,
} from "modules/store";

import { NavContext } from "../context";
import { AdvertiserDropdown, Menu } from "./components";
import { DatePickerGlobal } from "./components/DatePickerGlobal";
import { MessageBar } from "./components/MessageBar";

export const TopBar = () => {
  const advertiser = useAppSelector(getAdvertiser);
  const globalDateRangeSettings = useAppSelector(getGlobalDateRangeSettings);

  const isActive = useIsPageActive();
  const { show, goBackButton } = useContext(NavContext);

  const {
    data: globalMessages,
    isFetchedAfterMount,
    refetch,
  } = useGlobalMessages({
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    onSettled: (data) => {
      if (ENV.isProduction)
        // logger for backend response to investigate the sentry issue
        // https://vow-made-limited.sentry.io/issues/5217194336/?environment=production&project=4505125740609536&query=release%3Amaster-0c65cb71&referrer=release-issue-stream
        // eslint-disable-next-line no-console
        console.info("Global messages response", JSON.stringify(data));
    },
  });

  useEffect(() => {
    // fetches data after login
    if (isFetchedAfterMount && !globalMessages) refetch();
  }, [isFetchedAfterMount]);

  const isAdministrationPanel = isActive("/administration");

  return (
    <div>
      {show && (
        <div>
          <div
            style={{ scrollbarGutter: "stable" }}
            data-testid="top-bar"
            className={classNames(
              "w-full flex items-center align-stretch bg-white-200 h-[60px] py-3 px-3 overflow-y-auto desktop-s:px-[3vw]",
              {
                "bg-blue-850": isAdministrationPanel,
              },
            )}
          >
            <div>
              {goBackButton.label && goBackButton.url && (
                <Breadcrumb
                  breadList={[
                    {
                      label: goBackButton.label,
                      url: goBackButton.url,
                    },
                  ]}
                  additionalStyles="text-blue-700"
                />
              )}
            </div>
            <div className="grow"></div>
            <div className="flex items-center gap-x-6 pl-8">
              {!isAdministrationPanel &&
                advertiser &&
                !globalDateRangeSettings.hidden && <DatePickerGlobal />}
              {!isAdministrationPanel && advertiser && <AdvertiserDropdown />}
              <Menu />
            </div>
          </div>
          <Expandable isOpen={!!globalMessages?.data.length} omitScaling>
            <MessageBar
              severity="warning"
              messages={
                globalMessages
                  ? globalMessages.data.filter((m) => m.severity === "warning")
                  : []
              }
            />
            <MessageBar
              severity="info"
              messages={
                globalMessages
                  ? globalMessages.data.filter((m) => m.severity === "info")
                  : []
              }
            />
          </Expandable>
        </div>
      )}
    </div>
  );
};

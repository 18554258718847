import { FeatureName } from "common/types";
import { getFeatureStatus, useAppSelector } from "modules/store";

/**
 * By default it assumes feature is inactive.
 * When an entry for waffle in the administration panel appears,
 * it reads the is_active flag from the endpoint.
 *
 * Example usage:
 * const createCampaignActive = useFeature("campaign.create")
 */
export const useFeature = (
  featureName?: FeatureName,
  defaultValue = false,
): boolean => {
  const featureStatus = useAppSelector(getFeatureStatus);
  if (!featureStatus && featureName) return false;
  if (!featureStatus && !featureName) return defaultValue;
  return featureName
    ? (featureStatus![featureName]?.is_active ?? defaultValue)
    : defaultValue;
};

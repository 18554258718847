import classNames from "classnames";

import { Icon } from "common/components/Icon";

import { TDropdownOption } from "../../../types";

type OptionSingleProps<T> = {
  option: TDropdownOption<T>;
  fieldTestId: string;
  setSelected: (value?: string | number) => void;
  selected?: TDropdownOption<T>;
  unselectSingleChoiceOn?: boolean;
  checkboxPosition?: "left" | "right";
};

export const OptionSingle = <T,>({
  option,
  fieldTestId,
  setSelected,
  selected,
  unselectSingleChoiceOn = false,
  checkboxPosition = "left",
}: OptionSingleProps<T>) => {
  const handleOnClick = () => {
    if (unselectSingleChoiceOn && option.value === selected?.value) {
      setSelected(undefined);
    } else setSelected(option.value);
  };

  return (
    <li>
      <button
        data-testid={`${option.value}-${fieldTestId}-dropdown-option`}
        className={classNames(
          "w-full text-blue-800 cursor-pointer pl-2 pr-4 py-2 hover:bg-white-300 bg-white-0 flex items-center gap-x-1",
          {
            "font-bold": option.value === selected?.value,
            "flex-row-reverse": checkboxPosition === "right",
          },
        )}
        onClick={handleOnClick}
      >
        <div className={`${option.value !== selected?.value && "opacity-0"}`}>
          <Icon name="check" />
        </div>
        <div
          className="truncate w-full text-blue-800 text-left"
          data-testid={`${option.label}-${fieldTestId}-dropdown-option-label`}
        >
          {option.dropdownLabel || option.labelEl || option.label}
        </div>
      </button>
    </li>
  );
};

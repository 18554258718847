import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { Advertiser, Agency, UNASSIGNED_AGENCY, User } from "common/types";
import { getUserFromCache, setUserToCache } from "common/utils/users";

import { fetchUser } from "./thunkActions";

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: getUserFromCache(),
};

const setSentryContextUser = (user: User | null) => {
  if (user) {
    Sentry.setUser({ id: user.id, email: user.email });
  }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
      setUserToCache(action.payload);
      setSentryContextUser(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const { user } = action.payload;
      const preparedUser = {
        ...user,
        agencies: Object.values(
          user.advertiser_accounts.reduce(
            (
              acc: {
                [key: string]: {
                  agency: Agency;
                  advertiser_accounts: Advertiser[];
                };
              },
              item,
            ) => {
              const agency = item.agency || UNASSIGNED_AGENCY;
              acc[agency.id] = acc[agency.id] || {
                agency,
                advertiser_accounts: [],
              };
              acc[agency.id].advertiser_accounts.push(item);
              return acc;
            },
            {},
          ),
        ),
      };

      state.user = preparedUser;
      setUserToCache(preparedUser);
      setSentryContextUser(preparedUser);
    });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

import { useCallback } from "react";
import { ReactDatePickerProps } from "react-datepicker";

import { useFormatMessage } from "common/internationalization";
import { FilterCalendarParamsForm, SetState } from "common/types";
import { parse } from "common/utils/dates";

import { DatePickerParams, DatePickerSelectType } from "./types";

export const getTimeZoneInfo = (date: Date) => {
  const utcOffsetMinutes = date.getTimezoneOffset();
  const timezoneName = Intl.DateTimeFormat("en", {
    timeZoneName: "long",
  })
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName")?.value;

  const utcOffsetHours = Math.abs(Math.floor(utcOffsetMinutes / 60));
  const utcOffsetMinutesRemainder = Math.abs(utcOffsetMinutes % 60);
  const sign = utcOffsetMinutes > 0 ? "-" : "+";

  return `UTC ${sign}${utcOffsetHours
    .toString()
    .padStart(2, "0")}:${utcOffsetMinutesRemainder
    .toString()
    .padStart(2, "0")} (${timezoneName})`;
};

export const checkRangeHasExcludedDates = (
  excludeDateIntervals: ReactDatePickerProps["excludeDateIntervals"],
  range: [Date, Date],
): boolean => {
  if (!excludeDateIntervals?.length) return false;

  const [start, end] = range;

  return excludeDateIntervals.some(
    ({ start: startDate, end: endDate }) =>
      (startDate <= start && endDate >= start) ||
      (startDate <= end && endDate >= end) ||
      (startDate >= start && endDate <= end),
  );
};

export const useHandleDateRangeErrors = (
  form: DatePickerParams["form"],
  dateFormat: DatePickerParams["dateFormat"],
  excludeDateIntervals: ReactDatePickerProps["excludeDateIntervals"],
  selects: DatePickerSelectType,
  setDateRangeError: SetState<string | null>,
) => {
  const formatMessage = useFormatMessage();

  const fn = useCallback(
    (newDateRange: Partial<FilterCalendarParamsForm>) => {
      let error = null;
      const { lower, upper } = newDateRange;

      if (!lower || !upper) return error;

      const lowerDate = parse(lower, dateFormat, new Date());
      const upperDate = parse(upper, dateFormat, new Date());

      const rangeHasExcludedDates = checkRangeHasExcludedDates(
        excludeDateIntervals,
        [lowerDate, upperDate],
      );
      if (rangeHasExcludedDates)
        error = formatMessage(
          "common.components.datePicker.error.selectedRangeIncludesExcludedDates",
        );

      setDateRangeError(error);
      handleDateInputsErrorState(error, form, selects);
      return error;
    },
    [dateFormat, JSON.stringify(excludeDateIntervals), selects],
  );

  return fn;
};

const handleDateInputsErrorState = (
  error: string | null,
  form: DatePickerParams["form"],
  selects: DatePickerSelectType,
) => {
  if (!error) {
    form.clearErrors("lower");
    form.clearErrors("upper");
    return;
  }

  // if there is an error we always want both inputs to have red border
  form.setError("lower", { message: "" });
  form.setError("upper", { message: "" });

  if (selects === DatePickerSelectType.START) {
    form.setError("lower", { message: error });
  } else if (selects === DatePickerSelectType.END) {
    form.setError("upper", { message: error });
  }
};

import { ButtonProps, Button } from "common/components/buttons/Button";

import { useCarouselContext } from "../context";

export const CarouselButtonPrev: React.FC<
  React.PropsWithChildren<Partial<ButtonProps>>
> = ({ children, ...props }) => {
  const { items, setSelectedIndex } = useCarouselContext();

  const handlePrev = () => {
    setSelectedIndex((prev) => (items.length + prev - 1) % items.length);
  };

  return (
    <Button
      styling=""
      dataTestId="prev-carousel"
      onClick={handlePrev}
      {...props}
    >
      {children}
    </Button>
  );
};

import classNames from "classnames";
import "./style.scss";

type Props = {
  loading?: boolean;
  color?: string;
  size?: number;
  width?: number;
};

export const Loader: React.FC<Props> = ({
  loading = true,
  color,
  size = 24,
  width = 4,
}) => (
  <div
    style={
      {
        "--spinner-color": color ?? "currentColor",
        "--size": size,
        "--spinner-width": width,
      } as React.CSSProperties
    }
    className={classNames("spinner ma-auto", {
      hidden: !loading,
    })}
  />
);

import classNames from "classnames";

import { Button } from "common/components/buttons/Button";
import { CarouselButtonNext } from "common/components/Carousel/components";
import { useCarouselContext } from "common/components/Carousel/context";
import { useFormatMessage } from "common/internationalization";

type Props = {
  onDismiss: (msgId: string) => void;
};

export const MessageModalFooter: React.FC<Props> = ({ onDismiss }) => {
  const { selectedIndex, items } = useCarouselContext();
  const formatMessage = useFormatMessage();
  const selectedItem = items[selectedIndex];

  return (
    <div className="flex items-center w-full justify-between gap-x-5">
      <Button
        dataTestId="dismiss-detailed-message"
        styling="blue-outline-button"
        onClick={() => onDismiss(selectedItem.id)}
        additionalClasses="w-full"
      >
        {formatMessage("words.dismiss")}
      </Button>
      <CarouselButtonNext
        dataTestId="next-detailed-global-message"
        styling="blue-outline-button"
        additionalClasses={classNames("w-full", {
          hidden: items.length < 2,
        })}
      >
        {formatMessage("common.seeNext")}
      </CarouselButtonNext>
    </div>
  );
};

import { useIsPageActive } from "common/hooks/useIsPageActive";

import {
  AdministrationNavItems,
  NonAdministrationNavItems,
} from "./components";

export const Navigation = () => {
  const isActive = useIsPageActive();

  const isAdministrationPanel = isActive("/administration");

  return (
    <div className="flex flex-col items-center h-full">
      {isAdministrationPanel ? (
        <AdministrationNavItems />
      ) : (
        <NonAdministrationNavItems />
      )}
    </div>
  );
};

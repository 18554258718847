/* eslint-disable @typescript-eslint/no-explicit-any */

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { get, RegisterOptions, UseFormReturn } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { IconName } from "common/components/Icon";
import { Popover } from "common/components/popovers";
import { Expandable } from "common/components/Transitions/Expandable";
import { MessageKey } from "common/internationalization";
import { SupportedCountry } from "common/types";

import { FormLabel, LabelWithReset, TLabelSize } from "../components";
import { FormHelperText } from "../components/FormHelperText";
import {
  DropdownChipsDefault,
  DropdownChipsGrouped,
  DropdownInput,
  DropdownInputInline,
  DropdownOptions,
  DropdownSkeleton,
} from "./components";
import {
  DropdownMode,
  DropdownSize,
  DropdownType,
  isMultipleChoice,
  TDropdownOption,
} from "./types";
import {
  filterMatchingOptions,
  getPlaceholderText,
  getSelectedOptions,
} from "./utils";

export type DropdownProps = {
  form: UseFormReturn<any>;
  name: string;
  label?: string | JSX.Element;
  helperText?: string;
  options: TDropdownOption[];
  withError?: boolean;
  withReset?: boolean;
  withSkeleton?: boolean;
  registerOptions?: RegisterOptions;
  labelSize?: TLabelSize;
  placeholderMsgKey?: MessageKey;
  type?: DropdownType;
  disabled?: boolean;
  chipsMode?: "default" | "grouped";
  mode?: DropdownMode;
  hideMainCheckboxes?: boolean;
  collapsingSubOptions?: boolean;
  customOptionsClassname?: string;
  inputClassName?: string;
  testId?: string;
  size?: DropdownSize;
  iconLeftName?: IconName | SupportedCountry;
  trailingElement?: JSX.Element;
  withLabelCount?: boolean;
  searchOff?: boolean;
  selectIfOnlyOneOption?: boolean;
  unselectSingleChoiceOn?: boolean; // unselect selected singleChoice option on click - sets undefined as value
  formatCreatedOption?: (value: string) => string;
  onToggleDropdown?: (isOpen: boolean | undefined) => void;
  checkboxPosition?: "left" | "right";
  selectAll?: boolean;
};

export const Dropdown = ({
  form,
  name,
  label,
  options,
  withReset = false,
  withSkeleton = false,
  registerOptions = {},
  labelSize = "regular",
  placeholderMsgKey,
  type = "singleChoice",
  disabled = false,
  chipsMode,
  mode = "default",
  helperText,
  withError = mode === "default",
  hideMainCheckboxes = false,
  collapsingSubOptions = false,
  customOptionsClassname = "",
  inputClassName = "",
  testId = name,
  size = "default",
  iconLeftName,
  trailingElement,
  withLabelCount,
  searchOff,
  selectIfOnlyOneOption = true,
  unselectSingleChoiceOn = false,
  formatCreatedOption,
  onToggleDropdown,
  checkboxPosition,
  selectAll,
}: DropdownProps) => {
  const errors = get(form.formState.errors, name, "");

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchPhrase, setSearchPhrase] = useState("");

  const { onChange } = form.register(name, {
    ...registerOptions,
    onChange: () => {
      form.clearErrors(name);
    },
  });

  const value =
    type === "multipleChoice"
      ? form.getValues(name) || []
      : form.getValues(name);

  const filteredOptions = filterMatchingOptions(
    options,
    searchPhrase,
    type === "singleCreatable",
    formatCreatedOption,
  );
  const selectedOption = getSelectedOptions(type, value, options);
  const placeholderText = getPlaceholderText(
    type,
    selectedOption,
    placeholderMsgKey,
    withLabelCount,
  );

  // autofill select if only one option is available
  useEffect(() => {
    if (
      selectIfOnlyOneOption &&
      registerOptions?.required &&
      options.length === 1 &&
      type !== "singleCreatable"
    ) {
      if (!options[0].children)
        form.resetField(name, {
          keepDirty: true,
          defaultValue:
            type === "singleChoice" ? options[0].value : [options[0].value],
        });
      else if (options[0].children.length === 1) {
        form.resetField(name, {
          keepDirty: true,
          defaultValue:
            type === "singleChoice"
              ? options[0].children![0].value
              : [options[0].children![0].value],
        });
      }
    }
  }, [options[0]?.value]);

  // guards
  if (chipsMode && type !== "multipleChoice") {
    throw Error(
      "`chipsMode` prop can be used only with multipleChoice `type`.",
    );
  }
  if (chipsMode && mode === "inline") {
    throw Error("`chipsMode` prop can only be used with default mode");
  }
  if (withError && mode === "inline") {
    throw Error("`withError` prop can only be used with default mode");
  }
  if (unselectSingleChoiceOn && type !== "singleChoice") {
    throw Error(
      "`unselectSingleChoiceOn` prop can only be used with singleChoice `type`",
    );
  }

  const handleOnChange = (value: any) => {
    const prevValue = form.getValues(name);
    const event = {
      target: {
        name,
        value,
        prevValue,
      },
    };

    if (type !== "singleCreatable") {
      onChange(event);
      if (registerOptions.onChange) registerOptions.onChange(event);
      return;
    }

    const itemFromList = options.find((option) => option.label === value);
    event.target.value = itemFromList ? itemFromList.value : value;

    onChange(event);
    if (registerOptions.onChange) registerOptions.onChange(event);
  };

  const onReset = () => {
    form.resetField(name);
  };

  const onInputKeyDown = (
    key: React.KeyboardEvent["key"],
    onClose: () => void,
  ) => {
    if (key === "Enter" && searchPhrase.length) {
      if (filteredOptions.length > 0) {
        handleOnChange(
          ["singleChoice", "singleCreatable"].includes(type)
            ? filteredOptions[0].value
            : [...value, filteredOptions[0].value],
        );
        onClose();
      }
    }
  };

  if (withSkeleton) {
    return <DropdownSkeleton />;
  }

  const inputComponent =
    mode === "inline" ? (
      <DropdownInputInline
        testId={testId}
        selectedOption={selectedOption}
        placeholder={placeholderText}
        disabled={disabled}
      />
    ) : (
      <DropdownInput
        testId={testId}
        type={type}
        searchInputRef={searchInputRef}
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        onChange={handleOnChange}
        onInputKeyDown={onInputKeyDown}
        placeholder={placeholderText}
        value={(selectedOption as TDropdownOption)?.label}
        hasError={!!errors}
        disabled={disabled}
        size={size}
        iconLeftName={iconLeftName}
        trailingElement={trailingElement}
        searchOff={searchOff}
      />
    );

  return (
    <div
      className={classNames("relative flex flex-col h-full", {
        "w-fit": size === "compact" || size === "compactSmall",
      })}
      data-testid={`${testId}-dropdown`}
      id={name}
    >
      {label && withReset && (
        <LabelWithReset
          label={label}
          hasChanged={!!form.formState.dirtyFields[name]}
          reset={onReset}
        />
      )}
      {label && !withReset && <FormLabel label={label} size={labelSize} />}

      {disabled ? (
        <div
          className={twMerge(classNames("h-full max-h-[46px]", inputClassName))}
        >
          {inputComponent}
        </div>
      ) : (
        <Popover
          testId={`${testId}-dropdown`}
          reference={inputComponent}
          referenceStyling={twMerge(
            classNames("h-full max-h-[46px]", inputClassName),
          )}
          onChange={onToggleDropdown}
          popover={
            <div
              className={classNames(
                "drop-shadow-md w-fit bg-white rounded-[5px]",
                { "w-full": mode === "inline" || size === "full" },
              )}
            >
              <ul
                className={classNames(
                  "overflow-y-auto overflow-x-hidden py-2",
                  {
                    "max-h-[360px]": type === "multipleChoice",
                    "max-h-44": type === "singleChoice",
                    "w-[500px]":
                      size === "default" &&
                      mode !== "inline" &&
                      !customOptionsClassname,
                    "w-[305px]":
                      (size === "small" || size === "advertiser") &&
                      mode !== "inline" &&
                      !customOptionsClassname,
                    "w-fit":
                      (size === "compact" || size === "compactSmall") &&
                      !customOptionsClassname,
                    "w-full":
                      (mode === "inline" || size === "full") &&
                      !customOptionsClassname,
                    [customOptionsClassname]: !!customOptionsClassname,
                  },
                )}
              >
                <DropdownOptions
                  value={value}
                  type={type}
                  filteredOptions={filteredOptions}
                  onChange={handleOnChange}
                  searchPhrase={searchPhrase}
                  selected={selectedOption}
                  testId={testId || name}
                  hideMainCheckboxes={hideMainCheckboxes}
                  collapsingSubOptions={collapsingSubOptions}
                  unselectSingleChoiceOn={unselectSingleChoiceOn}
                  checkboxPosition={checkboxPosition}
                  selectAll={selectAll}
                />
              </ul>
            </div>
          }
        />
      )}
      {isMultipleChoice(selectedOption) && chipsMode && (
        <Expandable isOpen={selectedOption.length > 0}>
          <div className={classNames({ "mt-3": selectedOption.length > 0 })} />
          {chipsMode === "default" && (
            <DropdownChipsDefault
              selected={selectedOption}
              onChange={handleOnChange}
              disabled={disabled}
            />
          )}
          {chipsMode === "grouped" && (
            <DropdownChipsGrouped
              selected={selectedOption}
              onChange={handleOnChange}
              options={options}
              disabled={disabled}
            />
          )}
        </Expandable>
      )}
      {(helperText || withError) && (
        <FormHelperText helperText={helperText} name={name} errors={errors} />
      )}
    </div>
  );
};

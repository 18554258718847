import classNames from "classnames";
import React, { useContext } from "react";

import { Icon } from "common/components/Icon";
import { PopoverContext } from "common/components/popovers/context";
import { VOWCluster } from "common/components/VOWCluster";

import { TDropdownOption, isMultipleChoice } from "../types";

export type TDropdownInputInline = {
  testId: string;
  selectedOption?: TDropdownOption | TDropdownOption[];
  placeholder?: string;
  disabled?: boolean;
};

export const DropdownInputInline: React.FC<TDropdownInputInline> = ({
  testId,
  selectedOption,
  placeholder,
  disabled,
}) => {
  const { isOpen } = useContext(PopoverContext);

  const showPlaceholder = isMultipleChoice(selectedOption)
    ? selectedOption.length === 0
    : !selectedOption;

  return (
    <div
      data-testid={`${testId}-dropdown-button`}
      className={classNames(
        "h-[34px] group/container w-full rounded flex items-center justify-between mr-2 duration-200",
        {
          "bg-white-300 px-2": isOpen,
          "hover:bg-white-300 hover:px-2": !isOpen,
          "cursor-not-allowed": disabled,
        },
      )}
    >
      {showPlaceholder ? (
        <p
          className={classNames({
            "text-gray-800": !disabled,
            "text-gray-800/40": disabled,
          })}
        >
          {placeholder}
        </p>
      ) : isMultipleChoice(selectedOption) ? (
        <VOWCluster
          id={`${testId}-selected-options`}
          visibleItemsCount={2}
          additionalHiddenItemsStylings="max-w-[80px]"
          items={selectedOption.map((option) => (
            <React.Fragment key={option.label}>
              {option.selectedInputEl}
            </React.Fragment>
          ))}
        />
      ) : (
        <p>{selectedOption?.label}</p>
      )}
      <div
        className={classNames({
          "hidden group-hover/container:block [&>button:hover]:bg-transparent":
            !isOpen,
          "bg-transparent": isOpen,
          "text-gray-800": showPlaceholder && !disabled,
          "text-gray-800/40": showPlaceholder && disabled,
        })}
      >
        <Icon name={isOpen ? "caretUp" : "caretDown"} />
      </div>
    </div>
  );
};

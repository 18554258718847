import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { BASENAME } from "App/components/AppRoutes/routes";

export const useIsPageActive = () => {
  const location = useLocation();

  return useCallback(
    (page: string | string[]) => {
      const path = location.pathname.replace(BASENAME, "");
      if (typeof page === "string") {
        return path.startsWith(page.toLowerCase());
      }

      return page.some((p) => path.startsWith(p.toLowerCase()));
    },
    [location.pathname],
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Cluster } from "./Cluster";
import { TitleShortener } from "./TitleShortener";
import { Tooltip } from "./Tooltip/Tooltip";

export const VOWCluster: React.FC<{
  id: string;
  items: string[] | JSX.Element[];
  customStyling?: string;
  additionalStyling?: string;
  visibleItemsCount?: number;
  hiddenStringItemsInLineCount?: number;
  additionalVisibleItemsStyling?: string;
  additionalTriggerItemStyling?: string;
  additionalHiddenItemsStylings?: string;
  tooltipClassName?: string;
}> = ({
  id,
  items,
  customStyling,
  additionalStyling = "",
  visibleItemsCount = 1,
  hiddenStringItemsInLineCount,
  additionalVisibleItemsStyling = "150px",
  additionalTriggerItemStyling,
  additionalHiddenItemsStylings,
  tooltipClassName,
}) => {
  // commented code is used for dynamically calculating what amount of items should be in cluster based on the width of span parent
  // const [node, setNode] = useState<Element | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemsLength, setItemsLength] = useState(visibleItemsCount);

  // const useDetectNodeChange = () => {
  //   const ref = useCallback((node: Element | null) => {
  //     if (node !== null) {
  //       setNode(node);
  //     }
  //   }, []);

  //   return [itemsLength, ref] as const;
  // };

  // const calculateItemsLength = useCallback(() => {
  //   if (node) {
  //     let i = 0;
  //     while (i <= items.length) {
  //       if (node.parentNode) {
  //         const parentWidth = (node.parentNode as HTMLDivElement).clientWidth;
  //         if (
  //           getTextWidth(items.slice(0, i).join(", ")) >
  //           (parentWidth > 100 ? parentWidth * 0.75 : parentWidth * 0.5)
  //         ) {
  //           i -= 1;
  //           break;
  //         }
  //       }
  //       i += 1;
  //     }
  //     setItemsLength(i);
  //   }
  // }, [node]);

  // useLayoutEffect(() => {
  //   calculateItemsLength();
  //   if (node) window.addEventListener("resize", calculateItemsLength);
  //   return () => {
  //     window.removeEventListener("resize", calculateItemsLength);
  //   };
  // }, [node]);

  // const [len, ref] = useDetectNodeChange();

  // const getTextWidth = (text: string) => {
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   if (context) {
  //     context.font = font;

  //     return context.measureText(text).width;
  //   }
  //   return 0;
  // };

  useEffect(() => setItemsLength(visibleItemsCount), [visibleItemsCount]);

  const generateVisibleItems = () => {
    if (typeof items[0] === "string") {
      return (
        // use ref in below to use commented code
        <span
          data-testid="visible-items-cluster"
          className={`whitespace-break-spaces overflow-hidden text-14 ${additionalVisibleItemsStyling}`}
        >
          <TitleShortener title={items.slice(0, itemsLength).join(", ")} />
        </span>
      );
    }

    return (
      // use ref in below to use commented code
      items.slice(0, itemsLength)
    );
  };

  const generateHiddenItems = () => {
    if (typeof items[0] === "string") {
      let itemsToDisplay = [];

      if (!hiddenStringItemsInLineCount) {
        itemsToDisplay = items.slice(itemsLength);
      } else {
        for (
          let i = itemsLength;
          i < items.length;
          i += hiddenStringItemsInLineCount
        ) {
          itemsToDisplay.push(
            items.slice(i, i + hiddenStringItemsInLineCount).join(", "),
          );
        }
      }

      return (
        <div>
          {itemsToDisplay.map((el) => (
            <div key={el as string}>
              <span data-testid="tooltip-text" className="whitespace-nowrap">
                {el}
              </span>
              <br />
            </div>
          ))}
        </div>
      );
    }

    return (
      <div
        className={`flex items-center gap-2 flex-wrap ${additionalHiddenItemsStylings}`}
      >
        {items.slice(itemsLength)}
      </div>
    );
  };

  return (
    <div
      className={
        customStyling ??
        `${additionalStyling} flex items-center gap-x-2 min-w-max relative`
      }
    >
      {generateVisibleItems()}
      {items.slice(itemsLength).length !== 0 && (
        <Tooltip
          testId={id}
          placement="top"
          theme="light"
          classNames={{
            content: classNames("w-max", tooltipClassName),
            trigger: "h-full",
          }}
          content={generateHiddenItems()}
          trigger={
            <Cluster
              testId="format-tooltip"
              text={`+${items.slice(itemsLength).length}`}
              additionalClasses={additionalTriggerItemStyling}
            />
          }
        />
      )}
    </div>
  );
};

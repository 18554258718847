import {
  ArrowBendDownRight,
  ArrowLeft,
  ArrowRight,
  Basket,
  Browser,
  CalendarBlank,
  Cards,
  CaretDown,
  CaretLeft,
  CaretUp,
  ChartLine,
  ChartBar,
  CheckCircle,
  Circle,
  Copy,
  DeviceMobile,
  Download,
  DownloadSimple,
  FileXls,
  CaretRight,
  Funnel,
  GlobeSimple,
  Info,
  MagnifyingGlass,
  MonitorPlay,
  PencilSimple,
  PencilSimpleLine,
  Plus,
  TrafficSignal,
  Trash,
  UsersThree,
  WarningCircle,
  X,
  Check,
  DotsThree,
  IdentificationBadge,
  IdentificationCard,
  UserRectangle,
  Browsers,
  CloudArrowUp,
  Image,
  MegaphoneSimple,
  Question,
  Warning,
  ArrowClockwise,
  Link,
  Archive,
  EyeSlash,
  Eye,
  FileCsv,
  Play,
  ClockClockwise,
  ArrowsInSimple,
  ArrowsOutSimple,
  Coin,
  Coins,
  Cactus,
  TrendUp,
  TrendDown,
  Minus,
  ImageSquare,
  FilmSlate,
  Crosshair,
  ShoppingBagOpen,
  ShoppingBag,
  SquareHalf,
  Megaphone,
  Magnet,
  Gear,
  ArrowFatUp,
  ArrowFatDown,
  VideoCamera,
  Star,
  ArrowDown,
  PresentationChart,
  ArrowsInLineHorizontal,
  ArrowsOutLineHorizontal,
  GearSix,
  Intersect,
  Monitor,
  BellSimpleRinging,
  UserCirclePlus,
  ShoppingCart,
  Handbag,
  ClipboardText,
  Envelope,
  DotsThreeCircle,
  Stack,
} from "@phosphor-icons/react";

import {
  CheckboxChecked,
  CheckboxPartiallyChecked,
  CheckboxUnChecked,
} from "./components";

export const icons = {
  creatives: Image,
  strategy: MegaphoneSimple,
  categories: Cards,
  chartLine: ChartLine,
  report: ChartBar,
  search: MagnifyingGlass,
  add: Plus,
  download: Download,
  downloadSimple: DownloadSimple,
  delete: Trash,
  duplicate: Copy,
  fileCsv: FileCsv,
  fileXls: FileXls,
  edit: PencilSimple,
  editLine: PencilSimpleLine,
  close: X,
  check: Check,
  checkboxChecked: CheckboxChecked,
  checkboxUnChecked: CheckboxUnChecked,
  checkboxPartiallyChecked: CheckboxPartiallyChecked,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  caretUp: CaretUp,
  caretDown: CaretDown,
  info: Info,
  intersect: Intersect,
  calendar: CalendarBlank,
  filter: Funnel,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  arrowDown: ArrowDown,
  arrowEnter: ArrowBendDownRight,
  market: GlobeSimple,
  channel: Browser,
  status: TrafficSignal,
  device: DeviceMobile,
  format: MonitorPlay,
  warningCircle: WarningCircle,
  successCircle: CheckCircle,
  emptyCircle: Circle,
  audience: UsersThree,
  identificationBadge: IdentificationBadge,
  identificationCard: IdentificationCard,
  tripleDot: DotsThree,
  vector: Funnel,
  userRectangle: UserRectangle,
  browsers: Browsers,
  cloudArrowUp: CloudArrowUp,
  image: Image,
  imageSquare: ImageSquare,
  video: FilmSlate,
  question: Question,
  warning: Warning,
  arrowClockwise: ArrowClockwise,
  link: Link,
  archive: Archive,
  eyeSlash: EyeSlash,
  eye: Eye,
  reactivate: Play,
  clockClockwise: ClockClockwise,
  arrowsInSimple: ArrowsInSimple,
  arrowsOutSimple: ArrowsOutSimple,
  coin: Coin,
  coins: Coins,
  cactus: Cactus,
  minus: Minus,
  trendDown: TrendDown,
  trendUp: TrendUp,
  AWARENESS: Crosshair,
  CONVERSION: ShoppingBagOpen,
  engagement: Megaphone,
  CONSIDERATION: Magnet,
  settings: Gear,
  arrowFatUp: ArrowFatUp,
  arrowFatDown: ArrowFatDown,
  videoCamera: VideoCamera,
  star: Star,
  presentationChart: PresentationChart,
  arrowsInLineHorizontal: ArrowsInLineHorizontal,
  arrowsOutLineHorizontal: ArrowsOutLineHorizontal,
  gearSix: GearSix,
  monitor: Monitor,
  bellSimpleRinging: BellSimpleRinging,
  userCirclePlus: UserCirclePlus,
  shoppingCart: ShoppingCart,
  shoppingBag: ShoppingBag,
  squareHalf: SquareHalf,
  handbag: Handbag,
  clipboardText: ClipboardText,
  envelope: Envelope,
  dotsThreeCircle: DotsThreeCircle,
  basket: Basket,
  stack: Stack,
};

import { useState } from "react";

import { TGlobalMessage } from "App/components/AppRoutes/components/types";
import { useCarouselContext } from "common/components/Carousel/context";
import { useFormatMessage } from "common/internationalization";

import { DetailedMessageModal } from "./DetailedMessageModal";
import { useDiscardMessage } from "./utils";

type Props = {
  severity: TGlobalMessage["severity"];
};

export const MessageActions: React.FC<Props> = ({ severity }) => {
  const formatMessage = useFormatMessage();
  const { items, selectedIndex } = useCarouselContext();
  const [isReadMoreModal, setIsReadMoreModal] = useState(false);

  const discardMutation = useDiscardMessage(setIsReadMoreModal);

  const handleReadMore = () => {
    setIsReadMoreModal(true);
  };

  const onCancel = () => {
    setIsReadMoreModal(false);
  };

  return (
    <div className="flex items-center gap-x-5">
      <button data-testid="read-more-message-button" onClick={handleReadMore}>
        <span className="text-blue-300 font-bold whitespace-nowrap">
          {formatMessage("common.readMore")}
        </span>
      </button>
      <button
        data-testid="dismiss-message-button"
        onClick={() =>
          discardMutation((items[selectedIndex] as TGlobalMessage).id)
        }
      >
        <span className="text-blue-300 font-bold capitalize whitespace-nowrap">
          {formatMessage("words.dismiss")}
        </span>
      </button>
      {isReadMoreModal && (
        <DetailedMessageModal
          severity={severity}
          onCancel={onCancel}
          onDismiss={discardMutation}
        />
      )}
    </div>
  );
};

import classNames from "classnames";

import { InputDate } from "common/components/form/inputs";
import { validateDatePattern } from "common/components/form/validators";
import { useFormatMessage } from "common/internationalization";
import { SetState } from "common/types";

import { DatePickerParams, DatePickerSelectType } from "../types";

type Props = Pick<
  DatePickerParams,
  | "form"
  | "dateFormat"
  | "monthsShown"
  | "disableStart"
  | "disableEnd"
  | "quickFiltersLayout"
  | "dateInputsLayout"
  | "requiredFields"
> & {
  dateRangeError: string | null;
  setSelects: SetState<DatePickerSelectType>;
  onChangeDate: (
    date: Date | [Date | null, Date | null] | null,
    change: DatePickerSelectType | null,
  ) => void;
};

export const DateInputs: React.FC<Props> = ({
  form,
  dateFormat,
  disableStart,
  disableEnd,
  setSelects,
  onChangeDate,
  dateInputsLayout = "row",
  requiredFields = ["upper", "lower"],
}) => {
  const formatMessage = useFormatMessage();
  const { selector } = form.getValues();

  return (
    <div
      className={classNames("flex w-full", {
        "gap-x-7": dateInputsLayout === "row",
        "flex-col": dateInputsLayout === "column",
      })}
    >
      <InputDate
        form={form}
        name="lower"
        dateFormat={dateFormat}
        label={formatMessage("common.componenets.datePicker.startDate")}
        onFocus={() => setSelects(DatePickerSelectType.START)}
        onChange={(date) => {
          setSelects(DatePickerSelectType.START);
          onChangeDate(date, DatePickerSelectType.START);
        }}
        registerOptions={{
          required: selector !== "lifetime" && requiredFields.includes("lower"),
          validate: { validateDatePattern },
        }}
        disabled={disableStart}
      />
      <InputDate
        form={form}
        name="upper"
        dateFormat={dateFormat}
        label={formatMessage("common.components.datePicker.endDate")}
        onFocus={() => setSelects(DatePickerSelectType.END)}
        onChange={(date) => {
          setSelects(DatePickerSelectType.END);
          onChangeDate(date, DatePickerSelectType.END);
        }}
        registerOptions={{
          required: selector !== "lifetime" && requiredFields.includes("upper"),
          validate: { validateDatePattern },
        }}
        placeholder={
          requiredFields.includes("upper")
            ? ""
            : formatMessage("words.optional")
        }
        disabled={disableEnd}
      />
    </div>
  );
};

import { IconWeight } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import { Country } from "common/types";
import { getAppContainer } from "common/utils/getAppContainer";

import { Icon, IconName, IconSize } from "../Icon";
import { TooltipContent, TooltipTrigger, TooltipWrapper } from "./components";
import { TooltipBehaviour, TooltipOptions, TooltipTheme } from "./types";

const defaultIconTriggerProps: { name: IconName | Country } = {
  name: "info",
};

type Props = {
  testId: string;
  content: string | JSX.Element;
  trigger?: JSX.Element;
  iconTriggerProps?: {
    name?: IconName | Country;
    weight?: IconWeight;
    size?: IconSize;
    color?: string;
  };
  classNames?: {
    wrapper?: string;
    content?: string;
    trigger?: string;
  };
  arrow?: boolean;
  theme?: TooltipTheme;
  disabled?: boolean;
  behaviour?: TooltipBehaviour;
} & Pick<TooltipOptions, "placement" | "offset" | "initialOpen">;

export const Tooltip: React.FC<Props> = ({
  testId,
  content,
  trigger: triggerProp,
  classNames: classNamesProp,
  arrow = false,
  theme = "light",
  initialOpen = false,
  disabled = false,
  iconTriggerProps: iconTriggerPropsInit = {
    name: "info",
    size: "medium",
  },
  behaviour = "floating",
  ...rest
}) => {
  const [open, setOpen] = useState(initialOpen);
  const iconTriggerProps = {
    ...defaultIconTriggerProps,
    ...iconTriggerPropsInit,
  };

  useEffect(() => {
    const appContainer = getAppContainer();
    const callback = () => {
      setOpen(false);
    };
    if (open) appContainer.addEventListener("scroll", callback);
    return () => {
      appContainer.removeEventListener("scroll", callback);
    };
  }, [open]);

  const trigger = triggerProp || <Icon {...iconTriggerProps} />;

  return (
    <div
      className={classNamesProp?.wrapper}
      onMouseLeave={() => setOpen(false)}
    >
      <TooltipWrapper
        open={open}
        onOpenChange={setOpen}
        arrow={arrow}
        {...rest}
      >
        <TooltipTrigger
          testId={testId}
          className={classNamesProp?.trigger}
          onClick={() => setOpen((v) => !v)}
          onMouseEnter={() => setOpen(true)}
        >
          {trigger}
        </TooltipTrigger>

        <TooltipContent
          testId={testId}
          contentClassName={classNamesProp?.content}
          arrow={arrow}
          theme={theme}
          disabled={disabled}
          behaviour={behaviour}
        >
          {content}
        </TooltipContent>
      </TooltipWrapper>
    </div>
  );
};

import { generatePath } from "react-router-dom";

import {
  AUDIENCE_ROOT,
  AUDIENCE_SETS_INDEX,
  BASENAME,
  CREATIVE_LIST,
  CREATIVE_ROOT,
  DASHBOARD_ROOT,
  REPORTING_CAMPAIGNS_INDEX,
  REPORTING_ROOT,
  STRATEGY_ROOT,
  GENUS_ROOT,
} from "App/components/AppRoutes/routes";
import { RestrictedDisplay } from "common/components/RestrictedDisplay";
import { useIsPageActive } from "common/hooks/useIsPageActive";
import { useFormatMessage } from "common/internationalization";

import { NavigationItem } from "./components";

export const NonAdministrationNavItems = () => {
  const formatMessage = useFormatMessage();
  const isActive = useIsPageActive();

  return (
    <>
      <NavigationItem
        name={formatMessage("pageTitle.dashboard")}
        isActive={isActive(DASHBOARD_ROOT)}
        iconSrc={`${BASENAME}/icons/navigation/dashboard.svg`}
        iconActiveSrc={`${BASENAME}/icons/navigation/dashboardSelected.svg`}
        iconAlt="dashboard"
        basicUrl={DASHBOARD_ROOT}
      />
      <RestrictedDisplay scopes={["can_view_strategies"]}>
        <NavigationItem
          name={formatMessage("pageTitle.strategies")}
          isActive={isActive(STRATEGY_ROOT)}
          iconSrc={`${BASENAME}/icons/navigation/strategies.svg`}
          iconActiveSrc={`${BASENAME}/icons/navigation/strategiesSelected.svg`}
          iconAlt="strategies"
          basicUrl={STRATEGY_ROOT}
        />
      </RestrictedDisplay>
      <RestrictedDisplay scopes={["can_view_audiences"]}>
        <NavigationItem
          name={formatMessage("pageTitle.targeting")}
          isActive={isActive(AUDIENCE_ROOT)}
          iconSrc={`${BASENAME}/icons/navigation/targeting.svg`}
          iconActiveSrc={`${BASENAME}/icons/navigation/targetingSelected.svg`}
          iconAlt="targeting"
          basicUrl={AUDIENCE_SETS_INDEX}
        />
      </RestrictedDisplay>
      <RestrictedDisplay scopes={["can_view_creatives"]}>
        <NavigationItem
          name={formatMessage("pageTitle.creatives")}
          isActive={isActive(CREATIVE_ROOT)}
          iconSrc={`${BASENAME}/icons/navigation/creatives.svg`}
          iconActiveSrc={`${BASENAME}/icons/navigation/creativesSelected.svg`}
          iconAlt="creatives"
          basicUrl={generatePath(CREATIVE_LIST, { tabName: "creatives" })}
        />
      </RestrictedDisplay>
      <NavigationItem
        name={formatMessage("pageTitle.reporting")}
        isActive={isActive(REPORTING_ROOT)}
        iconSrc={`${BASENAME}/icons/navigation/reporting.svg`}
        iconActiveSrc={`${BASENAME}/icons/navigation/reportingSelected.svg`}
        iconAlt="reporting"
        basicUrl={REPORTING_CAMPAIGNS_INDEX}
      />
      <RestrictedDisplay flagName="genus_ai_creation">
        <NavigationItem
          name={formatMessage("pageTitle.creation")}
          isActive={isActive(GENUS_ROOT)}
          iconSrc={`${BASENAME}/icons/navigation/creation.svg`}
          iconActiveSrc={`${BASENAME}/icons/navigation/creationSelected.svg`}
          iconAlt="creation"
          basicUrl={GENUS_ROOT}
        />
      </RestrictedDisplay>
    </>
  );
};

import { DocumentHead } from "App/components/AppRoutes/components";
import { Button } from "common/components/buttons/Button";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { useBrand } from "common/hooks/useBrand";
import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";
import { useFormatMessage } from "common/internationalization";
import { sendHelpCenterMail } from "common/utils/sendHelpCenterMail";

const Error503Page = () => {
  const formatMessage = useFormatMessage();
  const brand = useBrand();
  const navigate = useNavigateWithAdvertiser();

  return (
    <>
      <DocumentHead pageName={formatMessage("serviceUnavailable")} />
      <ErrorLayout
        title="503"
        subtitle={formatMessage("serviceUnavailable")}
        message={formatMessage("serviceUnavailable.info")}
      >
        {brand.support_email && (
          <Button
            onClick={() => sendHelpCenterMail(brand.support_email)}
            styling="blue-button"
            additionalClasses="w-full mx-2 capitalize"
            dataTestId="help-center"
          >
            {formatMessage("contactUs")}
          </Button>
        )}
        <Button
          dataTestId="refresh"
          onClick={() => {
            navigate(0);
          }}
          styling="blue-button"
          additionalClasses="w-full mx-2"
        >
          {formatMessage("refresh")}
        </Button>
      </ErrorLayout>
    </>
  );
};

export default Error503Page;

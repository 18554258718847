import classNames from "classnames";
import { Suspense, useContext } from "react";
import { Link } from "react-router-dom";

import { useBrand } from "common/hooks/useBrand";
import { useDefaultRoute } from "common/hooks/useDefaultRoute";
import { useIsPageActive } from "common/hooks/useIsPageActive";
import {
  getHrefNavbarLink,
  preventRedirectToSamePage,
} from "common/utils/navigation";
import { clearAllTablesData, useAppDispatch } from "modules/store";

import { NavContext } from "../context";
import { Navigation } from "./components";

export const NavBar = () => {
  const isActive = useIsPageActive();
  const { show } = useContext(NavContext);
  const dispatch = useAppDispatch();
  const defaultRoute = useDefaultRoute();
  const brand = useBrand();

  const isAdministrationPanel = isActive("/administration");

  if (!show) return null;
  return (
    <div
      className={classNames(
        "h-full shrink-0 flex flex-col align-stretch w-[60px] z-[1000]",
        {
          "bg-violet-500": isAdministrationPanel,
          "bg-blue-900": !isAdministrationPanel,
        },
      )}
    >
      <Link
        to={getHrefNavbarLink(defaultRoute)}
        onClick={(e) => {
          preventRedirectToSamePage(e, defaultRoute);
          dispatch(clearAllTablesData());
        }}
        data-testid="logo-click"
        className="self-center"
      >
        <img
          src={`${brand.logo_file_url}`}
          alt={`${brand.application_name} logo`}
        />
      </Link>
      <div className="grow pt-4">
        <Suspense>
          <Navigation />
        </Suspense>
      </div>
    </div>
  );
};

import { capitalize, uniqBy } from "lodash-es";

import { MessageKey, formatMessage } from "common/internationalization";

import { DropdownType, TDropdownOption } from "./types";

export const filterMatchingOptions = (
  options: TDropdownOption[],
  phrase: string,
  creatable: boolean,
  formatCreatedOption?: (value: string) => string,
): TDropdownOption[] => {
  const filteredOptions = options.reduce(
    (acc: TDropdownOption[], option: TDropdownOption) => {
      const isOptionMatches = option.label
        .toLowerCase()
        .includes(phrase.toLowerCase());

      if (option.children) {
        if (isOptionMatches) {
          return [...acc, option];
        }
        const childrenMatches = filterMatchingOptions(
          option.children,
          phrase,
          false,
        );
        if (childrenMatches.length) {
          return [...acc, { ...option, children: childrenMatches }];
        }
      } else if (isOptionMatches) {
        return [...acc, option];
      }
      return acc;
    },
    [],
  );

  if (
    creatable &&
    phrase.length &&
    !filteredOptions.some((option) => option.label === phrase)
  )
    return [
      ...filteredOptions,
      {
        value: phrase,
        label: phrase,
        dropdownLabel: (
          <p className="whitespace-normal break-all">{`${capitalize(
            formatMessage("words.create"),
          )} "${
            formatCreatedOption ? formatCreatedOption(phrase) : phrase
          }"`}</p>
        ),
        manuallyCreated: true,
      },
    ];

  return filteredOptions;
};

export const getSelectedOptions = (
  type: DropdownType,
  value: string | number | (string | number)[],
  options: TDropdownOption[],
) => {
  const flatOptions = options.reduce(
    (acc: TDropdownOption[], option: TDropdownOption) =>
      option.children ? [...acc, ...option.children] : [...acc, option],
    [],
  );

  if (type === "singleChoice") {
    return flatOptions.find(
      (option: TDropdownOption) => option.value === value,
    );
  }

  if (type === "singleCreatable") {
    return value
      ? (flatOptions.find(
          (option: TDropdownOption) => option.value === value,
        ) ??
          ({
            value,
            label: value,
            manuallyCreated: true,
          } as TDropdownOption))
      : undefined;
  }
  const selectedOptions = flatOptions.filter((option) =>
    (value as (string | number)[]).includes(option.value),
  );

  const hasChildren = options.some((option) => option.children);
  if (!hasChildren) {
    return selectedOptions;
  }

  options
    .filter((option) => (value as (string | number)[]).includes(option.value))
    .forEach((opt) => {
      selectedOptions.push(opt);
    });
  return uniqBy(selectedOptions, (el) => el.value);
};

export const getPlaceholderText = (
  type: DropdownType,
  selectedOption: TDropdownOption | TDropdownOption[] | undefined,
  placeholderMsgKey: MessageKey | undefined,
  withLabelCount?: boolean,
) => {
  if (["singleChoice", "singleCreatable"].includes(type) && !withLabelCount) {
    return (
      (selectedOption as TDropdownOption)?.label ||
      (placeholderMsgKey && formatMessage(placeholderMsgKey))
    );
  }

  if (!placeholderMsgKey) return "";
  if (["singleChoice", "singleCreatable"].includes(type) && withLabelCount) {
    return formatMessage(placeholderMsgKey, {
      values: {
        count: selectedOption ? 1 : 0,
      },
    });
  }
  return formatMessage(placeholderMsgKey, {
    values: {
      count: (selectedOption as TDropdownOption[]).filter(
        (option) => !option.children,
      ).length,
    },
  });
};

export const getParent = <T,>(
  value: string | number,
  options: TDropdownOption<T>[],
) => options.find((opt) => opt.children?.map((c) => c.value)?.includes(value));

export const areAllChildrenSelected = <T,>(
  parent: TDropdownOption<T>,
  selected: TDropdownOption<T>[],
) => {
  const selectedValues = (selected as TDropdownOption<T>[])?.map(
    (s) => s.value,
  );
  const childrenValues = parent?.children?.map((s) => s.value);
  return (
    selectedValues.filter((v) => childrenValues?.includes(v)).length ===
    childrenValues?.length
  );
};

import { TitleShortener } from "common/components/TitleShortener";
import { Currency } from "common/types";

export type NumericValueType = "currency" | "number" | "percentage";
export type NotationType = "compact" | "standard";

const formatValue = (
  value: number,
  decimalsLength = 2,
  style: "decimal" | "percent" = "decimal",
  notation: NotationType = "standard",
): string =>
  new Intl.NumberFormat(navigator.language || "en", {
    style,
    notation,
    maximumFractionDigits: decimalsLength,
  }).format(value) || "";

export const formatCurrencyValue = (
  value: number,
  currency?: Currency,
  maximumFractionDigits = 2,
  notation: NotationType = "standard",
): string =>
  new Intl.NumberFormat(navigator.language || "en", {
    style: "currency",
    currency: currency || "USD",
    notation,
    maximumFractionDigits,
    currencyDisplay: (currency || "USD") === "USD" ? "narrowSymbol" : undefined,
  }).format(value) || "";

export const formatNumericValue = (
  type: NumericValueType,
  value: number,
  currency?: Currency,
  decimalsLength?: number,
  notation?: NotationType,
): string => {
  switch (type) {
    case "currency":
      return formatCurrencyValue(value, currency, 2, notation);
    case "number":
      return formatValue(value, decimalsLength, "decimal", notation);
    case "percentage":
      return formatValue(value / 100, decimalsLength, "percent", notation);
    default:
      return "";
  }
};

export const formatNumericValueFullWidth = (
  type: NumericValueType,
  value: number,
  currency?: Currency,
  decimalsLength?: number,
): JSX.Element => {
  switch (type) {
    case "currency":
      return (
        <TitleShortener
          title={formatCurrencyValue(value, currency, 2, "standard")}
          overflowTitle={formatCurrencyValue(value, currency, 2, "compact")}
        />
      );
    case "number":
      return (
        <TitleShortener
          title={formatValue(value, decimalsLength, "decimal", "standard")}
          overflowTitle={formatValue(
            value,
            decimalsLength,
            "decimal",
            "compact",
          )}
        />
      );
    case "percentage":
      return (
        <TitleShortener
          title={formatValue(
            value / 100,
            decimalsLength,
            "percent",
            "standard",
          )}
          overflowTitle={formatValue(
            value / 100,
            decimalsLength,
            "percent",
            "compact",
          )}
        />
      );
    default:
      return <div></div>;
  }
};

export const removeCurrencySymbolFromMonetaryValue = (value: string): string =>
  value.replace(/[^0-9.-]+/g, "");

/* eslint-disable @typescript-eslint/no-var-requires */
import headlessUi from "@headlessui/tailwindcss";
import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";
import plugin from "tailwindcss/plugin";

import { colors } from "./colors";
export default {
  important: true,
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "node_modules/@aleph/supergrid/**/*.js",
  ],
  theme: {
    colors,
    fontSize: {
      8: ["0.5rem", "1.125rem"],
      10: ["0.625rem", "0.84375rem"],
      11: ["0.6875rem", "0.875rem"],
      12: ["0.75rem", "1.125rem"],
      13: ["0.8125rem", "1.125rem"],
      14: ["0.875rem", "1.125rem"],
      15: ["0.9375rem", "1.125rem"],
      16: ["1rem", "1.375rem"],
      18: ["1.125rem", "1.375rem"],
      20: ["1.25rem", "1.75rem"],
      22: ["1.375rem", "1.875rem"],
      24: ["1.5rem", "1.8rem"],
      26: ["1.625rem", "1.95rem"],
      28: ["1.75rem", "2.125rem"],
      30: ["1.875rem", "2.5625rem"],
      32: ["2rem", "2.4rem"],
      34: ["2.125rem", "2.90625rem"],
      40: ["2.5rem", "3.375rem"],
      42: ["2.625rem", "2.625rem"],
    },
    extend: {
      boxShadow: {
        modal: "4px 8px 30px 0px rgba(12, 12, 12, 0.05)",
        container: "0 4px 20px rgba(0,0,0,0.2)",
        card: "0px 1px 4px rgba(172, 172, 172, 0.3), 0px 0px 2px rgba(152, 152, 152, 0.25)",
        box: "0px 0px 8px 0px rgba(0, 0, 0, 0.05)",
        "box-darker": "0px 2px 6px 0px rgba(0, 0, 0, 0.10)",
        arrow:
          "0px 1px 4px rgba(172, 172, 172, 0.3), 0px 0px 2px rgba(152, 152, 152, 0.25)",
        google:
          "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)",
        googleHover: "0px -1px 4px rgba(66, 133, 244, 0.7)",
        option:
          "0px 2px 4px -2px rgba(24,39,75,0.12), 0px 4px 4px -2px rgba(24,39,75,0.08)",
        tableBorder: "0px 0px 0px 1px rgba(207, 212, 232, 1)",
        tableBorderNoTop:
          "0px 1px 0px 0px rgba(207, 212, 232, 1), 1px 0px 0px 0px rgba(207, 212, 232, 1), -1px 0px 0px 0px rgba(207, 212, 232, 1)",
        tableOptions: "-2px 1px 10px -4px rgba(17, 34, 77, 0.20)",
      },
      dropShadow: {
        "table-1": "0px, 1px rgba(172, 172, 172, 0.3)",
        "table-2": "0px 0px 0px 1px rgba(152, 152, 152, 0.25)",
        popup: "0px 2px 6px rgba(0, 0, 0, 0.10)",
        card: [
          "0px 1px 4px rgba(172, 172, 172, 0.3)",
          "0px 0px 2px rgba(152, 152, 152, 0.25)",
        ],
      },
      borderRadius: {
        "1px": "1px",
        "5px": "5px",
        "4px": "4px",
        "10px": "10px",
        "20px": "20px",
        "40px": "40px",
      },
      screens: {
        ...defaultTheme.screens,
        tablet: "768px",
        "desktop-s": "1024px",
        "desktop-sm": "1152px",
        "desktop-m": "1280px",
        "desktop-l": "1440px",
        "desktop-xl": "1680px",
        530: "530px",
        1400: "1520px",
        "max-h-910": { raw: "(max-height: 910px)" },
      },
      width: {
        5.5: "22px",
        18: "4.5rem",
        100: "25rem",
      },
      height: {
        5.5: "22px",
      },
      spacing: {
        18: "4.5rem",
      },
      backgroundImage: {
        "google-icon": "url('assets/google.svg')",
        blobs1: `url('${import.meta.env.BASE_URL || "/app"}/icons/blobs/blobs1.svg')`,
        blobs2: `url('${import.meta.env.BASE_URL || "/app"}/icons/blobs/blobs2.svg')`,
        blobs3: `url('${import.meta.env.BASE_URL || "/app"}/icons/blobs/blobs3.svg')`,
        blobs4: `url('${import.meta.env.BASE_URL || "/app"}/icons/blobs/blobs4.svg')`,
      },
      lineHeight: {
        unset: "unset",
      },
      gridTemplateColumns: {
        "5-2x20-2x15-2x10": "1fr 4fr 4fr 3fr 3fr 2fr 2fr",
        "5-2x20-3x15-10": "1fr 4fr 4fr 3fr 3fr 3fr 2fr",
        "auto-345": "1fr 345px",
        "flat-5-narrow-1": "0.75fr 1fr 1fr 1fr 1fr",
        15: "repeat(15, minmax(0, 1fr))",
        20: "repeat(20, minmax(0, 1fr))",
      },
      gridTemplateRows: {
        "flat-1": "repeat(1, 1fr)",
        "flat-2": "repeat(2, 1fr)",
        "flat-3": "repeat(3, 1fr)",
      },
    },
  },
  plugins: [
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          "bg-gradient": (angle) => ({
            "background-image": `linear-gradient(${angle}, var(--tw-gradient-stops))`,
          }),
        },
        {
          values: Object.assign(theme("bgGradientDeg", {}), {
            135: "135deg", // bg-gradient-135
            315: "315deg", // bg-gradient-315
          }),
        },
      );
    }),
    headlessUi({ prefix: "ui" }),
  ],
} satisfies Config;

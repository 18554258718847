import { getElementText } from "common/utils/getElementText";

import { TLabelSize } from "./types";

const getTextSize = (size: TLabelSize) => {
  switch (size) {
    case "small":
      return "text-14 mb-1";
    case "regular":
      return "text-16 mb-2.5";
    case "xregular":
      return "text-20 leading-6 mb-4";
    default:
      return "text-16 mb-2.5";
  }
};

export const FormLabel: React.FC<{
  label: string | JSX.Element;
  size?: TLabelSize;
}> = ({ label, size = "regular" }) =>
  typeof label === "string" ? (
    <div
      data-testid={`form-label-${label}`}
      className={`${getTextSize(size)} font-bold text-blue-800`}
    >
      {label}
    </div>
  ) : (
    <div
      data-testid={`form-label-${getElementText(label)}`}
      className="flex items-center justify-between"
    >
      {label}
    </div>
  );

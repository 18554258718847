import { Brand } from "common/types";

const BRAND_STORAGE_KEY = "brand";

export const getBrandFromCache = (): Brand | null => {
  const cachedData = localStorage.getItem(BRAND_STORAGE_KEY);
  if (cachedData) {
    try {
      return JSON.parse(cachedData);
    } catch (SyntaxError) {
      return null;
    }
  }
  return null;
};

export const setBrandToCache = (brand: Brand | null) => {
  if (brand) {
    localStorage.setItem(BRAND_STORAGE_KEY, JSON.stringify(brand));
  } else {
    localStorage.removeItem(BRAND_STORAGE_KEY);
  }
};

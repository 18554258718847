import { useEffect } from "react";

import { CallbackDefault } from "common/types";

const handleClickOutside = (
  event: MouseEvent,
  fn: () => void,
  ref: React.MutableRefObject<HTMLElement | null>,
  includedRefs: React.MutableRefObject<HTMLElement | null>[] = [],
) => {
  if (
    ref.current &&
    event.target instanceof Node &&
    !ref.current.contains(event.target) &&
    !includedRefs.some(
      (ref) => ref.current && ref.current.contains(event.target as Node),
    )
  ) {
    fn();
  }
};

export const useHandleClickOutside = (
  ref: React.MutableRefObject<HTMLElement | null>,
  fn: CallbackDefault,
  includedRefs: React.MutableRefObject<HTMLElement | null>[] = [],
) => {
  useEffect(() => {
    const callback = (e: MouseEvent) =>
      handleClickOutside(e, fn, ref, includedRefs);

    document.addEventListener("mousedown", callback);
    return () => {
      document.removeEventListener("mousedown", callback);
    };
  }, [ref, fn]);
};

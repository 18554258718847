/* eslint-disable @typescript-eslint/no-namespace */
import { AxiosResponse } from "axios";
import { EmptyObject } from "react-hook-form";
import { UseQueryOptions } from "react-query";

import { countries, metrics, timezones } from "common/constants";
import Currencies from "common/utils/currencies.json";
import { Channel } from "modules/Reporting/types";

import { Scope } from "./components/RestrictedDisplay";

export type Currency = keyof typeof Currencies;
export type ExchangeRates = Record<Currency, number>;
export type AdvertiserStatus =
  | "Disabled"
  | "Approved"
  | "Pending"
  | "To Create";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Advertiser = {
  id: string;
  name: string;
  channels: Channel[];
  countries: SupportedCountry[];
  currencies: Currency[];
  country_with_currency: { country: SupportedCountry; currency: Currency }[];
  sp_country_with_currency: { country: SupportedCountry; currency: Currency }[];
  primary_currency: Currency;
  creatives_languages: Partial<Record<Language, Country[]>>;
  exchange_rates: ExchangeRates;
  status: AdvertiserStatus;
  agency: Agency;
};

export type Agency = { id: string; name: string };
export const UNASSIGNED_AGENCY = { id: "", name: "Unassigned" };

export type Brand = {
  id: string;
  domain: string;
  brand_slug: string;
  application_name: string;
  application_alt_name: string;
  company_name: string;
  company_legal_name: string;
  company_address: string;
  active: boolean;
  logo_file_url: string;
  email_logo_file_url: string;
  favicon_file_url: string;
  login_logo_file_url: string;
  terms_of_service_file_url: string;
  support_email: string;
};

export type UserApi = {
  id: string;
  email: string; // Email is unique
  name?: string;
  surname?: string;
  advertiser_accounts: Advertiser[];
  permissions: Scope[];
  role?: { id: string | null; name: UserRole };
  user_type: UserType;
};
export type UserFilter =
  | {
      id: string;
      name: string;
    }
  | Record<string, never>; // empty object is returned for system actor - actor from outside the vow platform

export type User = UserApi & {
  agencies: { agency: Agency; advertiser_accounts: Advertiser[] }[];
};

export const userRoleAdvertiserList = [
  "Read Only",
  "Report Only",
  "Client Regular",
  "Client Manager",
] as const;
export type UserRoleAdvertiser = (typeof userRoleAdvertiserList)[number];

export const userRoleAgencyList = [
  "Agency Junior",
  "Agency Regular",
  "Agency Manager",
  "Finance Manager",
] as const;
export type UserRoleAgency = (typeof userRoleAgencyList)[number];

export const userRoleList = [
  ...userRoleAdvertiserList,
  ...userRoleAgencyList,
  "Staff",
  "Technical",
] as const;
export type UserRole = (typeof userRoleList)[number] | "ADMINISTRATOR";

export const userTypeList = ["agency", "advertiser"] as const;
export type UserType = (typeof userTypeList)[number] | "staff";

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

// Element -> HTMLElement | SVGElement | … -> HTMLDivElement | SVGSVGElement | …
export type EventDefault<T = Event> = (event: T) => void;
export type OnClickDefault<T = Element> = EventDefault<React.MouseEvent<T>>;
export type OnChangeDefault<T = Element> = EventDefault<React.ChangeEvent<T>>;
export type OnSelectDefault<T = Element> = EventDefault<React.ChangeEvent<T>>;
export type OnBlurDefault<T = Element> = EventDefault<React.FocusEvent<T>>;
export type OnFocusDefault<T = Element> = EventDefault<React.FocusEvent<T>>;
export type OnKeyboardEventDefault<T = Element> = EventDefault<
  React.KeyboardEvent<T>
>;

export type CallbackDefault = () => void;
export type PromiseCallbackDefault = () => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CallbackSomeData<T = any> = (data?: T) => void;

export type AnyObject = Record<string, any>;

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type ValueOf<T> = T[keyof T];

export type PartialNull<T> = { [P in keyof T]: T[P] | null };

export type Stringify<T> = { [P in keyof T]: string };

export type KeysOfValue<T, TCondition> = {
  [K in keyof T]: T[K] extends TCondition ? K : never;
}[keyof T];

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type NonNullableValues<T, K extends keyof T> = T & {
  [P in K]: NonNullable<T[P]>;
};

export type UseQueryOptionsResponse<T> = UseQueryOptions<AxiosResponse<T>>;

export type Pagination<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type DateRange = {
  lower: string;
  upper: string;
  bounds?: string;
  timezone?: string;
};

export enum AppDisplayType {
  Standard = "standard",
  Advanced = "advanced",
}

export const questionAnswers = ["yes", "no"] as const;
export type QuestionAnswer = (typeof questionAnswers)[number];
export type PhoneNumber = {
  id: string;
  dial: string;
  countryName: string;
  countryCode: Country;
};
export type PhoneNumberForm = {
  number: string;
  data: PhoneNumber;
};

/**
 * Countries and Regions
 */

export const northAmericaCountriesList = ["US", "MX", "CA"] as const;
export type NorthAmericaCountry = (typeof northAmericaCountriesList)[number];

export const southAmericaCountriesList = ["BR"] as const;
export type SouthAmericaCountry = (typeof southAmericaCountriesList)[number];

export const middleEastCountriesList = ["AE", "SA"] as const;
export type MiddleEastCountry = (typeof middleEastCountriesList)[number];

export const europeCountriesList = [
  "GB",
  "FR",
  "DE",
  "ES",
  "IT",
  "SE",
  "TR",
  "NL",
] as const;
export type EuropeCountry = (typeof europeCountriesList)[number];

export const apacCountriesList = ["AU", "IN", "SG", "JP"] as const;
export type ApacCountry = (typeof apacCountriesList)[number];

export const supportedCountries = [
  ...northAmericaCountriesList,
  ...southAmericaCountriesList,
  ...middleEastCountriesList,
  ...europeCountriesList,
  ...apacCountriesList,
] as const;

export type SupportedCountry = (typeof supportedCountries)[number];
export const isSupportedCountry = (
  country: string,
): country is SupportedCountry =>
  (supportedCountries as readonly string[]).includes(country);

export type Country = (typeof countries)[number];
export const isCountry = (country: string): country is Country =>
  (countries as readonly string[]).includes(country);

export const regionsList = [
  "north_america",
  "south_america",
  "middle_east",
  "europe",
  "apac",
] as const;
export type Region = (typeof regionsList)[number];
export enum AmzRegion {
  NA = "NA",
  EU = "EU",
  FE = "FE",
}

export const countriesMap: Record<Region, Readonly<SupportedCountry[]>> = {
  north_america: northAmericaCountriesList,
  south_america: southAmericaCountriesList,
  middle_east: middleEastCountriesList,
  europe: europeCountriesList,
  apac: apacCountriesList,
};
export const countryToAmzRegionMap: Record<SupportedCountry, AmzRegion> = {
  US: AmzRegion.NA,
  MX: AmzRegion.NA,
  CA: AmzRegion.NA,
  BR: AmzRegion.NA,
  AE: AmzRegion.EU,
  SA: AmzRegion.EU,
  GB: AmzRegion.EU,
  FR: AmzRegion.EU,
  DE: AmzRegion.EU,
  ES: AmzRegion.EU,
  IT: AmzRegion.EU,
  SE: AmzRegion.EU,
  TR: AmzRegion.EU,
  NL: AmzRegion.EU,
  IN: AmzRegion.EU,
  AU: AmzRegion.FE,
  SG: AmzRegion.FE,
  JP: AmzRegion.FE,
};

// boolean because react-use-form sets false value for unchecked checkboxes
export type CountriesForm = Record<
  SupportedCountry,
  SupportedCountry | boolean
>;

export const languages = [
  "English",
  "Dutch",
  "French",
  "German",
  "Czech",
  "Polish",
  "Turkish",
  "Italian",
  "Spanish",
  "Portuguese",
  "Arabic",
  "Japanese",
  "Chinese",
  "Hindi",
  "Tamil",
  "Telugu",
  "Kannada",
  "Marathi",
  "Swedish",
  "Norwegian",
  "Finnish",
  "Danish",
] as const;
export type Language = (typeof languages)[number];

export namespace Size {
  export type XXL = "XXL";
  export type XL = "XL";
  export type L = "L";
  export type M = "M";
  export type S = "S";
  export type XS = "XS";
  export type XXS = "XXS";
}

export namespace Theme {
  export type Light = "light";
  export type Dark = "dark";
  export type Custom = "custom";
}

/**
 * End of Countries and Regions
 */

/**
 * Features
 *
 * WARNING: Please ensure that flags are synced with the db by updating coresponding data in the
 * following location: backend/vowmade/management/commands/update_feature_flags_and_switches.py
 */

export type FeatureName =
  | TestFlag
  | "strategy.history"
  | "strategy.history_performance_chart"
  | "strategy.strategy_campaign_performance_chart"
  | "tablesOptimisation.caching"
  | "reports.multiple_channels"
  | "reports.inventory"
  | "contextual_targeting"
  | "recs_on_strategy_wizard"
  | "enable_credit_management"
  | "amazon_ad_tag"
  | "supergrid"
  | "multi_flight_dates_planner"
  | "overlapping_audiences"
  | "product_audiences"
  | "edit_product_audiences"
  | "auto_rec_strategy_targeting"
  | "metric_display_settings"
  | "genus_ai_creation"
  | "deals";

// flag used only for out unit test purpouses, don't sync it
type TestFlag = "test.flag";
type FeatureData = {
  is_active: boolean;
  last_modified: string;
};

export type FeatureStatus = Record<FeatureName, FeatureData> | EmptyObject;

export type SwitchName =
  | "enable_audit_trail_logging"
  | "enable_fixing_images"
  | "enable_campaign_activation"
  | "enable_syncing_with_amazon";

export type Switch = Record<SwitchName, FeatureData> | EmptyObject;

export type FeatureResponse = {
  flags: FeatureStatus;
  switches: Switch;
};

/**
 * End of Features
 */

export const ErrorsModal = {
  500: "500",
  404: "404",
  429: "429",
  unexpectedError: "unexpectedError",
} as const;
export type ErrorsModalType = (typeof ErrorsModal)[keyof typeof ErrorsModal];

export type Timezone = (typeof timezones)[number];

export type Subheader = {
  id: string;
  isSelected: boolean;
  isVisible: boolean;
  header: string;
  searchValue: string;
  label?: string | JSX.Element;
};

/**
 * Date picker
 */

export type CalendarParams = {
  lower?: Date;
  upper?: Date;
  selector?: Period;
};

export type FilterCalendarParamsForm = {
  lower: string;
  upper: string;
  selector?: Period;
};

export type CalendarParamsForm = Partial<FilterCalendarParamsForm>;

export const periods = [
  "last7d",
  "mtd",
  "lastWeek",
  "lastMonth",
  "lastQuarter",
  "lifetime",
] as const;

export type Period = (typeof periods)[number];

/**
 * End of Date Picker
 */

/**
 * Metrics
 */
export type Metric = (typeof metrics)[number];
export type Metrics = {
  fdr?: string | null;
  fdi?: string | null;
  dcpm?: string | null;
  vcpm?: string | null;
  clicks?: string | null;
  ctr?: string | null;
  impressions?: number | null;
  conversions?: number | null;
  sales?: string | null;
  roas?: string | null;
  acos?: string | null;
  pacing?: string | null;
  spend?: string | null;
  cpa?: string | null;
  cvr?: string | null;
  vr?: string | null;
  vi?: string | null;
  vcr?: string | null;
  cpvc?: string | null;
  click_throughs?: number | null;
  off_amazon_cpa?: string | null;
  off_amazon_cvr?: string | null;
  total_cost?: string | null;
  viewable_impressions?: number | null;
  ecpc?: string | null;
  ecpm?: string | null;
  total_sales?: string | null;
  spent_amount?: string | null;
  pacing_ratio?: string | null;
  t_roas?: string | null;
  c_roas?: string | null;
  cpsu?: string | null;
  cpfao?: string | null;
  dpvr?: string | null;
  cpdpv?: string | null;
  purchases?: number | null;
};

/**
 * End of Metrics
 */

import {
  ADMIN_ADVERTISER_INDEX,
  ADMIN_USER_INDEX,
  BASENAME,
} from "App/components/AppRoutes/routes";
import { RestrictedDisplay } from "common/components/RestrictedDisplay";
import { useIsPageActive } from "common/hooks/useIsPageActive";
import { useFormatMessage } from "common/internationalization";

import { NavigationItem } from "./components";

export const AdministrationNavItems = () => {
  const formatMessage = useFormatMessage();
  const isActive = useIsPageActive();

  return (
    <>
      <RestrictedDisplay scopes={["can_view_advertisers"]}>
        <NavigationItem
          name={formatMessage("pageTitle.advertisers")}
          isActive={isActive(ADMIN_ADVERTISER_INDEX)}
          iconSrc={`${BASENAME}/icons/navigation/advertisers.svg`}
          iconActiveSrc={`${BASENAME}/icons/navigation/advertisersSelected.svg`}
          iconAlt="advertisers"
          basicUrl={ADMIN_ADVERTISER_INDEX}
          theme="violet"
        />
      </RestrictedDisplay>
      <NavigationItem
        name={formatMessage("pageTitle.users")}
        isActive={isActive(ADMIN_USER_INDEX)}
        iconSrc={`${BASENAME}/icons/navigation/targeting.svg`}
        iconActiveSrc={`${BASENAME}/icons/navigation/targetingSelected.svg`}
        iconAlt="users"
        basicUrl={ADMIN_USER_INDEX}
        theme="violet"
      />
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash-es";

import { predefinedSelectors } from "common/components/DatePicker/consts";
import { TDropdownOption } from "common/components/form/Dropdown/types";
import { TableId } from "common/components/tables/components/Filter/types";
import { emptyBrand } from "common/constants";
import {
  AppDisplayType,
  Brand,
  CalendarParams,
  FeatureResponse,
  Period,
} from "common/types";
import { setBrandToCache } from "common/utils/brand";
import { AudienceCategory } from "modules/Audiences/types";
import { getChangesFormDefaultValues } from "modules/Strategies/components/StrategiesDisplaySettings/components/MetricDisplaySettingsModal/components/utils";

import {
  fetchAudienceCategories,
  fetchBrand,
  fetchFeatureStatus,
  fetchMetricPreferences,
  fetchProductCategories,
} from "./thunkActions";
import { AppState, TableData } from "./types";
import { retrieveGlobalDateRange } from "./utils/dateRange";
import { mapDataObjectToCategories } from "./utils/productCategories";
import { setAppSettingsToCache } from "./utils/settings";

const initialState: AppState = {
  productCategories: [],
  audienceCategories: [],
  metricPreferences: getChangesFormDefaultValues([]),
  brand: emptyBrand,
  featureStatus: undefined,
  switches: undefined,
  globalDateRange: retrieveGlobalDateRange(),
  globalDateRangeSettings: {
    minDate: undefined,
    maxDate: undefined,
    disabledDayInfo: undefined,
    disabled: false,
    hidden: false,
  },
  isAdvertiserDropdownDisabled: false,
  settings: {
    appDisplayType: AppDisplayType.Standard,
  },
  tablesData: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProductCategories(state, action: PayloadAction<TDropdownOption[]>) {
      state.productCategories = action.payload;
    },
    setAudienceCategories(state, action: PayloadAction<AudienceCategory[]>) {
      state.audienceCategories = action.payload;
    },
    setBrand(state, action: PayloadAction<Brand>) {
      state.brand = action.payload;
      setBrandToCache(action.payload);
    },
    setGlobalDateRange(state, action: PayloadAction<CalendarParams>) {
      let globalDateRange = action.payload;
      if (
        action.payload.selector &&
        !action.payload.lower &&
        !action.payload.upper &&
        predefinedSelectors.some(
          (selector) => selector.id === action.payload.selector,
        )
      ) {
        const selector = predefinedSelectors.find(
          (selector) => selector.id === action.payload.selector,
        )!;
        globalDateRange = {
          ...action.payload,
          lower: selector.range().lower,
          upper: selector.range().upper,
        };
      }
      state.globalDateRange = globalDateRange;
      localStorage.setItem("globalDateRange", JSON.stringify(globalDateRange));
    },
    setGlobalDateRangeSettings(
      state,
      action: PayloadAction<{
        minDate?: Date;
        maxDate?: Date;
        disabledDayInfo?: string;
        disabled?: boolean;
        disabledSelectors?: Period[];
        hidden?: boolean;
      }>,
    ) {
      state.globalDateRangeSettings = {
        ...cloneDeep(state.globalDateRangeSettings),
        ...action.payload,
      };
    },
    setIsAdvertiserDropdownDisabled(state, action: PayloadAction<boolean>) {
      state.isAdvertiserDropdownDisabled = action.payload;
    },
    setAppSettings(state, action: PayloadAction<AppState["settings"]>) {
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
      setAppSettingsToCache(state.settings);
    },
    setTableData(
      state,
      action: PayloadAction<{
        id: TableId;
        value: TableData;
      }>,
    ) {
      state.tablesData[action.payload.id] = {
        ...state.tablesData[action.payload.id],
        ...action.payload.value,
      };
    },
    clearTableData(state, action: PayloadAction<TableId[]>) {
      action.payload.forEach((id) => {
        delete state.tablesData[id];
      });
    },
    clearAllTablesData(state) {
      state.tablesData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureStatus.fulfilled, (state, action) => {
      state.featureStatus = (action.payload as FeatureResponse).flags;
      state.switches = (action.payload as FeatureResponse).switches;
    });
    builder.addCase(fetchProductCategories.fulfilled, (state, action) => {
      state.productCategories = mapDataObjectToCategories(action.payload || []);
    });
    builder.addCase(fetchAudienceCategories.fulfilled, (state, action) => {
      state.audienceCategories = action.payload || [];
    });
    builder.addCase(fetchBrand.fulfilled, (state, action) => {
      const brand = action.payload;
      state.brand = brand;
      if (brand) setBrandToCache(brand);
    });
    builder.addCase(fetchMetricPreferences.fulfilled, (state, action) => {
      state.metricPreferences = getChangesFormDefaultValues(
        action.payload || [],
      );
    });
  },
});

export const {
  setProductCategories,
  setGlobalDateRange,
  setGlobalDateRangeSettings,
  setIsAdvertiserDropdownDisabled,
  setAppSettings,
  setTableData,
  clearTableData,
  clearAllTablesData,
} = appSlice.actions;
export default appSlice.reducer;

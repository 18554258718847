import { Options } from "@popperjs/core/lib/modifiers/offset";
import { forwardRef, PropsWithChildren } from "react";
import { PopperChildrenProps } from "react-popper";

import { Tooltip } from "common/components/Tooltip";

import { GenericPopover } from "../components";
import { PopoverRef } from "../types";
import { LabelPopoverRef, RefProps } from "./components";

export type Props = PropsWithChildren<
  RefProps & {
    referenceStyling?: string;
    additionalPopoverStyling?: string;
    placement?: PopperChildrenProps["placement"];
    offset?: Options["offset"];
    disabled?: boolean;
    disabledInfoMessage?: string;
    onChange?: (value: boolean | undefined) => void;
  }
>;

export const LabelPopover = forwardRef<PopoverRef, Props>(
  (
    {
      disabled: popoverDisabled = false,
      children,
      testId,
      referenceStyling,
      additionalPopoverStyling,
      placement = "bottom-start",
      offset,
      disabledInfoMessage,
      onChange,
      ...refProps
    },
    ref,
  ) => (
    <GenericPopover
      placement={placement}
      offset={offset}
      testId={testId}
      innerRef={ref}
      referenceStyling={referenceStyling}
      disabled={popoverDisabled}
      onChange={onChange}
      popover={
        <div
          className={`overflow-hidden bg-white border-[0.5px] border-white-600 shadow-card rounded-md ${additionalPopoverStyling}`}
        >
          {children}
        </div>
      }
      reference={
        <Tooltip
          testId={`label-popover-${testId}`}
          content={<div className="w-[200px]">{disabledInfoMessage}</div>}
          disabled={!popoverDisabled}
          classNames={{
            wrapper: "w-full",
          }}
          trigger={
            <LabelPopoverRef
              {...refProps}
              testId={testId}
              disabled={popoverDisabled}
            />
          }
        />
      }
    />
  ),
);

import * as routes from "../../App/components/AppRoutes/routes";
import { useUserPermissions } from "./useUserPermissions";

/**
 * Return the default route for the user based on their permissions
 */
export const useDefaultRoute = (): string => {
  const reportingPermission = useUserPermissions(["can_view_reports"]);
  const strategiesPermission = useUserPermissions(["can_view_strategies"]);
  const adminPermission = useUserPermissions(["can_view_admin"]);

  if (strategiesPermission) return routes.DASHBOARD_ROOT;
  if (reportingPermission) return routes.REPORTING_ROOT;
  if (adminPermission) return routes.ADMIN_ROOT;
  return routes.ERR_ACCESS_DENIED;
};

import { createSelector } from "@reduxjs/toolkit";

import { sortByMessage } from "common/internationalization";
import { Advertiser, User } from "common/types";
import { parseDateRange, parseDateRangeIntoForm } from "common/utils/dateRange";
import { Metrics } from "modules/Strategies/components/StrategiesDisplaySettings/components/MetricDisplaySettingsModal/components/types";
import { ProductLocation } from "modules/Strategies/types";

import { UserPreferences } from "../api/models/UserPreferences";
import { RootState } from "./store";

export const getProductCategories = (state: RootState) =>
  state.app.productCategories;
export const getAudienceCategories = (state: RootState) =>
  state.app.audienceCategories;

export const getUser = <T = User>(state: RootState) => state.user.user as T;
export const getAdvertiser = <T = Advertiser>(state: RootState) =>
  state.advertiser.advertiser as T;
export const getFeatureStatus = (state: RootState) => state.app.featureStatus;
export const getSwitches = (state: RootState) => state.app.switches;
export const getGlobalDateRange = (state: RootState) =>
  state.app.globalDateRange;
export const getParsedGlobalDateRangeIntoForm = createSelector(
  [getGlobalDateRange],
  (globalDateRange) => parseDateRangeIntoForm(globalDateRange),
);
export const getGlobalDateRangeRequest = (state: RootState) =>
  parseDateRange(state.app.globalDateRange);
export const getGlobalDateRangeSettings = (state: RootState) =>
  state.app.globalDateRangeSettings;
export const getIsAdvertiserDropdownDisabled = (state: RootState) =>
  state.app.isAdvertiserDropdownDisabled;
export const getAppSettings = (state: RootState) => state.app.settings;
export const getTablesData = (state: RootState) => state.app.tablesData;
export const getBrand = (state: RootState) => state.app.brand;

export const getMetricPreferences = (state: RootState) =>
  state.app.metricPreferences;

export const getMetricPreferencesByGoal =
  (
    goal: UserPreferences.goal | undefined,
    type: "table" | "graph" | "pod",
    productLocation: ProductLocation | null = null,
    returnDefault: Metrics[] = [],
  ) =>
  (state: RootState) => {
    const preferences = state.app.metricPreferences.find(
      (preferences) => preferences.goal === goal,
    );
    let metrics = returnDefault;
    switch (type) {
      case "table":
        if (preferences?.table_metrics) {
          metrics = preferences?.table_metrics as Metrics[];
        }
        break;
      case "graph":
        if (preferences?.graph_metrics) {
          metrics = preferences?.graph_metrics as Metrics[];
        }
        break;
      case "pod":
        if (preferences?.pod_metrics) {
          metrics = preferences?.pod_metrics as Metrics[];
        }
        break;
      default:
        break;
    }

    if (productLocation) {
      // Skip off amazon metrics
      if (productLocation === "SOLD_ON_AMAZON") {
        metrics = metrics.filter((metric) => !metric.startsWith("off_amazon"));
      }
    }

    return metrics.slice().sort(sortByMessage("metrics"));
  };

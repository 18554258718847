import { ButtonProps, Button } from "common/components/buttons/Button";

import { useCarouselContext } from "../context";

export const CarouselButtonNext: React.FC<
  React.PropsWithChildren<Partial<ButtonProps>>
> = ({ children, ...props }) => {
  const { items, setSelectedIndex } = useCarouselContext();

  const handleNext = () => {
    setSelectedIndex((prev) => (prev + 1) % items.length);
  };

  return (
    <Button
      dataTestId="next-carousel"
      styling=""
      onClick={handleNext}
      {...props}
    >
      {children}
    </Button>
  );
};

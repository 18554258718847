import { get, post, patch } from "common/api";
import { UserPreferences } from "modules/api/models/UserPreferences";

export const getReportingUserPreferenceList = () =>
  get<UserPreferences[]>("/reports/user-preferences/");

export const createReportingUserPreference = (payload: UserPreferences) =>
  post<UserPreferences>("/reports/user-preferences/", payload);

export const editReportingUserPreference = (params: {
  id: string;
  payload: UserPreferences;
}) =>
  patch<UserPreferences>(
    `/reports/user-preferences/${params.id}/`,
    params.payload,
  );

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { ReactPortal } from "common/components/ReactPortal";
import { ErrorsModal, ErrorsModalType } from "common/types";
import { eventBus } from "common/utils/eventBus";

import { Error500, UnexpectedError } from "./components";
import { Error429 } from "./components/Error429";

export const ErrorModal: React.FC = () => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<ErrorsModalType>("500");

  useEffect(() => {
    const eventHandler = (e: {
      detail: {
        error: ErrorsModalType;
      };
    }) => {
      setError(e.detail.error);
      setIsOpen(true);
    };

    eventBus.on("VOWMade:openErrorModal", eventHandler);

    return () => {
      eventBus.remove("VOWMade:openErrorModal", eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactPortal wrapperId="error-modal">
      <CSSTransition
        in={isOpen}
        timeout={{ enter: 0, exit: 200 }}
        classNames="modal"
        nodeRef={nodeRef}
      >
        <div
          data-testid="modal-backdrop"
          className={classNames(
            "bg-gray-900-t-50 absolute top-0 left-0 w-screen h-screen flex items-center justify-center z-[3000] p-10 overflow-hidden",
            { hidden: !isOpen },
          )}
        >
          <div ref={nodeRef} className="modal">
            {error === ErrorsModal[500] && (
              <Error500 modal onClose={() => setIsOpen(false)} />
            )}
            {error === ErrorsModal[429] && (
              <Error429 modal onClose={() => setIsOpen(false)} />
            )}
            {error === ErrorsModal.unexpectedError && (
              <UnexpectedError modal onClose={() => setIsOpen(false)} />
            )}
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

import { Transition } from "@headlessui/react";
import classNames from "classnames";

type Props = React.PropsWithChildren & {
  isOpen: boolean;
  duration?: number;
  omitScaling?: boolean;
  overflowVisible?: boolean;
  infiniteHeight?: boolean;
};

export const Expandable: React.FC<Props> = ({
  children,
  isOpen,
  duration = 500,
  omitScaling = false,
  overflowVisible = false,
  infiniteHeight = false,
}) => (
  <Transition
    show={isOpen}
    data-testid="csv-asins-chips"
    className={classNames(`transition-all duration-${duration}`, {
      "overflow-hidden": !overflowVisible,
    })}
    enterFrom={classNames("transform opacity-0 max-h-0", {
      "scale-95": !omitScaling,
    })}
    enterTo={classNames("transform scale-100 opacity-100", {
      "max-h-[1000px]": !infiniteHeight,
    })}
    leaveFrom={classNames("transform scale-100 opacity-100", {
      "max-h-[1000px]": !infiniteHeight,
    })}
    leaveTo={classNames("transform opacity-0 max-h-0", {
      "scale-95": !omitScaling,
    })}
  >
    {children}
  </Transition>
);

import { createAsyncThunk } from "@reduxjs/toolkit";

import { notifyOnClientErrorModal } from "common/utils/errors";
import { getAudienceCategories } from "modules/api/requests/audiences";
import { getFeature } from "modules/api/requests/features";
import { getBrand } from "modules/api/requests/general";
import { getReportingUserPreferenceList } from "modules/api/requests/reporting/reportingUserPreferences";
import { getProductCategories } from "modules/api/requests/strategies/productCategories";

export const fetchProductCategories = createAsyncThunk(
  "app/fetchProductCategories",
  async () => {
    try {
      const response = await getProductCategories();
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      notifyOnClientErrorModal(error);
    }
  },
);

export const fetchFeatureStatus = createAsyncThunk(
  "user/fetchFeatureStatus",
  async () => {
    try {
      const response = await getFeature();
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      notifyOnClientErrorModal(error);
      return {};
    }
  },
);

export const fetchAudienceCategories = createAsyncThunk(
  "app/fetchAudienceCategories",
  async () => {
    try {
      const response = await getAudienceCategories();
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      notifyOnClientErrorModal(error);
    }
  },
);

export const fetchBrand = createAsyncThunk("app/fetchBrand", async () => {
  try {
    const response = await getBrand();
    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    notifyOnClientErrorModal(error);
  }
});

export const fetchMetricPreferences = createAsyncThunk(
  "app/fetchMetricPreferences",
  async () => {
    try {
      const response = await getReportingUserPreferenceList();
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      notifyOnClientErrorModal(error);
    }
  },
);

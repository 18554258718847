import { useQueryClient } from "react-query";

import { useCarouselContext } from "common/components/Carousel/context";
import { useFormatMessage } from "common/internationalization";
import { SetState } from "common/types";
import { eventBus } from "common/utils/eventBus";
import { queriesNames } from "modules/api/consts";
import { useDiscardMessageMutation } from "modules/api/mutations/globalMessages";

export const useDiscardMessage = (setIsReadMoreModal: SetState<boolean>) => {
  const queryClient = useQueryClient();

  const formatMessage = useFormatMessage();
  const { items } = useCarouselContext();
  const discardMutation = useDiscardMessageMutation();

  return (msgId: string) => {
    discardMutation.mutate(msgId, {
      onSuccess: () => {
        if (items.length === 1) {
          setIsReadMoreModal(false);
        }
        queryClient.refetchQueries({
          queryKey: queriesNames["global-messages"],
        });
      },
      onError: () => {
        eventBus.openSnackbar(
          formatMessage("common.error.api.unexpectedErrorOccured"),
          "error",
        );
      },
    });
  };
};

import React from "react";

import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";

import { IconWithLabel } from "../IconWithLabel";
import { BreadcrumbItem } from "./types";

export const Breadcrumb: React.FC<{
  breadList: BreadcrumbItem[];
  additionalStyles?: string;
}> = ({ breadList, additionalStyles = "text-gray-800" }) => {
  const navigate = useNavigateWithAdvertiser();

  return (
    <div className={`text-16 font-bold flex ${additionalStyles}`}>
      {breadList.map((el) => (
        <button
          key={el.url}
          onClick={() => navigate(el.url)}
          data-testid={`${el.label}-breadcrumb`}
        >
          <IconWithLabel
            label={el.label}
            name="arrowLeft"
            className="inline"
            size="xsmall"
          />
        </button>
      ))}
    </div>
  );
};

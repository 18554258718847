import { createAsyncThunk } from "@reduxjs/toolkit";

import { getInitialAdvertiser } from "common/utils/advertisers";
import { getUser } from "modules/api/requests/general";

import { setAdvertiser } from "../advertiser";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_params, { dispatch }) => {
    const { data: user } = await getUser();
    const advertiser = getInitialAdvertiser(
      user.advertiser_accounts,
      user.email,
    );
    dispatch(
      setAdvertiser({
        advertiser,
        user,
      }),
    );
    return { user };
  },
);

import { FormError } from "../FormError";

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = {
  name: string;
  errors: any;
  helperText?: string;
};

export const FormHelperText: React.FC<Props> = ({
  helperText,
  name,
  errors,
}) =>
  helperText && !errors ? (
    <p className="text-gray-800 text-10 leading-5 min-h-4">{helperText}</p>
  ) : (
    <FormError name={name} errors={errors} />
  );

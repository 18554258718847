import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ApiProvider } from "common/api";
import { ErrorModal } from "common/components/modals/ErrorModal/ErrorModal";
import { Snackbar } from "common/components/Snackbar";
import { MessageProvider } from "common/internationalization";
import { setupStore } from "modules/store";
import { UnexpectedErrorPage, UnexpectedErrorPageRaw } from "pages/Errors";
import { LoadingPage } from "pages/Loading/LoadingPage";

import { AppRoutes } from "./components";
import { BASENAME } from "./components/AppRoutes/routes";
import {
  useCreateBrandFavicon,
  useGoogleAnalyticsLocationTrack,
} from "./hooks";
import {
  getGoogleClientId,
  initializeGoogleAnalytics,
  initializeSentry,
} from "./integrations";

// Enable Google Analytics
initializeGoogleAnalytics();

// Enable Sentry monitoring
initializeSentry();

// Enable Datadog RUM monitoring
// initializeDataDog();  # DM: disabled for now

const App = () => {
  const clientId = getGoogleClientId();
  useGoogleAnalyticsLocationTrack();

  useCreateBrandFavicon();

  return (
    <ApiProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <Suspense fallback={<LoadingPage />}>
          <AppRoutes />
        </Suspense>
        <ReactQueryDevtools />
        <Snackbar />
        <ErrorModal />
      </GoogleOAuthProvider>
    </ApiProvider>
  );
};

const store = setupStore();

export default Sentry.withProfiler(() => (
  <ErrorBoundary fallback={UnexpectedErrorPageRaw}>
    <HelmetProvider>
      <MessageProvider>
        <Provider store={store}>
          <ErrorBoundary fallback={UnexpectedErrorPage}>
            <BrowserRouter basename={BASENAME}>
              <App />
            </BrowserRouter>
          </ErrorBoundary>
        </Provider>
      </MessageProvider>
    </HelmetProvider>
  </ErrorBoundary>
));

import { lazy, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import * as routes from "App/components/AppRoutes/routes";
import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";
import { Advertiser, User } from "common/types";
import {
  getAdvertiser,
  getFeatureStatus,
  getUser,
  refresh,
  useAdvertiserStoreSetup,
  useAppDispatch,
  useAppSelector,
  useUserStoreSetup,
} from "modules/store";
import Error403Page from "pages/Errors/Error403Page";
import Error404Page from "pages/Errors/Error404Page";
import Error503Page from "pages/Errors/Error503Page";
import { LoadingPage } from "pages/Loading/LoadingPage";

import { NavBar } from "./components/NavBar";
import { NavContextProvider } from "./components/NavContextProvider";
import { TopBar } from "./components/TopBar";

const PublicRoutes = lazy(() => import("./components/PublicRoutes"));
const PrivateRoutes = lazy(() => import("./components/PrivateRoutes"));
export const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigateWithAdvertiser();
  const params = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();
  const user = useAppSelector(getUser<User | null>);
  const advertiser = useAppSelector(getAdvertiser<Advertiser | null>);
  const featureStatus = useAppSelector(getFeatureStatus);
  const [error, setError] = useState<number | null>(null);
  const [newVersion, setNewVersion] = useState(false);

  useUserStoreSetup();
  useAdvertiserStoreSetup();

  useEffect(() => {
    refresh({ dispatch, user });
  }, [user?.email]);

  useEffect(() => {
    const navigateAccessDenied = () => setError(403);
    const navigateNotFound = () => setError(404);
    const navigateServiceUnavailable = () => setError(503);
    const handleNewVersion = (event: Event) => {
      event.preventDefault();
      setNewVersion(true);
      navigate(0);
    };

    document.addEventListener("VOW:accessDenied", navigateAccessDenied);
    document.addEventListener("VOW:notFound", navigateNotFound);
    document.addEventListener(
      "VOW:serviceUnavailable",
      navigateServiceUnavailable,
    );
    window.addEventListener("vite:preloadError", handleNewVersion);
    return () => {
      document.removeEventListener("VOW:accessDenied", navigateAccessDenied);
      document.removeEventListener("VOW:notFound", navigateNotFound);
      document.removeEventListener(
        "VOW:accessDenied",
        navigateServiceUnavailable,
      );
      window.removeEventListener("vite:preloadError", handleNewVersion);
    };
  }, []);
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname, location.search]);

  const isAppStateLoading =
    (user &&
      (!advertiser ||
        !featureStatus ||
        !params.get("aid") ||
        !searchParams.get("aid"))) ||
    newVersion;
  if (isAppStateLoading) {
    return <LoadingPage />;
  }

  if (!user || location.pathname.startsWith(routes.TC_ROOT)) {
    return <PublicRoutes />;
  }

  const getRoutes = () => {
    if (error === 403) return <Error403Page />;
    if (error === 404) return <Error404Page />;
    if (error === 503) return <Error503Page />;
    return <PrivateRoutes />;
  };

  return (
    <NavContextProvider>
      <div className="flex relative h-screen w-screen overflow-x-hidden overflow-y-visible">
        <NavBar />
        <div className="relative w-full h-full overflow-hidden flex flex-col">
          <TopBar />
          {getRoutes()}
        </div>
      </div>
    </NavContextProvider>
  );
};

import { Button } from "common/components/buttons/Button";
import { ErrorLayout } from "common/components/layout/pages/ErrorLayout";
import { useBrand } from "common/hooks/useBrand";
import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";
import { useFormatMessage } from "common/internationalization";
import { sendHelpCenterMail } from "common/utils/sendHelpCenterMail";

type Props = {
  modal: boolean;
  onClose?: () => void;
};

export const Error429: React.FC<Props> = ({ modal, onClose }) => {
  const formatMessage = useFormatMessage();
  const navigate = useNavigateWithAdvertiser();
  const brand = useBrand();

  return (
    <ErrorLayout
      title="429"
      subtitle={formatMessage("tooManyRequests")}
      message={formatMessage("tooManyRequests.info")}
      modal={modal}
      onClose={onClose}
      withClose={modal}
    >
      <div className="flex w-full">
        {brand.support_email && (
          <Button
            dataTestId="help-center"
            onClick={() => sendHelpCenterMail(brand.support_email)}
            styling="blue-outline-button"
            additionalClasses="w-full mx-2 capitalize"
          >
            {formatMessage("contactUs")}
          </Button>
        )}
        <Button
          dataTestId="refresh"
          onClick={() => {
            navigate(0);
          }}
          styling="blue-button"
          additionalClasses="w-full mx-2"
        >
          {formatMessage("refresh")}
        </Button>
      </div>
    </ErrorLayout>
  );
};

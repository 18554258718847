import { Icon } from "common/components/Icon";
import { PhoneNumber } from "common/types";

export const PhoneNumberListItem: React.FC<PhoneNumber> = ({
  dial,
  countryCode,
  countryName,
}) => (
  <div className="flex items-center gap-x-2.5 px-4 py-2.5">
    <Icon name={countryCode} />
    <span className="text-gray-800">{dial}</span>
    <span>{countryName}</span>
  </div>
);

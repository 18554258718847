import { useMemo } from "react";

import {
  ADMIN_ADVERTISER_INDEX,
  ADMIN_ROOT,
  ADMIN_USER_INDEX,
  DASHBOARD_INDEX,
  LOGOUT,
  PROFILE_ACCOUNT_INDEX,
  PROFILE_ROOT,
} from "App/components/AppRoutes/routes";
import { Button } from "common/components/buttons/Button";
import { Popover } from "common/components/popovers";
import { useIsPageActive } from "common/hooks/useIsPageActive";
import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";
import { useUserPermissions } from "common/hooks/useUserPermissions";
import { useFormatMessage } from "common/internationalization";
import { capitalize } from "common/utils/capitalize";
import { getUser, useAppSelector } from "modules/store";
import { useIsAdmin } from "modules/store/user/utils";

import { Badge, MenuItem } from "./components";

export const Menu = () => {
  const navigate = useNavigateWithAdvertiser();
  const isAdmin = useIsAdmin();
  const canManageAdmin = useUserPermissions(["can_view_advertisers"]);

  const formatMessage = useFormatMessage();
  const user = useAppSelector(getUser);
  const isPageActive = useIsPageActive();

  const handleLogout = () => {
    navigate(LOGOUT, { replace: true });
  };

  const isOnAdminPanel = useMemo(
    () => isPageActive(ADMIN_ROOT),
    [isPageActive],
  );

  const isOnProfileSection = useMemo(
    () => isPageActive(PROFILE_ROOT),
    [isPageActive],
  );

  return (
    <Popover
      offset={[0, 8]}
      testId="user-badge"
      withTransitionLeave={false}
      reference={<Badge />}
      popover={
        <div className="rounded-[14px] bg-white overflow-hidden shadow-card w-[260px]">
          <div className="bg-white-700 p-5">
            <div className="grid grid-cols-[35px_auto] gap-x-4">
              <Badge />
              <div className="overflow-hidden">
                <p
                  data-testid="user-name"
                  className="text-12 break-all leading-[14px] font-bold text-blue-200 mb-1"
                >
                  {`${user?.name || ""}${user?.name ? " " : ""}${
                    user?.surname || ""
                  }`}
                </p>
                <p className="text-12 leading-[14px] text-gray-400">
                  {capitalize(user?.role?.name || "Read Only", true)}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white py-5 pt-7.5 w-full">
            <div data-testid="admin-menu" className="flex flex-col mb-[50px]">
              {!isOnProfileSection && (
                <MenuItem
                  name="myProfile"
                  iconName="userRectangle"
                  basicUrl={PROFILE_ACCOUNT_INDEX}
                />
              )}
              {isOnProfileSection && (
                <MenuItem
                  name="dashboard"
                  iconName="browsers"
                  basicUrl={DASHBOARD_INDEX}
                />
              )}

              {isAdmin && !isOnAdminPanel && (
                <MenuItem
                  name="agencyAndAdvertisserSettings"
                  iconName="settings"
                  basicUrl={
                    canManageAdmin ? ADMIN_ADVERTISER_INDEX : ADMIN_USER_INDEX
                  }
                />
              )}
              {isOnAdminPanel && (
                <MenuItem
                  name="dashboard"
                  iconName="browsers"
                  basicUrl={DASHBOARD_INDEX}
                />
              )}
            </div>
            <div className="px-5">
              <Button
                styling="blue-button"
                onClick={handleLogout}
                additionalClasses="w-full py-[15px]"
                dataTestId="logout"
              >
                {formatMessage("navbar.menu.logout")}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

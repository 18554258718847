import { useContext } from "react";

import { TGlobalMessage } from "App/components/AppRoutes/components/types";
import { CarouselCounter } from "common/components/Carousel/components";
import { useCarouselContext } from "common/components/Carousel/context";
import { Icon } from "common/components/Icon";
import { ModalContext } from "common/components/modals/context";

type Props = {
  severity: TGlobalMessage["severity"];
};

export const MessageModalHeader: React.FC<Props> = ({ severity }) => {
  const { onClose } = useContext(ModalContext);
  const { items, selectedIndex } = useCarouselContext();
  const selectedItem = items[selectedIndex];

  return (
    <div
      data-testid="detailed-message-header"
      className="flex items-center justify-between"
    >
      <div className="flex items-center gap-x-4 text-blue-900">
        <Icon name={severity} size="large" />
        <h2
          data-testid="detailed-message-title"
          className="font-bold text-24 transition-all"
        >
          {selectedItem.title}
        </h2>
        <CarouselCounter />
      </div>
      <button onClick={onClose} data-testid="close-modal">
        <Icon name="close" alt="close" size="xlarge" />
      </button>
    </div>
  );
};

export type TDropdownOption<T = string> = {
  label: string;
  value: string | number;
  labelEl?: JSX.Element;
  dropdownLabel?: JSX.Element | string;
  selectedInputEl?: JSX.Element; // displayed as placeholder in ReadOnlyDropdownInput.tsx
  children?: TDropdownOption<T>[];
  manuallyCreated?: boolean;
  additionalValue?: T;
};

export type DropdownMode = "default" | "inline";

export type DropdownType =
  | "singleChoice"
  | "multipleChoice"
  | "singleCreatable";

export type DropdownSize =
  | "default"
  | "small"
  | "compact"
  | "compactSmall"
  | "advertiser"
  | "full";

export const isMultipleChoice = (
  option?: TDropdownOption | TDropdownOption[],
): option is TDropdownOption[] =>
  (option as TDropdownOption[])?.length !== undefined;

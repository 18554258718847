export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const urlRegex =
  /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?$/;
export const twoDecimalPlaces = /(^([0-9\b])+([.,]\d{1,2})?$|^$)/;
export const threeDecimalPlaces = /(^([0-9\b])+([.,]\d{1,3})?$|^$)/;
export const integersOnly = /^[0-9\b]+$/;
export const alphanumeric = /^[a-z0-9\b]+$/i;

export const exactLettersNumber = (number: number) => {
  const pattern = `^[A-Za-z]{${number}}$`;
  return new RegExp(pattern, "g");
};
export const exactAlphanumericNumber = (number: number) => {
  const pattern = `^[A-Za-z0-9]{${number}}$`;
  return new RegExp(pattern, "g");
};
export const startsWithLettersNumber = (number: number) => {
  const pattern = `^[A-Za-z]{${number}}.*`;
  return new RegExp(pattern, "g");
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*#?&]{8,}$/;

export const csvRegex = /\s|,|;/g;

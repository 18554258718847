import { TDropdownOption } from "../types";
import { Chip } from "./Chip";

type DropdownChipsGroupedProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  selected: TDropdownOption[];
  options: TDropdownOption[];
  disabled?: boolean;
};

export type DropdownChipsGroupedOptions = Record<
  string,
  DropdownChipsGroupedValue
>;

export type DropdownChipsGroupedValue = {
  count: number;
  option: TDropdownOption;
  isFull: boolean;
  selectedChildren?: TDropdownOption[];
};

export const generateDropdownOptions = (
  selected: TDropdownOption[],
  options: TDropdownOption[],
) => {
  const dropdownOptions: Record<string, DropdownChipsGroupedValue> = {};

  options.forEach((opt: TDropdownOption) => {
    if (opt.children && !(opt.value.toString() in dropdownOptions)) {
      const childrenValues = opt.children.map((o: TDropdownOption) => o.value);
      const selectedChildren = selected.filter((s) =>
        childrenValues.includes(s.value),
      );
      dropdownOptions[opt.value.toString()] = {
        count: selectedChildren.length,
        option: opt,
        isFull: selectedChildren.length === childrenValues.length,
        selectedChildren,
      };
    }
  });
  return dropdownOptions;
};

export const DropdownChipsGrouped: React.FC<DropdownChipsGroupedProps> = ({
  onChange,
  selected,
  options,
  disabled,
}) => {
  const selectedGroupCount: Record<
    string,
    {
      count: number;
      option: TDropdownOption;
      isFull: boolean;
    }
  > = generateDropdownOptions(selected, options);

  const handleDelete = (option: TDropdownOption) => {
    const childrenValues = option.children!.map(
      (o: TDropdownOption) => o.value,
    );
    const newValue = ((selected as TDropdownOption[]) || [])
      .map((option) => option.value)
      .filter((v) => v !== option.value && !childrenValues.includes(v));

    onChange(newValue);
  };

  return (
    <div className="flex items-center flex-wrap gap-x-3 gap-y-1">
      {Object.values(selectedGroupCount)
        .filter((group) => group.count)
        .map((group) => (
          <Chip
            disabled={disabled}
            key={group.option.value}
            value={group.option.value}
            labelEl={
              group.isFull
                ? group.option.label
                : `${group.option.label} (${group.count})`
            }
            onRemoveClick={() => handleDelete(group.option)}
          />
        ))}
    </div>
  );
};

/**
 * This is place to keep all route paths we use across the project.
 * It should be always full paths so it is consisten to use and easy to compare.
 *  - ROOT postfix represents main pathes that are used by load balancer in backend integration;
 *  - INDEX postfix represents main pages, sometimes lists, that has reference on navigation or subnavigation;
 *  - DETAIL postfix represents entity main pages;
 *  - CREATE postfix represents entity creation pages;
 *  - UPDATE postfix represents entity update pages;
 *
 * Keep the pattern where DETAIL/CREATE/INDEX pages are build on top of INDEX
 */

export const DEFAULT = "/";
export const ANY_OTHER = "*";

// It is used on browser router level, thanks to it we don't need to duplicate
// it across every path, exceptions are redirections links out of the react router
// import.meta.env.BASE_URL is not set for tests so we need to set default
export const BASENAME = import.meta.env.BASE_URL || "/app";

/**
 * DASHBOARD
 */
export const DASHBOARD_ROOT = "/dashboard";
export const DASHBOARD_INDEX = DASHBOARD_ROOT;

export const dashboardIndexRoutes = [DASHBOARD_INDEX];

/**
 * STRATEGIES
 */
export const STRATEGY_ROOT = "/strategies";
export const STRATEGY_INDEX = STRATEGY_ROOT;

export const STRATEGY_CREATE = "/strategies/create";
export const STRATEGY_CREATE_DSP = "/strategies/create/dsp";
export const STRATEGY_CREATE_DSP_DRAFT = "/strategies/create/dsp/:strategyId";
export const STRATEGY_CREATE_SP = "/strategies/create/sp";
export const STRATEGY_CREATE_SP_DRAFT = "/strategies/create/sp/:strategyId";

export const STRATEGY_DETAIL = "/strategies/:strategyId";
export const STRATEGY_DETAIL_OVERVIEW = "/strategies/:strategyId/overview";
export const STRATEGY_DETAIL_CAMPAIGNS = "/strategies/:strategyId/campaigns";
export const STRATEGY_DETAIL_SINGLE_CAMPAIGN =
  "/strategies/:strategyId/campaigns/:campaignId";
export const STRATEGY_DETAIL_AUDIENCES = "/strategies/:strategyId/audiences";
export const STRATEGY_DETAIL_CREATIVES = "/strategies/:strategyId/creatives";
export const STRATEGY_DETAIL_HISTORY = "/strategies/:strategyId/history";
export const STRATEGY_DETAIL_CONTEXTUAL_TARGETING =
  "/strategies/:strategyId/targeting";
export const STRATEGY_DETAIL_TARGETING_MARKET =
  "/strategies/:strategyId/targeting/:market";
export const STRATEGY_DETAIL_TARGETING_PRODUCTS =
  "/strategies/:strategyId/targeting/:market/products";
export const STRATEGY_DETAIL_TARGETING_ADD_PRODUCTS =
  "/strategies/:strategyId/targeting/:market/products/add";
export const STRATEGY_DETAIL_TARGETING_EDIT_PRODUCTS =
  "/strategies/:strategyId/targeting/:market/products/edit";
export const STRATEGY_DETAIL_TARGETING_CATEGORIES =
  "/strategies/:strategyId/targeting/:market/categories";
export const STRATEGY_DETAIL_TARGETING_ADD_CATEGORIES =
  "/strategies/:strategyId/targeting/:market/categories/add";
export const STRATEGY_DETAIL_TARGETING_EDIT_CATEGORIES =
  "/strategies/:strategyId/targeting/:market/categories/edit";
export const STRATEGY_DETAIL_TARGETING_KEYWORDS =
  "/strategies/:strategyId/targeting/:market/keywords";
export const STRATEGY_PLANNER = "/strategies/:strategyId/planner";

export const strategyIndexRoutes = [STRATEGY_INDEX];

/**
 * AUDIENCES
 */
export const AUDIENCE_ROOT = "/audiences";
export const AUDIENCE_INDEX = "/audiences/lists";
export const AUDIENCE_SETS_INDEX = "/audiences/lists/sets";
export const AUDIENCE_SETS_DETAIL = "/audiences/lists/sets/:audienceSetId";
export const AUDIENCE_SETS_CREATE = "/audiences/lists/sets/create";
export const AUDIENCE_SETS_UPDATE = "/audiences/lists/sets/edit/:audienceSetId";
export const AUDIENCE_ALL_INDEX = "/audiences/lists/all";
export const AUDIENCE_PRODUCT_INDEX = "/audiences/lists/product";
export const AUDIENCE_CREATE = "/audiences/lists/all/create";
export const AUDIENCE_EDIT = "/audiences/lists/all/edit/:audienceId";

export const audienceIndexRoutes = [
  AUDIENCE_SETS_INDEX,
  AUDIENCE_ALL_INDEX,
  AUDIENCE_PRODUCT_INDEX,
];

/**
 * CREATIVES
 */
export const CREATIVE_ROOT = "/creatives";
export const CREATIVE_INDEX = CREATIVE_ROOT;
export const CREATIVE_LIST = "/creatives/:tabName";
export const CREATIVE_DETAIL = "/creatives/detail/:creativeId";
export const CREATIVE_CREATE = "/creatives/create";
export const CREATIVE_REC_DETAIL = "/creatives/recs/:recId";
export const CREATIVE_REC_CREATE = "/creatives/recs/create";

export const creativeIndexRoutes = [CREATIVE_INDEX];

/**
 * REPORTING
 */
export const REPORTING_ROOT = "/reporting";
export const REPORTING_OVERVIEW_INDEX = "/reporting/overview";
export const REPORTING_CAMPAIGNS_INDEX = "/reporting/campaigns";
export const REPORTING_AD_GROUPS_INDEX = "/reporting/ad-groups";
export const REPORTING_CREATIVE_INDEX = "/reporting/creatives";
export const REPORTING_INVENTORY_INDEX = "/reporting/inventory";

export const reportingIndexRoutes = [
  REPORTING_OVERVIEW_INDEX,
  REPORTING_CAMPAIGNS_INDEX,
  REPORTING_AD_GROUPS_INDEX,
  REPORTING_CREATIVE_INDEX,
  REPORTING_INVENTORY_INDEX,
];

/**
 * GENUS-AI
 */
export const GENUS_ROOT = "/creation";
export const GENUS_INDEX = GENUS_ROOT;

export const genusIndexRoutes = [GENUS_ROOT];

/**
 * ADMINISTRATION
 */
export const ADMIN_ROOT = "/administration";
export const ADMIN_USER_INDEX = "/administration/users";
export const ADMIN_ADVERTISER_INDEX = "/administration/advertisers";
export const ADMIN_ADVERTISER_CREATE = "/administration/advertisers/create";
export const ADMIN_ADVERTISER_UPDATE =
  "/administration/advertisers/edit/:advertiserId";
export const ADMIN_ADVERTISER_DETAIL =
  "/administration/advertisers/:advertiserId";
export const ADMIN_ADVERTISER_DETAIL_TRANSACTIONS =
  "/administration/advertisers/:advertiserId/transactions";
export const ADMIN_ADVERTISER_DETAIL_AD_TAG =
  "/administration/advertisers/:advertiserId/amazon_ad_tag";

export const adminIndexRoutes = [ADMIN_USER_INDEX, ADMIN_ADVERTISER_INDEX];

/**
 * PROFILE
 */
export const PROFILE_ROOT = "/profile";
export const PROFILE_INDEX = PROFILE_ROOT;
export const PROFILE_ACCOUNT_INDEX = "/profile/account";

export const profileIndexRoutes = [PROFILE_INDEX];

/**
 * AUTH
 * TODO: add AUTH_ROOT
 */
export const LOGIN = "/login";
export const LOGOUT = "/logout";

/**
 * ACCOUNT
 * TODO: add ACCOUNT_ROOT
 */
export const ACCOUNT_REGISTER_ACTIVATE = "/join/:token";
export const ACCOUNT_PASSWORD_RESET = "/reset_password";
export const ACCOUNT_PASSWORD_RESET_MAIL = "/reset_password/:token";

/**
 * TERMS & CONDITIONS
 */
export const TC_ROOT = "/terms";
export const TC_VOW = "/terms/vow";
export const TC_AMAZON = "/terms/amazon";

/**
 * ERRORS
 * TODO: add ERROR_ROOT
 */
export const ERR_ACCESS_DENIED = "/access-denied";
export const ERR_SERVICE_UNAVAILABLE = "/service-unavailable";

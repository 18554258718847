import { Button } from "common/components/buttons/Button";
import { Icon } from "common/components/Icon";
import { MessageKey, useFormatMessage } from "common/internationalization";

import { FormLabel } from "./FormLabel";
import { TLabelSize } from "./types";

export const LabelWithReset: React.FC<{
  label: string | JSX.Element;
  hasChanged?: boolean;
  labelSize?: TLabelSize;
  reset?: () => void;
  resetMessageKey?: MessageKey;
}> = ({
  label,
  hasChanged,
  labelSize,
  reset,
  resetMessageKey = "words.reset",
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="flex items-center justify-between">
      <FormLabel label={label} size={labelSize} />
      {hasChanged && reset && (
        <Button size="" onClick={() => reset()} dataTestId="reset">
          <div className="flex items-center transition text-gray-800 hover:text-blue-700 font-bold ">
            <Icon
              name="close"
              size="xsmall"
              alt={formatMessage(resetMessageKey)}
              weight="bold"
            />
            <p className="text-12 first-letter:capitalize lowercase">
              {formatMessage(resetMessageKey)}
            </p>
          </div>
        </Button>
      )}
    </div>
  );
};

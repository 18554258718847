/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useStore } from "react-redux";

import { User } from "common/types";
import { getUserFromCache } from "common/utils/users";

import { fetchBrand, fetchFeatureStatus } from "../app";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getUser } from "../selectors";
import { fetchUser } from "./thunkActions";
import { setUser } from "./userSlice";

export const useIsAdmin = () => {
  const user = useAppSelector(getUser<User | null>);
  return user?.permissions.includes("can_view_admin");
};

export const refresh = async ({
  dispatch,
  user,
}: {
  dispatch: Dispatch<any>;
  user: User | null;
}) => {
  dispatch(fetchBrand());
  dispatch(fetchFeatureStatus());
  if (user) dispatch(fetchUser());
};

export const useUserStoreSetup = () => {
  // Should be invoked only once in the app

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser<User | null>);
  const cachedUser = getUserFromCache();
  const store = useStore();

  if (JSON.stringify(cachedUser) !== JSON.stringify(user)) {
    dispatch(setUser(user));
  }

  useEffect(() => {
    document.addEventListener("VOW:logout", () => {
      store.dispatch({ type: "USER_LOGOUT" });
      dispatch(setUser(null));
      queryClient.clear();
    });
  }, []);
};

import { useEffect, useRef, useState } from "react";

import { Icon } from "common/components/Icon";
import { Expandable } from "common/components/Transitions";

import { TDropdownOption } from "../../../types";
import { OptionMultiple } from "./OptionMultiple";

type OptionGroupProps<T> = {
  option: TDropdownOption<T>;
  fieldTestId: string;
  setSelected: (option: TDropdownOption<T>[], op: "add" | "delete") => void;
  selected?: TDropdownOption<T>[];
  hideMainCheckboxes?: boolean;
  collapsingSubOptions?: boolean;
  indentLevel: number;
  checkboxPosition?: "left" | "right";
};

export const OptionMultipleGroup = <T,>({
  option,
  fieldTestId,
  setSelected,
  selected,
  hideMainCheckboxes,
  collapsingSubOptions = false,
  indentLevel,
  checkboxPosition = "left",
}: OptionGroupProps<T>) => {
  const [isExpanded, setIsExpanded] = useState(!collapsingSubOptions);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hideCheckbox = hideMainCheckboxes && (option.children || []).length > 0;

  useEffect(() => {
    // wait for Expandable to show elements
    setTimeout(() => {
      if (collapsingSubOptions && isExpanded && containerRef.current) {
        containerRef.current.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        });
      }
    }, 100);
  }, [isExpanded]);

  return (
    <div ref={containerRef}>
      <li key={option.value} className="flex w-full relative font-bold">
        <OptionMultiple
          key={option.value}
          fieldTestId={fieldTestId}
          option={option}
          setSelected={
            hideCheckbox ? () => setIsExpanded((prev) => !prev) : setSelected
          }
          selected={selected}
          hideCheckbox={hideCheckbox}
          indentLevel={indentLevel}
          trailingComponent={
            collapsingSubOptions && (option.children || []).length > 0 ? (
              <button
                aria-label="expand"
                className="min-w-[18px] px-1 z-10"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded((prev) => !prev);
                }}
              >
                <Icon name={isExpanded ? "caretDown" : "caretRight"} />
              </button>
            ) : undefined
          }
          checkboxPosition={checkboxPosition}
        />
      </li>

      <Expandable isOpen={isExpanded} duration={0}>
        {(option.children || []).map((opt: TDropdownOption<T>) => {
          if (option.children) {
            return (
              <OptionMultipleGroup<T>
                key={opt.value}
                fieldTestId={fieldTestId}
                option={opt}
                setSelected={setSelected}
                selected={selected}
                hideMainCheckboxes={hideMainCheckboxes}
                collapsingSubOptions={collapsingSubOptions}
                indentLevel={indentLevel + 1}
                checkboxPosition={checkboxPosition}
              />
            );
          }
          return (
            <OptionMultiple<T>
              key={opt.value}
              fieldTestId={fieldTestId}
              option={opt}
              setSelected={setSelected}
              selected={selected}
              indentLevel={indentLevel + 1}
              checkboxPosition={checkboxPosition}
            />
          );
        })}
      </Expandable>
    </div>
  );
};

import { mapNoFormToForm } from "../utils";
import { Dropdown, DropdownProps } from "./Dropdown";

type Props<T> = Omit<DropdownProps, "form" | "name"> & {
  selected: T;
  defaultValue: T;
  setSelected: (value: T) => void;
};

// eslint-disable-next-line react/function-component-definition
export function DropdownState<T>({
  selected,
  defaultValue,
  setSelected,
  ...dropdownProps
}: Props<T>) {
  const form = mapNoFormToForm<T>(selected, defaultValue, setSelected);
  return (
    <Dropdown
      form={form}
      name="dropdown-state"
      withError={false}
      {...dropdownProps}
    />
  );
}

import { createContext } from "react";

import { SetState } from "common/types";

export type TNavContext = {
  show: boolean;
  setShow: SetState<boolean>;
  goBackButton: { label?: string; url?: string | number };
  setGoBackButton: SetState<{
    label?: string;
    url?: string | number;
  }>;
};

export const NavContext = createContext<TNavContext>({
  show: true,
  setShow: () => {},
  goBackButton: { label: undefined, url: undefined },
  setGoBackButton: () => {},
});

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseMetrics } from './BaseMetrics';
export type StrategyList = {
  readonly id?: string;
  readonly name?: string;
  readonly channel_type?: StrategyList.channel_type;
  readonly goal?: StrategyList.goal | null;
  budget_at_risk: string;
  readonly primary_currency?: StrategyList.primary_currency;
  flight_dates: Record<string, string>;
  product_location: string;
  delivery_activation_status: string;
  formats: Array<'standard_display' | 'display' | 'mobile' | 'video' | 'online_video' | 'streaming_tv'>;
  markets: Array<'AU' | 'BR' | 'CA' | 'FR' | 'DE' | 'IN' | 'IT' | 'JP' | 'MX' | 'SA' | 'SG' | 'ES' | 'SE' | 'TR' | 'AE' | 'GB' | 'US'>;
  metrics: BaseMetrics;
  status: string;
  budget: string;
  readonly is_draft?: boolean;
  is_syncing: boolean;
  failure_reason: string;
  readonly is_archived?: string;
  readonly is_readonly?: string;
};
export namespace StrategyList {
  export enum channel_type {
    DSP = 'dsp',
    SPONSORED_BRANDS = 'sponsored_brands',
    SPONSORED_DISPLAY = 'sponsored_display',
    SPONSORED_PRODUCTS = 'sponsored_products',
  }
  export enum goal {
    AWARENESS = 'AWARENESS',
    CONVERSION = 'CONVERSION',
    CONSIDERATION = 'CONSIDERATION',
    OTHER = 'OTHER',
    CONVERSIONS_OFF_AMAZON = 'CONVERSIONS_OFF_AMAZON',
    ENGAGEMENT_WITH_MY_AD = 'ENGAGEMENT_WITH_MY_AD',
    CONSIDERATIONS_ON_AMAZON = 'CONSIDERATIONS_ON_AMAZON',
    PURCHASES_ON_AMAZON = 'PURCHASES_ON_AMAZON',
    MOBILE_APP_INSTALLS = 'MOBILE_APP_INSTALLS',
    PURCHASES_ON_OFF_AMAZON = 'PURCHASES_ON_OFF_AMAZON',
  }
  export enum primary_currency {
    USD = 'USD',
    MXN = 'MXN',
    CAD = 'CAD',
    BRL = 'BRL',
    AED = 'AED',
    SAR = 'SAR',
    GBP = 'GBP',
    EUR = 'EUR',
    SEK = 'SEK',
    TRY = 'TRY',
    AUD = 'AUD',
    INR = 'INR',
    SGD = 'SGD',
    JPY = 'JPY',
  }
}


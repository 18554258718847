import { MessageTypes } from "common/components/Message";
import { MessageKey, Values } from "common/internationalization";
import { ErrorsModalType } from "common/types";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const eventBus = {
  on(event: any, callback: any) {
    document.addEventListener(event, callback);
  },
  dispatch(event: any, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback: any) {
    document.removeEventListener(event, callback);
  },
  openSnackbar(
    message: MessageKey | string | JSX.Element,
    type: MessageTypes,
    values?: Values,
    suffixComponent?: JSX.Element,
    duration?: number,
  ) {
    this.dispatch("VOWMade:openSnackbar", {
      message,
      type,
      values,
      suffixComponent,
      duration,
    });
  },
  openErrorModal(error: ErrorsModalType) {
    this.dispatch("VOWMade:closeAllModals");
    this.dispatch("VOWMade:openErrorModal", {
      error,
    });
  },
};

import { FallbackRender } from "@sentry/react";

import { DocumentHead } from "App/components/AppRoutes/components";
import { UnexpectedError } from "common/components/modals/ErrorModal/components";
import { useFormatMessage } from "common/internationalization";

export const UnexpectedErrorPage: FallbackRender = () => {
  const formatMessage = useFormatMessage();

  return (
    <div className="h-screen">
      <DocumentHead pageName={formatMessage("unexpectedError.title")} />
      <UnexpectedError modal={false} />
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";

import { DatePicker } from "common/components/DatePicker";
import {
  QuickSelectors,
  TQuickSelectors,
} from "common/components/DatePicker/components/QuickSelectors";
import { PopoverRef, LabelPopover } from "common/components/popovers";
import { DEFAULT_DATE_FORMAT } from "common/constants";
import { useResizeScreenListener } from "common/hooks/useResizeScreenListener";
import { formatMessage } from "common/internationalization";
import { CalendarParamsForm } from "common/types";
import { parseFormObjectIntoDateRange } from "common/utils/dateRange";
import { formatLocaleDate, parse } from "common/utils/dates";
import {
  getGlobalDateRange,
  getGlobalDateRangeSettings,
  getParsedGlobalDateRangeIntoForm,
  setGlobalDateRange,
  useAppDispatch,
  useAppSelector,
} from "modules/store";

export const DatePickerGlobal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const globalDateRange = useAppSelector(getGlobalDateRange);
  const parsedGlobalDateRangeIntoForm = useAppSelector(
    getParsedGlobalDateRangeIntoForm,
  );
  const globalDateRangeSettings = useAppSelector(getGlobalDateRangeSettings);

  const popoverRef = useRef<PopoverRef>(null);
  const [quickSelectorsStyling, setQuickSelectorsStyling] =
    useState<TQuickSelectors["styling"]>("default");

  useResizeScreenListener({
    "desktop-s": () => {
      setQuickSelectorsStyling("compact");
    },
    "desktop-m": () => {
      setQuickSelectorsStyling("default");
    },
  });

  const form = useForm<CalendarParamsForm>({
    mode: "onSubmit",
    defaultValues: parsedGlobalDateRangeIntoForm,
  });
  useWatch(form);
  const {
    lower: lowerForm,
    upper: upperForm,
    selector: selectorForm,
  } = form.getValues();
  const { lower, upper, selector } = globalDateRange;

  const getButtonText = (translateSelector: boolean) => {
    if (selector)
      return translateSelector ? formatMessage(`date.${selector}`) : selector;
    return `${lower ? formatLocaleDate(lower) : ""} - ${
      upper ? formatLocaleDate(upper) : ""
    }`;
  };

  /**
   * Effect ensures that globalDateRange is mapped as search param
   */
  useEffect(() => {
    const searchParamValue = getButtonText(false).replace(/\s/g, "");
    if (searchParamValue !== (searchParams.get("dateRange") || "")) {
      setSearchParams(
        (searchParams) => {
          searchParams.set("dateRange", searchParamValue);
          return searchParams;
        },
        { replace: true, preventScrollReset: true, state },
      );
    }
  }, [globalDateRange, searchParams.get("dateRange")]);

  /**
   * Effect ensures that globalDateRange lower/upper date is within min/max dates
   */
  useEffect(() => {
    form.reset(parsedGlobalDateRangeIntoForm);
  }, [parsedGlobalDateRangeIntoForm]);

  const onPopoverClose = (state: boolean | undefined) => {
    if (state) return;
    form.reset();
  };

  const onApply = async (closePopover?: () => void) => {
    const isValid = await form.trigger();
    if (!isValid) {
      if (!closePopover) form.reset();
      return;
    }

    dispatch(
      setGlobalDateRange(parseFormObjectIntoDateRange(form.getValues())),
    );
    form.reset(form.getValues());

    if (!selectorForm && !lowerForm && !upperForm) {
      dispatch(
        setGlobalDateRange(
          parseFormObjectIntoDateRange({
            ...form.getValues(),
            selector: "lifetime",
          }),
        ),
      );
      form.reset({
        ...form.getValues(),
        selector: "lifetime",
      });
    }
    if (closePopover) closePopover();
  };

  return (
    <LabelPopover
      ref={popoverRef}
      buttonText={getButtonText(true)}
      iconName="calendar"
      testId="toggle-calendar"
      trailingComponents={[]}
      additionalButtonStylings="h-[36px] bg-white-200 text-blue-700 font-bold w-[274px]"
      isError={false}
      outline
      placement="bottom"
      offset={[0, 10]}
      onChange={onPopoverClose}
      disabled={globalDateRangeSettings.disabled}
      disabledInfoMessage={formatMessage("date.disabled")}
    >
      <div className="flex">
        <div className="p-5 border-r-[1px] border-white-600">
          <QuickSelectors
            form={form}
            dateFormat={DEFAULT_DATE_FORMAT}
            minDate={globalDateRangeSettings.minDate}
            maxDate={globalDateRangeSettings.maxDate}
            styling={quickSelectorsStyling}
            disabledSelectors={globalDateRangeSettings.disabledSelectors}
          />
        </div>
        <div className="flex m-5 desktop-m:mr-10 desktop-m:mt-10 w-[778px]">
          <DatePicker
            form={form}
            dateFormat={DEFAULT_DATE_FORMAT}
            startDate={
              lowerForm
                ? parse(lowerForm, DEFAULT_DATE_FORMAT, new Date(), {
                    silent: true,
                  })
                : undefined
            }
            endDate={
              upperForm
                ? parse(upperForm, DEFAULT_DATE_FORMAT, new Date(), {
                    silent: true,
                  })
                : undefined
            }
            monthsShown={2}
            inline
            minDate={globalDateRangeSettings.minDate}
            maxDate={globalDateRangeSettings.maxDate}
            disabledDayInfo={globalDateRangeSettings.disabledDayInfo}
            onCancel={() => popoverRef.current?.closePopover()}
            onApply={() => {
              onApply(popoverRef.current?.closePopover);
            }}
          />
        </div>
      </div>
    </LabelPopover>
  );
};

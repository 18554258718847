/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import { useCallback, useState } from "react";

import { Icon } from "common/components/Icon";
import { useListenOnKeyDown } from "common/hooks/useListenOnKeyDown";
import { useFormatMessage } from "common/internationalization";

type Props<T> = {
  selected: T;
  list: { data: T; component: JSX.Element }[];
  searchableFields: string[];
  onSelect: (value: T) => void;
};

type OptionType = {
  id: string;
  [key: string]: any;
};

export const SearchSelect = <T extends OptionType>({
  selected,
  list,
  searchableFields,
  onSelect,
}: Props<T>) => {
  const formatMessage = useFormatMessage();
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState(list);

  const onEnterHandle = useCallback(() => {
    onSelect(filteredList[0].data);
  }, [filteredList]);

  useListenOnKeyDown("Enter", true, onEnterHandle);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearch(value);
    setFilteredList(() =>
      list.filter((v) =>
        searchableFields.some((field) =>
          v.data[field].toLowerCase().includes(value.toLowerCase()),
        ),
      ),
    );
  };

  return (
    <div className="bg-white shadow-card rounded-lg overflow-hidden">
      <div
        data-testid="search-phone-no"
        className="flex items-center gap-x-2 px-3 py-2 border-b border-white-600"
      >
        <Icon name="search" />
        <input
          value={search}
          onChange={onSearch}
          placeholder={formatMessage("words.search")}
          className="w-full bg-transparent"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
      <ul className="bg-white overflow-y-auto max-h-[200px]">
        {filteredList.map((el) => (
          <li
            key={el.data.id}
            data-testid="country-phone-dropdown"
            className={classNames("transition hover:bg-white-300", {
              "bg-blue-300/20": el.data.id === selected.id,
            })}
          >
            <button onClick={() => onSelect(el.data)}>{el.component}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

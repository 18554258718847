import { AxiosResponse } from "axios";
import { get, isEqual } from "lodash";
import { useCallback } from "react";
import { Path, UseFormReturn } from "react-hook-form";

import { formatMessage, sortByMessage } from "common/internationalization";
import { eventBus } from "common/utils/eventBus";
import { UserPreferences } from "modules/api/models/UserPreferences";
import {
  useCreateUserPreferenceMutation,
  useEditUserPreferenceMutation,
} from "modules/api/mutations/reporting";

import {
  metricTabs,
  tableMetricValuesMap,
  defaultPodMetrics,
  defaultGraphMetrics,
  PERCENTAGE_METRICS,
  CURRENCY_METRICS,
  NUMBER_METRICS,
} from "./consts";
import { Metrics, PreferencesField, UserPreferencesForm } from "./types";

export const getChangesFormDefaultValues = (
  data: UserPreferences[],
): PreferencesField[] => {
  const goals = [
    UserPreferences.goal.AWARENESS,
    UserPreferences.goal.CONSIDERATION,
    UserPreferences.goal.CONVERSION,
  ];
  return goals.map((goal) => {
    const preference = data.find((p) => p.goal === goal);

    if (preference) {
      return {
        id: preference.id,
        goal,
        pod_metrics: preference.pod_metrics?.length
          ? preference.pod_metrics
          : defaultPodMetrics,
        graph_metrics: preference.graph_metrics?.length
          ? preference.graph_metrics
          : defaultGraphMetrics,
        table_metrics: preference.table_metrics?.length
          ? preference.table_metrics
          : tableMetricValuesMap[goal],
      };
    }
    return {
      goal,
      pod_metrics: defaultPodMetrics,
      graph_metrics: defaultGraphMetrics,
      table_metrics: tableMetricValuesMap[goal],
    };
  });
};

export const getUpdatedPreferences = (data: PreferencesField[]) =>
  data
    .map((preference) => ({
      id: preference.id,
      goal: preference.goal,
      pod_metrics: isEqual(preference.pod_metrics, defaultPodMetrics)
        ? []
        : preference.pod_metrics,
      graph_metrics: isEqual(preference.graph_metrics, defaultGraphMetrics)
        ? []
        : preference.graph_metrics,
      table_metrics: isEqual(
        preference.table_metrics,
        tableMetricValuesMap[preference.goal],
      )
        ? []
        : preference.table_metrics,
    }))
    .filter(
      (preference) =>
        preference.id ||
        preference.pod_metrics.length ||
        preference.graph_metrics.length ||
        preference.table_metrics.length,
    );

export const useSaveUserPreferences = (
  form: UseFormReturn<UserPreferencesForm>,
  onSuccess: () => void,
  setActiveForm: (name: string) => void,
) => {
  const createPreference = useCreateUserPreferenceMutation();
  const editPreference = useEditUserPreferenceMutation();

  const onSave = useCallback(async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      const errors = get(form.formState.errors, "preferences", []);
      if (errors[0]) setActiveForm(metricTabs[0].name);
      else if (errors[1]) setActiveForm(metricTabs[1].name);
      else if (errors[2]) setActiveForm(metricTabs[2].name);
      return;
    }

    const { preferences } = form.getValues();

    const updatedPreferences = getUpdatedPreferences(preferences);

    const promises: Promise<AxiosResponse<UserPreferences>>[] = [];

    updatedPreferences.forEach(({ id, ...payload }) => {
      if (id) {
        promises.push(
          editPreference.mutateAsync({
            id,
            payload: payload as UserPreferences,
          }),
        );
      } else {
        promises.push(createPreference.mutateAsync(payload as UserPreferences));
      }
    });

    Promise.all(promises)
      .then(() => {
        eventBus.openSnackbar("common.changesSuccessfullySaved", "success");
        onSuccess();
      })
      .catch(() => {
        eventBus.openSnackbar("common.changesErrorSaved", "error");
      });
  }, []);

  return onSave;
};

export const onResetToDefaults = (
  form: UseFormReturn<UserPreferencesForm>,
  name: Path<UserPreferencesForm>,
  goal: string,
) => {
  const fieldName = name.split(".").pop() || "";
  const options = {
    shouldValidate: true,
    shouldTouch: true,
    shouldDirty: true,
  };
  switch (fieldName) {
    case "pod_metrics":
      form.setValue(name, defaultPodMetrics, options);
      break;
    case "graph_metrics":
      form.setValue(name, defaultGraphMetrics, options);
      break;
    case "table_metrics":
      form.setValue(name, tableMetricValuesMap[goal], options);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(`${name} is not valid field of metric display settings.`);
  }
};

export const hasChangedFromDefaults = (
  form: UseFormReturn<UserPreferencesForm>,
  name: Path<UserPreferencesForm>,
  goal: string,
) => {
  const values = form.getValues(name);
  const fieldName = name.split(".").pop() || "";
  switch (fieldName) {
    case "pod_metrics":
      return !isEqual(values, defaultPodMetrics);
    case "graph_metrics":
      return !isEqual(values, defaultGraphMetrics);
    case "table_metrics":
      return !isEqual(values, tableMetricValuesMap[goal]);
    default:
      // eslint-disable-next-line no-console
      console.error(`${name} is not valid field of metric display settings.`);
  }
};

export const getUserPreferencePodsByGoal = (
  metricPreferencesData: UserPreferences[],
  goal:
    | UserPreferences.goal.AWARENESS
    | UserPreferences.goal.CONSIDERATION
    | UserPreferences.goal.CONVERSION
    | undefined,
) => {
  const preferencebyGoal =
    metricPreferencesData.find((p) => p.goal === goal)?.pod_metrics || [];
  return preferencebyGoal.length ? preferencebyGoal : defaultPodMetrics;
};

export const getMetricType = (metric: Metrics) => {
  if (PERCENTAGE_METRICS.includes(metric)) {
    return "percentage";
  }
  if (CURRENCY_METRICS.includes(metric)) {
    return "currency";
  }
  if (NUMBER_METRICS.includes(metric)) {
    return "number";
  }
  // eslint-disable-next-line no-console
  console.error(`Cannot map metric type ${metric}`);
  return "number";
};

export const mapMetricsToDropdownOptions = (metrics: Metrics[]) =>
  metrics
    .sort(sortByMessage("metrics"))
    .map((m) => ({ value: m, label: formatMessage(`metrics.${m}`) }));

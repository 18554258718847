import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";
import { useFavicon } from "react-use";

import { useBrand } from "common/hooks/useBrand";

export const useGoogleAnalyticsLocationTrack = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location.pathname]);
};

export const useCreateBrandFavicon = () => {
  const { favicon_file_url: faviconUrl } = useBrand();

  useFavicon(faviconUrl);
};

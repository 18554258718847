import {
  useFloating,
  autoUpdate,
  offset as offsetMiddleware,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  arrow as arrowMiddleware,
  FloatingArrow,
  size,
} from "@floating-ui/react";
import { useMemo, useRef, useState } from "react";

import useTailwind from "common/styles/tailwind-theme";

import { TooltipOptions, TooltipTheme } from "./types";

const ARROW_HEIGHT = 8;
const OFFSET = 0;

export function useTooltip({
  initialOpen = false,
  placement = "top",
  arrow = false,
  offset = OFFSET + +arrow * ARROW_HEIGHT,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const { colors: tailwindColors } = useTailwind().theme;
  const arrowRef = useRef(null);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offsetMiddleware(offset),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 10 }),
      size({
        apply({ availableHeight, elements }) {
          // eslint-disable-next-line no-param-reassign
          elements.floating.style.maxHeight = `${availableHeight}px`;
        },
        padding: 10,
      }),
      arrowMiddleware({
        element: arrowRef,
      }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  const renderFloatingArrow = (theme: TooltipTheme) => (
    <FloatingArrow
      ref={arrowRef}
      context={context}
      fill={
        theme === "light"
          ? tailwindColors.white["0"]
          : tailwindColors.blue["800"].DEFAULT
      }
    />
  );

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      renderFloatingArrow,
    }),
    [open, setOpen, interactions, data],
  );
}

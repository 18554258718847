import { useMemo, useState } from "react";

import { NavContext } from "./context";

type Props = React.PropsWithChildren;

export const NavContextProvider: React.FC<Props> = ({ children }) => {
  const [show, setShow] = useState<boolean>(true);
  const [goBackButton, setGoBackButton] = useState<{
    label?: string;
    url?: string | number;
  }>({
    label: undefined,
    url: undefined,
  });

  const value = useMemo(
    () => ({
      show,
      setShow,
      goBackButton,
      setGoBackButton,
    }),
    [show, goBackButton],
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

import { AxiosResponse } from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { TGlobalMessage } from "App/components/AppRoutes/components";
import { queriesNames } from "modules/api/consts";
import { getGlobalMessages } from "modules/api/requests/general";

export const useGlobalMessages = (
  options: UseQueryOptions<AxiosResponse<TGlobalMessage[]>>,
) => {
  const query = useQuery<AxiosResponse<TGlobalMessage[]>>(
    queriesNames["global-messages"],
    getGlobalMessages,
    options,
  );
  return query;
};

import { useEffect } from "react";

import { CallbackDefault } from "common/types";

export const useListenOnKeyDown = (
  key: string,
  isActive: boolean,
  action: CallbackDefault,
) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === key) {
        event.preventDefault();
        action();
      }
    };

    if (isActive) document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, action]);
};

import { createContext } from "react";

type PopoverContextValue = {
  isOpen: boolean;
  togglePopover: (newState?: boolean) => void;
};

export const PopoverContext = createContext<PopoverContextValue>({
  isOpen: false,
  togglePopover: () => {},
});

import { PropsWithChildren } from "react";

import { Icon } from "common/components/Icon";

type Props = PropsWithChildren & {
  title?: string;
  subtitle?: string;
  modal?: boolean;
  message?: string;
  withClose?: boolean;
  onClose?: () => void;
};
export const ErrorLayout: React.FC<Props> = ({
  title,
  subtitle,
  message,
  modal = false,
  onClose,
  withClose,
  children,
}) => (
  <div
    className={
      modal
        ? ""
        : "h-screen bg-white-300 grow overflow-y-auto flex flex-col items-center justify-center"
    }
  >
    <div className="bg-white w-[595px] p-10 text-blue-900 flex flex-col items-center justify-center rounded-md">
      {(withClose || modal) && (
        <div className="w-full flex justify-end">
          <button aria-label="close" onClick={onClose}>
            <Icon name="close" size="xlarge" />
          </button>
        </div>
      )}
      <h1 data-testid="error-code" className="text-40 font-black mb-2 pt-2.5">
        {title}
      </h1>
      <h3 className="text-20 font-bold mb-4 capitalize">{subtitle}</h3>
      <p className="font-semibold text-center text-14 px-2 pb-5 whitespace-pre-line">
        {message}
      </p>
      {children && (
        <div className="bg-white w-[595px] px-5 pt-5 flex items-center justify-center">
          {children}
        </div>
      )}
    </div>
  </div>
);

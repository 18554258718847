import { Helmet } from "react-helmet-async";

import { useBrand } from "common/hooks/useBrand";

export const DocumentHead = ({ pageName }: { pageName: string }) => {
  const brand = useBrand();

  return (
    <Helmet>
      <title>
        {brand.application_name}: {pageName}
      </title>
    </Helmet>
  );
};

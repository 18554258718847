import { TDropdownOption } from "../types";
import { areAllChildrenSelected, getParent } from "../utils";
import { Chip } from "./Chip";

type DropdownChipsDefaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  selected: TDropdownOption[];
  disabled?: boolean;
};

export const DropdownChipsDefault: React.FC<DropdownChipsDefaultProps> = ({
  onChange,
  selected,
  disabled = false,
}) => {
  const handleDelete = (value: string | number) => {
    let newValue = ((selected as TDropdownOption[]) || [])
      .map((option) => option.value)
      .filter((v) => v !== value);

    // Remove parent when removing first of all children selected
    const parent = getParent(value, selected as TDropdownOption[]);
    if (areAllChildrenSelected(parent!, selected as TDropdownOption[])) {
      newValue = newValue.filter((v) => v !== parent?.value);
    }

    onChange(newValue);
  };

  return (
    <div className="flex items-center flex-wrap gap-x-3 gap-y-1">
      {selected
        .filter((option) => !option.children)
        .map((option) => (
          <Chip
            key={option.value}
            value={option.value}
            labelEl={option.labelEl || option.label}
            onRemoveClick={() => handleDelete(option.value)}
            disabled={disabled}
          />
        ))}
    </div>
  );
};

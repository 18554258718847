import { createContext } from "react";

import { SetState } from "common/types";

import { MessageTypes } from "./types";

type MessageContextValue = {
  message: string;
  type: MessageTypes;
  setMessage: SetState<string>;
  setType: SetState<MessageTypes>;
  onClose: () => void;
};

export const MessageContext = createContext<MessageContextValue>({
  message: "",
  type: "error",
  setMessage: () => {},
  setType: () => {},
  onClose: () => {},
});

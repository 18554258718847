import { getUserFromCache } from "common/utils/users";

import { AppState } from "../types";

const APP_SETTINGS_STORAGE_KEY = "app_settings";

export const getAppSettingsFromCache = (): AppState["settings"] | null => {
  const user = getUserFromCache();
  if (!user) return null;

  const cachedData = localStorage.getItem(
    `${APP_SETTINGS_STORAGE_KEY}:${user.email}`,
  );
  if (!cachedData) return null;

  try {
    return JSON.parse(cachedData);
  } catch (SyntaxError) {
    return null;
  }
};

export const setAppSettingsToCache = (
  appSettings: AppState["settings"] | null,
) => {
  const user = getUserFromCache();
  if (appSettings && user) {
    localStorage.setItem(
      `${APP_SETTINGS_STORAGE_KEY}:${user.email}`,
      JSON.stringify(appSettings),
    );
  } else if (user) {
    localStorage.removeItem(`${APP_SETTINGS_STORAGE_KEY}:${user.email}`);
  }
};

import React, { PropsWithChildren, useMemo } from "react";

import { PopoverContext } from "./context";

type Props = PropsWithChildren<{
  isOpen: boolean;
  togglePopover: (newState?: boolean) => void;
}>;

export const PopoverContextProvider: React.FC<Props> = ({
  children,
  isOpen,
  togglePopover,
}) => {
  const PopoverContextProviderValue = useMemo(
    () => ({ isOpen, togglePopover }),
    [isOpen, togglePopover],
  );

  return (
    <PopoverContext.Provider value={PopoverContextProviderValue}>
      {children}
    </PopoverContext.Provider>
  );
};

import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useNavigateWithAdvertiser } from "common/hooks/useNavigateWithAdvertiser";
import { Advertiser } from "common/types";
import {
  defaultAdvertiser,
  getInitialAdvertiser,
} from "common/utils/advertisers";
import {
  fetchAudienceCategories,
  fetchProductCategories,
  getAdvertiser,
  getUser,
} from "modules/store";

import { useAppDispatch, useAppSelector } from "../hooks";
import { setAdvertiser } from "./advertiserSlice";

export const useAdvertiserStoreSetup = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const advertiser = useAppSelector(getAdvertiser);
  const navigate = useNavigateWithAdvertiser();
  const [searchParams, setSearchParams] = useSearchParams();

  const setAdvetiserIdSearchParam = (id: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("aid", id);
        return searchParams;
      },
      { replace: true, state },
    );
  };

  const setAdvertiserContext = (advertiserId?: string) => {
    const newAdevertiserId =
      advertiserId ??
      getInitialAdvertiser(
        user.advertiser_accounts,
        user.email,
        undefined,
        searchParams,
      ).id;
    // When advertiser ID changes, update the context data
    if (newAdevertiserId && newAdevertiserId !== advertiser?.id) {
      const advertiserObj: Advertiser | undefined =
        user.advertiser_accounts.find((a) => a.id === newAdevertiserId);
      dispatch(
        setAdvertiser({ advertiser: advertiserObj || defaultAdvertiser, user }),
      );
      dispatch(fetchProductCategories());
      dispatch(fetchAudienceCategories());
    }
  };

  useEffect(() => {
    if (user) {
      if (
        searchParams.get("aid") &&
        (!advertiser || advertiser.id !== searchParams.get("aid"))
      ) {
        setAdvertiserContext(searchParams.get("aid")!);
      } else if (advertiser?.id === "-1")
        navigate("/access-denied", { replace: true });
      else if (advertiser && !searchParams.get("aid"))
        setAdvetiserIdSearchParam(advertiser.id);
      else setAdvertiserContext();
    }
  }, [searchParams.get("aid"), advertiser?.id, user]);
};

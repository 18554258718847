import classNames from "classnames";

import { CheckState } from "common/components/form/checkboxes/types";
import { TDropdownOption } from "common/components/form/Dropdown/types";
import { Icon } from "common/components/Icon";

const generateIconName = (
  isSelected: boolean,
  isPartial: boolean,
  isHeader = false,
): CheckState => {
  if (isHeader && isPartial) return "checkboxPartiallyChecked";
  if (!isSelected) return "checkboxUnChecked";
  return "checkboxChecked";
};

type OptionMultipleProps<T> = {
  option: TDropdownOption<T>;
  fieldTestId: string;
  setSelected: (options: TDropdownOption<T>[], op: "add" | "delete") => void;
  selected?: TDropdownOption<T>[];
  hideCheckbox?: boolean;
  indentLevel: number;
  trailingComponent?: JSX.Element;
  checkboxPosition?: "left" | "right";
};

export const OptionMultiple = <T,>({
  option,
  fieldTestId,
  setSelected,
  selected = [],
  hideCheckbox,
  indentLevel,
  trailingComponent,
  checkboxPosition = "left",
}: OptionMultipleProps<T>) => {
  const selectedValues = selected.map((s) => s.value);
  let isSelected = selectedValues.includes(option.value);
  let isPartial = false;
  const isHeader = !!option.children;

  if (isHeader) {
    const childrenValues = option.children?.map((opt) => opt.value) || [];
    let selectedChildrenCount = 0;
    selectedValues.forEach((value) => {
      if (childrenValues.includes(value)) selectedChildrenCount += 1;
    });
    isSelected = isSelected || selectedChildrenCount === childrenValues.length;
    isPartial = !isSelected && selectedChildrenCount > 0;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
    <div
      data-testid={`${option.value}-${fieldTestId}-dropdown-option`}
      style={{ paddingLeft: `${24 + indentLevel * 16}px` }}
      className={classNames(
        "flex w-full cursor-pointer py-2 hover:bg-white-300 bg-white-0 pr-6 items-center gap-x-2",
        {
          "bg-white-600": isSelected || (hideCheckbox && isPartial),
          "flex-row-reverse": checkboxPosition === "right",
        },
      )}
      onClick={() =>
        setSelected([option], isSelected || isPartial ? "delete" : "add")
      }
      aria-selected={isSelected || isPartial}
    >
      {!hideCheckbox && (
        <Icon
          name={generateIconName(isSelected, isPartial, isHeader)}
          size="xlarge"
          alt="check"
        />
      )}
      <div className="flex w-full items-center gap-x-2">
        <span
          className={classNames("w-full text-blue-800 text-left", {
            "font-normal": indentLevel !== 0,
          })}
          data-testid={`${option.label}-${fieldTestId}-dropdown-option-label`}
        >
          {option.dropdownLabel || option.labelEl || option.label}
        </span>
        {trailingComponent}
      </div>
    </div>
  );
};

import { get, post, put, remove } from "common/api";
import { TableQueryParams } from "common/components/tables/types";
import {
  StrategyCreativesListPaginated,
  StrategyCreativesListNoPagination,
  EditStrategyCreativePayload,
  UpdateCampaignCreativePayload,
} from "modules/Strategies/types";

export const getStrategyCreativesList = (
  id?: string,
  params?: TableQueryParams & {
    amz_advertiser_id?: string;
    status?: string;
    languages?: string;
    metrics_date_range?: string;
    resolution?: string;
  },
) =>
  get<StrategyCreativesListPaginated>(`/strategies/${id}/creatives/`, {
    params,
  });

export const getStrategyCreativesListNoPagination = (
  id?: string,
  params?: TableQueryParams & {
    amz_advertiser_id?: string;
    status?: string;
    languages?: string;
    metrics_date_range?: string;
    resolution?: string;
  },
) =>
  get<StrategyCreativesListNoPagination>(`/strategies/${id}/creatives/`, {
    params,
  });

export const editStrategyCreatives = ({
  payload,
  id,
}: {
  payload: EditStrategyCreativePayload;
  id: string;
}) => post(`/strategies/${id}/creatives/edit/`, payload);

export const updateStrategyCreative = ({
  payload,
  strategyId,
  creativeId,
}: {
  payload: UpdateCampaignCreativePayload;
  strategyId: string;
  creativeId: string;
}) => put(`/strategies/${strategyId}/creatives/${creativeId}/`, payload);

export const removeStrategyCreative = ({
  strategyId,
  creativeId,
}: {
  strategyId: string;
  creativeId: string;
}) => remove(`/strategies/${strategyId}/creatives/${creativeId}/`);

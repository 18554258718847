import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Advertiser, User, UserApi } from "common/types";
import { setAdvertiserIdToCache } from "common/utils/advertisers";

export interface AdvertiserState {
  advertiser: Advertiser | null;
}

const initialState: AdvertiserState = {
  advertiser: null,
};

const advertiserSlice = createSlice({
  name: "advertiser",
  initialState,
  reducers: {
    setAdvertiser(
      state,
      action: PayloadAction<{
        advertiser: Advertiser | null;
        user: User | UserApi | null;
      }>,
    ) {
      const { advertiser, user } = action.payload;
      state.advertiser = advertiser;
      if (advertiser?.id !== "-1") {
        setAdvertiserIdToCache(advertiser?.id || "", user?.email || "");
      }
    },
  },
});

export const { setAdvertiser } = advertiserSlice.actions;
export default advertiserSlice.reducer;

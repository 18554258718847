import { AxiosResponse } from "axios";

import { IconName } from "common/components/Icon";
import { TableQueryParams } from "common/components/tables/types";
import { formatMessage } from "common/internationalization";
import {
  AnyObject,
  Currency,
  DateRange,
  Language,
  Metrics,
  Pagination,
  SupportedCountry,
} from "common/types";
import { SponsoredMarketBudget } from "modules/api/models/SponsoredMarketBudget";
import { SponsoredStrategyDraft } from "modules/api/models/SponsoredStrategyDraft";
import { SponsoredTarget } from "modules/api/models/SponsoredTarget";
import { StrategyCreatives } from "modules/api/models/StrategyCreatives";
import { StrategyList as StrategyListPayload } from "modules/api/models/StrategyList";
import {
  AudienceSet,
  AudienceTargetingMatchType,
  LogicOperator,
  MarketAudienceSelection,
} from "modules/Audiences/types";
import { ConversionType } from "modules/Conversions/types";
import {
  Asset,
  AssetMetrics,
  AssetTargetType,
  CreativeType,
  TargetType,
} from "modules/Creatives/types";
import { CampaignStatus, Channel, Format } from "modules/Reporting/types";

import { ConvertBudgetsBids } from "./CreateStrategy/CreateStrategyDSP/types";

export type GenericStrategy = {
  name: string;
  flight_dates: DateRange;
  product_categories: string[];
  video_product_categories: string[];
  primary_currency?: Currency;
  is_archived?: boolean;
  is_readonly?: boolean;
  can_be_extended?: boolean;
  budget?: string;
  goal?: StrategyGoal;
  is_draft: boolean;
  is_syncing: boolean;
  failure_reason: string | null;
};

export type StrategyMetrics = {
  conversions: number | null;
  impressions: number | null;
  click_throughs: number | null;
  display_currency: Currency;
  total_cost: string | null;
  purchases: number | null;
  vr: string | null;
  ecpc: string | null;
  ecpm: string | null;
  ctr: string | null;
  dpvr: string | null;
  off_amazon_roas: string | null;
  product_sales: string | null;
  off_amazon_product_sales: string | null;
  off_amazon_purchases: number | null;
  vcr: string | null;
  total_sales?: string | null;
  t_roas: string | null;
  roas?: string | null;
  cpa?: string | null;
  cvr?: string | null;
  acos?: string | null;
};

export type Strategy = GenericStrategy & {
  id: string;
  budget: string;
  budget_at_risk: string;
  formats: Format[];
  kpis: KPIMetric[];
  markets: SupportedCountry[];
  product_location: ProductLocation;
  last_exported: string | null;
  status: StrategyStatus;
  metrics: StrategyMetrics;
  pacing_ratio: string | null;
  asin_numbers: string;
  conversion_types: ConversionType[];
  delivery_activation_status: ActivationStatus;
  created_at: string;
  audience_targeting_match_type: "EXACT" | "SIMILAR";
};

export type AudienceSetStrategy = {
  id: string;
  name: string;
  goal: StrategyGoal;
  status: CampaignStatus;
  metrics: StrategyMetrics;
};
export type GetAudienceSetStrategyPayload = AxiosResponse<
  Pagination<AudienceSetStrategy>
>;

export type MarketsExtendedInfo<T> = {
  missingMarkets: T[];
  allMarketsMissing: boolean;
  someMarketsInsufficent?: boolean;
  allMarketsInsufficent?: boolean;
};

export const strategyCurrencyTypes = ["primary", "market"] as const;
export type StrategyCurrencyType = (typeof strategyCurrencyTypes)[number];

export const strategyStatusList = [
  "1_delivering",
  "2_out_of_budget",
  "3_ended",
  "4_not_running",
  "5_ready_to_deliver",
  "6_inactive",
] as const;

export type StrategyStatus = (typeof strategyStatusList)[number];

export const strategyGoals = [
  "AWARENESS",
  "CONVERSION",
  "CONSIDERATION",
] as const;
export type StrategyGoal = (typeof strategyGoals)[number];

export const strategyEligibleChannelsList: StrategyEligibleChannel[] = [
  "dsp",
  "sponsored_products",
];
export type StrategyEligibleChannel = Extract<
  Channel,
  "dsp" | "sponsored_products"
>;
export const strategyEligibleChannelIconsMap: Record<
  StrategyEligibleChannel,
  IconName
> = {
  dsp: "market",
  sponsored_products: "shoppingCart",
};

export const awarenessGoalKPIs = ["REACH", "FREQUENCY"] as const;
export type AwarenessGoalKPIs = (typeof awarenessGoalKPIs)[number];

export const considerationGoalKPIs = [
  "COST_PER_VIDEO_COMPLETION",
  "VIDEO_COMPLETION_RATE",
  "CLICK_THROUGH_RATE",
  "COST_PER_CLICK",
  "DETAIL_PAGE_VIEW_RATE",
  "COST_PER_DETAIL_PAGE_VIEW",
] as const;
export type ConsiderationsGoalKPIs = (typeof considerationGoalKPIs)[number];

export const conversionGoalKPIs = [
  "COST_PER_ACTION",
  "RETURN_ON_AD_SPEND",
  "TOTAL_RETURN_ON_AD_SPEND",
  "COMBINED_RETURN_ON_AD_SPEND",
  "COST_PER_FIRST_APP_OPEN",
  "COST_PER_SIGN_UP",
] as const;
export type ConversionsGoalKPIs = (typeof conversionGoalKPIs)[number];

export const kpiMetrics = [
  ...awarenessGoalKPIs,
  ...considerationGoalKPIs,
  ...conversionGoalKPIs,
];

export type KPIMetric =
  | AwarenessGoalKPIs
  | ConversionsGoalKPIs
  | ConsiderationsGoalKPIs;

export const isKpiMetric = (value: string): value is KPIMetric =>
  kpiMetrics.includes(value as KPIMetric);

export type GoalAndKPI = {
  goal?: StrategyGoal;
  kpi?: KPIMetric;
};

export const goalAndKpiOptions = [
  {
    value: "awareness",
    label: formatMessage("strategy.goals.AWARENESS"),
    children: awarenessGoalKPIs.map((kpi: KPIMetric) => ({
      label: formatMessage(`strategy.metric.${kpi}`),
      value: `awareness:${kpi}`,
    })),
  },
  {
    value: "conversion",
    label: formatMessage("strategy.goals.CONVERSION"),
    children: conversionGoalKPIs.map((kpi: KPIMetric) => ({
      label: formatMessage(`strategy.metric.${kpi}`),
      value: `conversion:${kpi}`,
    })),
  },
];
export type GoalAndKpiOptions = typeof goalAndKpiOptions;

export const goalAndKpiValues = [
  ...awarenessGoalKPIs.map((kpi: KPIMetric) => `${strategyGoals[0]}:${kpi}`),
  ...conversionGoalKPIs.map((kpi: KPIMetric) => `${strategyGoals[1]}:${kpi}`),
  ...considerationGoalKPIs.map(
    (kpi: KPIMetric) => `${strategyGoals[2]}:${kpi}`,
  ),
];
export type GoalAndKpiValue = (typeof goalAndKpiValues)[number];

export const productLocations = [
  "SOLD_ON_AMAZON",
  "NOT_SOLD_ON_AMAZON",
] as const;
export type ProductLocation = (typeof productLocations)[number];

export const mapGoalFormat: Record<
  StrategyGoal | "CONVERSION_OFF_AMAZON" | "CONSIDERATION_OFF_AMAZON",
  Record<Format, KPIMetric[]>
> = {
  AWARENESS: {
    standard_display: ["REACH", "FREQUENCY"],
    display: ["REACH", "FREQUENCY"],
    video: ["REACH", "FREQUENCY"],
    online_video: ["REACH", "FREQUENCY"],
    streaming_tv: ["REACH", "FREQUENCY"],
    mobile: ["REACH", "FREQUENCY"],
  },
  CONSIDERATION: {
    standard_display: [
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
    display: [
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
    video: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
    online_video: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
    streaming_tv: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
    mobile: [
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
      "DETAIL_PAGE_VIEW_RATE",
      "COST_PER_DETAIL_PAGE_VIEW",
    ],
  },
  CONSIDERATION_OFF_AMAZON: {
    standard_display: ["CLICK_THROUGH_RATE", "COST_PER_CLICK"],
    display: ["CLICK_THROUGH_RATE", "COST_PER_CLICK"],
    video: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
    ],
    online_video: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
    ],
    streaming_tv: [
      "COST_PER_VIDEO_COMPLETION",
      "VIDEO_COMPLETION_RATE",
      "CLICK_THROUGH_RATE",
      "COST_PER_CLICK",
    ],
    mobile: ["CLICK_THROUGH_RATE", "COST_PER_CLICK"],
  },
  CONVERSION: {
    standard_display: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_SIGN_UP",
    ],
    display: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_FIRST_APP_OPEN",
      "COST_PER_SIGN_UP",
    ],
    video: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_SIGN_UP",
    ],
    online_video: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_SIGN_UP",
    ],
    streaming_tv: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_SIGN_UP",
    ],
    mobile: [
      "COST_PER_ACTION",
      "RETURN_ON_AD_SPEND",
      "TOTAL_RETURN_ON_AD_SPEND",
      "COMBINED_RETURN_ON_AD_SPEND",
      "COST_PER_FIRST_APP_OPEN",
      "COST_PER_SIGN_UP",
    ],
  },
  CONVERSION_OFF_AMAZON: {
    standard_display: ["COST_PER_ACTION", "COST_PER_SIGN_UP"],
    display: ["COST_PER_ACTION", "COST_PER_FIRST_APP_OPEN", "COST_PER_SIGN_UP"],
    video: ["COST_PER_ACTION", "COST_PER_SIGN_UP"],
    online_video: ["COST_PER_ACTION", "COST_PER_SIGN_UP"],
    streaming_tv: ["COST_PER_ACTION", "COST_PER_SIGN_UP"],
    mobile: ["COST_PER_ACTION", "COST_PER_FIRST_APP_OPEN", "COST_PER_SIGN_UP"],
  },
};

export const mapKpiToMetric: Record<KPIMetric, CampaignMetric | undefined> = {
  REACH: undefined,
  COST_PER_ACTION: "cpa",
  RETURN_ON_AD_SPEND: "roas",
  TOTAL_RETURN_ON_AD_SPEND: "t_roas",
  COMBINED_RETURN_ON_AD_SPEND: "c_roas",
  COST_PER_SIGN_UP: "cpsu",
  CLICK_THROUGH_RATE: "ctr",
  COST_PER_CLICK: "ecpc",
  DETAIL_PAGE_VIEW_RATE: "dpvr",
  COST_PER_DETAIL_PAGE_VIEW: "cpdpv",
  COST_PER_VIDEO_COMPLETION: "cpvc",
  VIDEO_COMPLETION_RATE: "vcr",
  COST_PER_FIRST_APP_OPEN: undefined,
  FREQUENCY: undefined,
};

const standardDisplayDevices = [
  "desktop_and_mobile_web",
  "mobile_web",
  "mobile_ios",
  "mobile_android",
  "desktop",
] as const;
export type StandardDisplayDevices = (typeof standardDisplayDevices)[number];

const aapMobileAppDevices = [
  "iphone",
  "ipad",
  "android",
  "kindle_fire",
  "kindle_fire_hd",
] as const;
export type AapMobileAppDevices = (typeof aapMobileAppDevices)[number];

const videoDevices = [
  "desktop",
  "mobile",
  "connected_tv",
  "desktop_and_mobile",
  "mobile_and_connected_tv",
  "desktop_and_connected_tv",
  "all",
] as const;
export type VideoDevices = (typeof videoDevices)[number];

export type AdGroupDevice =
  | StandardDisplayDevices
  | AapMobileAppDevices
  | VideoDevices;

export const mapFormatAssetType: Record<string, AssetTargetType> = {
  display: "DISPLAY",
  video: "VIDEO",
  streaming_tv: "STREAMING_TV",
  mobile: "MOBILE",
};

export const orientations = ["LANDSCAPE", "PORTRAIT", "ANY"] as const;
export type Orientation = (typeof orientations)[number];

export const bidStrategy = [
  "SPEND_BUDGET_IN_FULL",
  "MAXIMIZE_PERFORMANCE",
] as const;
export type BidStrategy = (typeof bidStrategy)[number];

export const autoOptimizations = ["BID", "BUDGET", "BID_AND_BUDGET"] as const;
export type AutoOptimizations = (typeof autoOptimizations)[number];

export const amazonViewability = [
  "allow_all",
  "greater_or_equal_70_percent",
  "greater_or_equal_60_percent",
  "greater_or_equal_50_percent",
  "greater_or_equal_40_percent",
  "up_to_40_percent",
] as const;
export type AmazonViewability = (typeof amazonViewability)[number];

export const pacings = ["evenly", "frontloaded"] as const;
export type Pacing = (typeof pacings)[number];

export const catchUpBoosts = ["2x", "3x", "4x", "none"] as const;
export type CatchUpBoost = (typeof catchUpBoosts)[number];

export const mobileOs = ["IOS", "ANDROID", "BOTH"] as const;
export type MobileOs = (typeof mobileOs)[number];

export const frequencies = ["uncapped", "custom"] as const;
export type Frequency = (typeof frequencies)[number];

export const budgetCapMethod = ["daily", "monthly", "both", "none"] as const;
export type BudgetCapMethod = (typeof budgetCapMethod)[number];

export type StrategyAsset = Asset & {
  clickthrough_url: Record<CreativeType, string>;
};

export type EditStrategyCreative = StrategyAsset & {
  creative_id: string;
  creative_type: CreativeType;
};

export type EditStrategyCreativePayload = {
  assets: {
    id: string;
    markets: {
      market: SupportedCountry;
      clickthrough_url: string;
    }[];
  }[];
  existing_creatives: {
    creative_id: string;
    clickthrough_url: string;
  }[];
  pre_approved_creatives: string[];
  rec_creatives: string[];
};

export type CreateStrategyFormCommon = {
  name: string;
  markets_info: MarketAudienceSelection;
  flight_dates: DateRange;
  formats: Format[];
  goal: StrategyGoal;
  asins: AsinsForm;
  primary_currency: Currency;
  current_step?: number;
};

export type CreateStrategyForm = CreateStrategyFormCommon & {
  markets: string[];
  kpi_metrics: Partial<Record<Format, KPIMetric | undefined>>;
  target_kpi: Record<Format, number | undefined>;
  product_categories: string[];
  video_product_categories: string[];
  video_product_categories_other: string[];
  audience_targeting_match_type: AudienceTargetingMatchType;
  product_location: ProductLocation;
  assets: Record<string, Record<SupportedCountry, StrategyAsset>>;
  recs: Record<string, StrategyFormResponsiveECommerceCreative>;
  deals: string[];
  conversion_types: ConversionType[];
} & ConvertBudgetsBids;

export type CreateStrategySPForm = CreateStrategyFormCommon & {
  markets: SupportedCountry[];

  daily_budget: Partial<
    Record<SupportedCountry, { value: number; visibleValue: number }> // value represents the full value without rounding; visibleValue is fixed to max 2 decimal places
  >;
  budget_cap: Partial<
    Record<SupportedCountry, { value: number; visibleValue: number }>
  >;
  budget_type: "total" | "monthly";
  targeting: {
    close_match_switch: boolean;
    close_match: number;
    loose_match_switch: boolean;
    loose_match: number;
    substitutes_switch: boolean;
    substitutes: number;
    complements_switch: boolean;
    complements: number;
  };
  adjustments: {
    top_of_search: number;
    rest_of_search: number;
    product_pages: number;
  };
};

export type AdGroup = {
  id: string;
  name: string;
  audience_set: string;
  creatives: string[];
  base_supply_bid: number;
  budget: number | null;
  pacing: string;
  last_fetched_data_updated_at: string;
  is_archived: boolean;
  is_readonly: boolean;
};

export type AdvancedAdGroup = AdGroup & {
  flight_dates: DateRange;
  device?: AdGroupDevice | null;
  orientation: Orientation;
  maximum_average_cpm: number;
  amazon_viewability: AmazonViewability;
  pacing_profile: Pacing;
  catch_up_boost: CatchUpBoost;
  mobile_os: MobileOs;
  frequency: Frequency;
  frequency_cap_days: number | null;
  frequency_cap_amount: number | null;
  inventory_sources: InventorySources;
  last_fetched_data_updated_at: string;
};

export type FormAdvancedAdGroup = Partial<
  Omit<AdvancedAdGroup, "inventory_sources"> & {
    inventory_sources: { amazon_exclusive: string[]; open_exchanges: string[] };
    inventory_sources_type: keyof InventorySources | "both";
  }
>;

export type AdvancedAdGroupPayload = Partial<
  Omit<AdvancedAdGroup, "inventory_sources"> & {
    inventory_sources: string[];
  }
>;

export const campaignFormatsList = [
  "standard_display",
  "display",
  "video",
  "online_video",
  "streaming_tv",
  "amazon_mobile_display",
  "aap_mobile_app",
] as const;
export type CampaignFormat = (typeof campaignFormatsList)[number];

export type AdvancedAdGroupChoices = {
  device_choices: AdGroupDevice[];
  orientation_choices: Orientation[];
  amazon_viewability_choices: AmazonViewability[];
  pacing_profile_choices: Pacing[];
  catch_up_boost_choices: CatchUpBoost[];
  mobile_os_choices: MobileOs[];
  inventory_sources_choices: InventorySourceChoices;
  audience_set_choices: { id: string; name: string }[];
  creatives_choices: { id: string; name: string }[];
};
export const campaignMetric = [
  "conversions",
  "impressions",
  "purchases",
  "click_throughs",
  "display_currency",
  "total_sales",
  "total_cost",
  "roas",
  "ecpm",
  "cpa",
  "ecpc",
  "cvr",
  "acos",
  "ctr",
  "vr",
  "dpvr",
  "off_amazon_roas",
  "product_sales",
  "off_amazon_product_sales",
  "off_amazon_purchases",
  "vcr",
  "t_roas",
  "c_roas",
  "cpsu",
  "cpfao",
  "dpvr",
  "cpdpv",
  "cpvc",
] as const;
export type CampaignMetric = (typeof campaignMetric)[number];

export type CampaignMetrics = {
  conversions: number | null;
  impressions: number | null;
  purchases: number | null;
  click_throughs: number | null;
  display_currency: Currency;
  total_sales: string | null;
  total_cost: string | null;
  sales: string | null;
  roas: string | null;
  ecpm: string | null;
  cpa: string | null;
  ecpc: string | null;
  cvr: string | null;
  acos: string | null;
  ctr: string | null;
  vr: string | null;
  dpvr: string | null;
  off_amazon_roas: string | null;
  product_sales: string | null;
  off_amazon_product_sales: string | null;
  off_amazon_purchases: number | null;
  vcr: string | null;
  t_roas: string | null;
  c_roas: string | null;
  cpsu: string | null;
  cpfao: string | null;
  cpdpv: string | null;
  cpvc: string | null;
};

export type Campaign = {
  id: string;
  amz_advertiser_id: string;
  name: string;
  status: CampaignStatus;
  market: SupportedCountry;
  goal_and_kpi: GoalAndKPI;
  format: CampaignFormat;
  flight_dates: DateRange;
  ad_groups: AdGroup[];
  budget: string;
  budget_at_risk: string;
  display_currency: Currency;
  metrics: CampaignMetrics;
  base_supply_bid: string | null;
  pacing_ratio: string | null;
  delivery_activation_status: CampaignDeliveryActivationStatus;
  days_active: number;
  is_archived: boolean;
  is_readonly: boolean;
  is_syncing: boolean;
  failure_reason: string | null;
  target_kpi: string | null;
  frequency_cap_days: number | null;
  frequency_cap_amount: number | null;
  asin_numbers: string[];
};

export type CampaignDeliveryActivationStatus =
  | "INACTIVE"
  | "ACTIVE"
  | "PENDING";

export type MarketsInfo = {
  market: SupportedCountry;
  budget?: number;
  base_supply_bid?: number;
  currency: Currency;
  audience_targeting: { audience_set_id: string; audience_type: string }[];
};

export type CreativeAsset = Pick<
  Asset,
  | "id"
  | "language"
  | "url"
  | "file_size"
  | "extension"
  | "status"
  | "target_types"
> &
  Partial<Pick<Asset, "duration">> & {
    thumbnail_url: string;
    resolution: string;
  };

export type Creative2 = Pick<
  StrategyCreative,
  "amz_id" | "id" | "approval_status" | "name" | "type" | "click_through_url"
> & { market: SupportedCountry };

export type Creative = {
  amz_id: string;
  id: string;
  name: string;
  type: CreativeType;
  market: SupportedCountry;
  approval_status: StrategyCreatives.approval_status;
  click_through_url?: string;
};

export type REC = Creative & {
  asins: string[];
  created_at: string;
  metrics: Partial<Metrics> & { display_currency: Currency };
  headline?: string;
  language?: Language;
};

export type StrategyCreative = Omit<
  Creative,
  "market" | "metrics" | "asins" | "created_at"
> & {
  creative_type: TargetType[];
  market: SupportedCountry;
  metrics: AssetMetrics;
  asset?: CreativeAsset;
  approval_status_failure_details: {
    reasons: string[];
  };
};

export type StrategyFormResponsiveECommerceCreative = Pick<
  REC,
  "id" | "market" | "name"
>;

export type UpdateCampaignCreativePayload = {
  name: string;
  click_through_url: string;
};

export type CreativesListPaginated = Pagination<Creative>;
export type CreativesListPaginatedResponse =
  AxiosResponse<CreativesListPaginated>;
export type CreativesListNoPagination = Creative[];

export type RECListPaginated = Pagination<REC>;
export type RECListPaginatedResponse = AxiosResponse<RECListPaginated>;

export type StrategyCreativesListPaginated = Pagination<StrategyCreative>;
export type StrategyCreativesListPaginatedResponse =
  AxiosResponse<StrategyCreativesListPaginated>;
export type StrategyCreativesListNoPagination = StrategyCreative[];

export type Audience = {
  audience_ids: string;
};

export type AudiencesListResponse = Pagination<Audience>;

/*
product_categories - format expected by BE:

["Automotive","Beauty & Fashion:Accessories","Beauty & Fashion:Hair"]
*/
export type CreateStrategyPayload = Omit<GenericStrategy, "budget"> &
  Pick<
    CreateStrategyForm,
    | "audience_targeting_match_type"
    | "product_categories"
    | "video_product_categories"
    | "primary_currency"
    | "product_location"
    | "current_step"
    | "conversion_types"
  > & {
    markets_info: MarketsInfo[];
    goal: StrategyGoal;
    formats_and_kpis: {
      format: Format;
      kpi?: KPIMetric;
      target_kpi: number | undefined;
    }[];
    assets: {
      id: string;
      name: string;
      target_types: AssetTargetType[];
      markets: { market: SupportedCountry; clickthrough_url: string }[];
      creative_type: CreativeType;
    }[];
    asin_numbers?: string;
    draft_id?: string;
    pre_approved_creatives?: string[];
    rec_creatives: string[];
    deals: string[];
  };

export type CreateStrategyResponse = Strategy & {
  product_categories: string[];
};
export type CreateStrategyResponseError = AnyObject;

export type StrategyMarketsInfo = MarketsInfo & {
  audiences: Pick<
    AudienceSet,
    "id" | "name" | "audience_groups" | "is_archived"
  >[];
};

export const StrategyChannels = StrategyListPayload.channel_type;
export type StrategyChannelType = StrategyListPayload.channel_type;

export type StrategyDSPDraft = Omit<
  CreateStrategyPayload,
  "markets_info" | "assets"
> &
  Pick<
    Strategy,
    "id" | "is_archived" | "is_readonly" | "audience_targeting_match_type"
  > & {
    markets_info: StrategyMarketsInfo[];
    creatives: {
      id?: string;
      name: string;
      click_through_url: string;
      asset: Pick<StrategyAsset, "name" | "id" | "asset_type" | "is_archived">;
      market: SupportedCountry;
      type: CreativeType;
    }[];
    pre_approved_creatives: string[];
    rec_creatives: string[];
    assets: (Pick<
      StrategyAsset,
      "id" | "target_types" | "name" | "is_archived"
    > & {
      markets: { market: SupportedCountry; clickthrough_url: string }[];
      creative_type: CreativeType;
    })[];
    targeting_type: string;
    deals: string[];
    channel_type: StrategyChannelType;
  };

export type StrategySponsoredDraftPayload = SponsoredStrategyDraft & {
  flight_dates: DateRange;
  goal?: StrategyGoal;
  market_budgets: Array<SponsoredMarketBudget>;
  targets: Array<SponsoredTarget>;
  top_of_search_adjustment: number;
  rest_of_search_adjustment: number;
  product_pages_adjustment: number;
  draft_id?: string;
  asin_numbers?: string;
  current_step?: number;
};

export type StrategyList = Omit<
  Strategy,
  | "pacing_ratio"
  | "can_be_extended"
  | "asin_numbers"
  | "last_exported"
  | "product_categories"
  | "video_product_categories"
  | "conversion_types"
  | "kpis"
  | "audience_targeting_match_type"
> & { is_draft: boolean; channel_type: StrategyChannelType };

export type StrategiesListResponse = Pagination<StrategyList>;

export type UpdateStrategyCampaignsPayload = Campaign[];

export type ArchiveCampaignsFromStrategyPayload = {
  object_ids: string[];
};

export type ArchiveAdGroupsFromStrategyPayload = {
  object_ids: string[];
};

export type CampaignsListAdGroup = { isSelected: boolean; adGroup: AdGroup };

export type CreateCampaignFormData = {
  name: string;
  goal_and_kpi: GoalAndKpiValue;
  format: Format | "";
  flight_dates: DateRange;
  market: SupportedCountry | undefined;
  budget: string;
};

export type CreateCampaignPayload = {
  name: string;
  goal_and_kpi: GoalAndKPI;
  format: Format;
  flight_dates: DateRange;
  market: SupportedCountry;
};

export type CreateCampaignResponse = CreateCampaignPayload;
export type CreateCampaignResponseError = AnyObject;

export type CreateAdGroupPayload = Pick<
  AdvancedAdGroup,
  "name" | "base_supply_bid" | "budget" | "flight_dates"
> & {
  amz_campaign_id: string;
};

export type CreateAdGroupResponse = AdGroup;
export type InventorySources = {
  amazon_exclusive: string[];
  open_exchanges: string[];
};

export type InventorySourceChoices = {
  amazon_exclusive: SupplySource[];
  open_exchanges: SupplySource[];
};
type SupplySource = {
  amz_id: string;
  name: string;
};

type CurrencyField = {
  currency: Currency;
  budget: string;
  base_supply_bid: string;
};

export type AdvancedCampaign = Omit<Campaign, "market, ad_groups"> & {
  agency_fee: string;
  bid_strategy: BidStrategy;
  auto_optimizations: AutoOptimizations;
  daily_budget_cap: number;
  monthly_budget_cap: number;
  budget_cap_method?: BudgetCapMethod;
  frequency?: Frequency;
  frequency_cap_days: number | null;
  frequency_cap_amount: number | null;
  last_fetched_data_updated_at: string;
  inventory_source: string;
  asin_numbers: string[];
  devices: string;
  mobile_os: string;
  audience_set_names: string[];
  product_location: ProductLocation;
  location: string;
  viewability: AmazonViewability;
  pacing_profile: string;
  catch_up_boost: string;
  maximum_average_cpm: string | null;
  domain: "All";
  primary_currency: CurrencyField;
  market_currency: CurrencyField;
  target_kpi: string | null;
};

export type EditCampaignFormData = Partial<
  Omit<
    AdvancedCampaign,
    "budget" | "base_supply_bid" | "flight_dates" | "target_kpi"
  >
> & {
  id: string;
  kpi?: KPIMetric;
  target_kpi: number | undefined;
  budget: {
    value: string;
    visibleValue: string;
  };
  base_supply_bid: {
    value: string;
    visibleValue: string;
  };
};

export type EditCampaignPayload = Partial<
  Omit<AdvancedCampaign, "goal_and_kpi" | "budget" | "flight_dates"> & {
    kpi: KPIMetric;
    currency: Currency;
  }
>;
export type EditCampaignResponse = CreateCampaignPayload;
export type EditCampaignResponseError = AnyObject;
export type EditCampaignStatusFormData = {
  status: CampaignDeliveryActivationStatus;
};

export type EditStrategyFormData = Partial<CreateStrategyForm>;
export type EditStrategyStatusFormData = {
  status: ActivationStatus;
};

export type SwapStrategySetPayload = {
  new_audience_set: string;
};

export type StrategyQueryParams = TableQueryParams & {
  status?: string;
  product_locations?: string;
  goal?: string;
};

export const editTypeValues = ["campaigns", "adGroups"] as const;

export type EditType = (typeof editTypeValues)[number];

export const activationStatus = ["ACTIVE", "INACTIVE"] as const;
export type ActivationStatus = (typeof activationStatus)[number];

export type StrategyFieldName = keyof Strategy | keyof StrategyMetrics;

export type BackendGroup = {
  audiences?: { amz_id: string; name: string }[];
  groups?: BackendGroup[];
  operator: LogicOperator;
};

export type AudienceRespresentationObject = {
  groupNumber: number;
  audiences: { amz_id: string; name: string }[];
  audienceOperator: LogicOperator;
  groupOperator?: LogicOperator;
};

export type ProductCategory = {
  amz_id: string;
  name: string;
  subcategories: { amz_id: string; name: string }[];
};

export type Asin = {
  value: string;
  isValid: boolean | null;
  error?: string;
};

export type InvalidAsins = {
  notExisting: Asin[]; // Asins that do not exist on Amazon
  incorrectFormat: Asin[]; // Asins that were provided in an incorrect format
};

export type AsinFile = {
  name: string;
  validAsins: Asin[];
  invalidAsins: InvalidAsins;
  error: string | null;
  isExpanded: boolean;
};

export type AsinsForm = {
  asin_numbers_input: string;
  validAsins: Asin[];
  invalidAsins: InvalidAsins;
  asin_files: AsinFile[];
};

export type StrategyFlightRangeBudgetUpdatePayload = {
  budget: string;
  currency: Currency;
};

export type CampaignFlightRangeUpdatePayload = {
  budget: string;
  currency: Currency;
};

export const flightRangeStatusValues = [
  "1_current",
  "2_scheduled",
  "3_ended",
] as const;

export type FlightRangeStatus = (typeof flightRangeStatusValues)[number];

export type FlightRangesShortInfo = {
  id: string;
  status: FlightRangeStatus;
  flight_dates: DateRange;
};

export type FlightRangesShortInfoResponse = {
  strategy_flight_dates: FlightRangesShortInfo[];
  flight_dates_count: number;
};

export type Deal = {
  external_deal_id: string;
  name: string;
};
export type DealsParams = {
  formats?: string;
  markets?: string;
};
export type DealsResponse = AxiosResponse<Deal[]>;

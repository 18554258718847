import { formatMessage } from "common/internationalization";
import { Brand } from "common/types";
import dialNumbers from "common/utils/dialNumbers.json";

export const DEFAULT_RELOAD_PAGE = "/app/strategies"; // Only used in ErrorBoundary redirect
export const DEFAULT_DATE_FORMAT = "P"; // localized date, requires locale to be set
export const DEFAULT_BACKEND_DATE_FORMAT = "yyyy-MM-dd";
export const DEFAULT_BACKEND_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const DEFAULT_BACKEND_DATETIME_FORMAT_UTC = "yyyy-MM-dd HH:mm:ss 'UTC'";
export const DEFAULT_DATE_ISO = "ISO";
export const DEFAULT_BACKEND_DATE_CHART_FORMAT = "%Y-%m-%d";
export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_MARKET = "US";

export const ERROR_MESSAGE_CLASS_NAME = "vow-error-message";

export const timezones = [
  "Pacific/Midway",
  "US/Samoa",
  "US/Hawaii",
  "US/Alaska",
  "US/Pacific",
  "America/Tijuana",
  "US/Arizona",
  "US/Mountain",
  "America/Chihuahua",
  "America/Mazatlan",
  "America/Mexico_City",
  "America/Monterrey",
  "Canada/Saskatchewan",
  "US/Central",
  "US/Eastern",
  "US/East-Indiana",
  "America/Bogota",
  "America/Lima",
  "America/Caracas",
  "Canada/Atlantic",
  "America/La_Paz",
  "America/Santiago",
  "Canada/Newfoundland",
  "America/Buenos_Aires",
  "Greenland",
  "Atlantic/Stanley",
  "Atlantic/Azores",
  "Atlantic/Cape_Verde",
  "Africa/Casablanca",
  "Europe/Dublin",
  "Europe/Lisbon",
  "Europe/London",
  "Africa/Monrovia",
  "Europe/Amsterdam",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Budapest",
  "Europe/Copenhagen",
  "Europe/Ljubljana",
  "Europe/Madrid",
  "Europe/Paris",
  "Europe/Prague",
  "Europe/Rome",
  "Europe/Sarajevo",
  "Europe/Skopje",
  "Europe/Stockholm",
  "Europe/Vienna",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Athens",
  "Europe/Bucharest",
  "Africa/Cairo",
  "Africa/Harare",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Asia/Jerusalem",
  "Europe/Kiev",
  "Europe/Minsk",
  "Europe/Riga",
  "Europe/Sofia",
  "Europe/Tallinn",
  "Europe/Vilnius",
  "Asia/Baghdad",
  "Asia/Kuwait",
  "Africa/Nairobi",
  "Asia/Riyadh",
  "Europe/Moscow",
  "Asia/Tehran",
  "Asia/Baku",
  "Europe/Volgograd",
  "Asia/Muscat",
  "Asia/Tbilisi",
  "Asia/Yerevan",
  "Asia/Kabul",
  "Asia/Karachi",
  "Asia/Tashkent",
  "Asia/Kolkata",
  "Asia/Kathmandu",
  "Asia/Yekaterinburg",
  "Asia/Almaty",
  "Asia/Dhaka",
  "Asia/Novosibirsk",
  "Asia/Bangkok",
  "Asia/Jakarta",
  "Asia/Krasnoyarsk",
  "Asia/Chongqing",
  "Asia/Hong_Kong",
  "Asia/Kuala_Lumpur",
  "Australia/Perth",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Irkutsk",
  "Asia/Seoul",
  "Asia/Tokyo",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Asia/Yakutsk",
  "Australia/Brisbane",
  "Australia/Canberra",
  "Pacific/Guam",
  "Australia/Hobart",
  "Australia/Melbourne",
  "Pacific/Port_Moresby",
  "Australia/Sydney",
  "Asia/Vladivostok",
  "Asia/Magadan",
  "Pacific/Auckland",
  "Pacific/Fiji",
] as const;

export const countries = [
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CG",
  "CD",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "KP",
  "MK",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "UM",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
] as const;

export const countryPhones: Record<
  string,
  {
    id: string;
    dial: string;
    countryName: string;
    countryCode: (typeof countries)[number];
  }
> = countries.reduce((res, c) => {
  if (!(c in dialNumbers)) return res;
  return {
    ...res,
    [c]: {
      id: c,
      dial: dialNumbers[c as keyof typeof dialNumbers],
      countryCode: c,
      countryName: formatMessage(`country.${c}`),
    },
  };
}, {});

export const metrics = [
  "fdr",
  "fdi",
  "dcpm",
  "vcpm",
  "clicks",
  "ctr",
  "impressions",
  "conversions",
  "sales",
  "roas",
  "acos",
  "pacing",
  "spend",
  "cpa",
  "cvr",
  "vr",
  "vi",
  "vcr",
  "cpvc",
  "click_throughs",
  "off_amazon_cpa",
  "off_amazon_cvr",
  "total_cost",
  "viewable_impressions",
  "ecpc",
  "ecpm",
  "total_sales",
  "t_roas",
  "c_roas",
  "cpsu",
  "cpfao",
  "dpvr",
  "cpdpv",
  "purchases",
] as const;

export const emptyBrand: Brand = {
  id: "",
  domain: "",
  brand_slug: "",
  application_name: "Service",
  application_alt_name: "",
  company_name: "",
  company_legal_name: "",
  company_address: "",
  active: false,
  logo_file_url: "",
  favicon_file_url: "",
  email_logo_file_url: "",
  login_logo_file_url: "",
  terms_of_service_file_url: "",
  support_email: "",
};

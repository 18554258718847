/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

import { ErrorsModal } from "common/types";
import { eventBus } from "common/utils/eventBus";

const hostname = import.meta.env.VITE_API_HOST;
const port = import.meta.env.VITE_API_PORT || 8000;

const baseURL = hostname
  ? `http://${hostname}:${port}`
  : window.location.origin;

const apiBaseURL = `${baseURL}`;

// TODO: find out how token will be stored and adjust axios instance
const apiInstance = axios.create({
  baseURL: apiBaseURL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
  withXSRFToken: true,
});

export const publicEndpoints = [
  "/api/login",
  "/api/reset_password",
  "/api/join",
  "/api/waffle_status",
  "/api/sentry-tunnel",
  "/api/brand/get_brand_by_domain/",
  "/api/reset/send_email/",
  "/api/reset/validate_token/",
  "/api/reset/reset_password/",
  "/api/join/validate_token/",
  "/api/join/google_sign_in/",
  "/api/join/set_password/",
  "/api/google_auth/login/",
  "/api/user",
];

export const skipErrorHandlingEndpoints = [
  "/api/sentry-tunnel",
  "/api/global-messages/",
];

apiInstance.interceptors.request.use((config) => {
  const params = new URLSearchParams(window.location.search);
  const advertiserId = params.get("aid");
  if (
    (advertiserId === "-1" || advertiserId === "") &&
    -!publicEndpoints.includes(config.url!)
  ) {
    document.dispatchEvent(new CustomEvent("VOW:accessDenied"));
    throw new axios.Cancel("Operation canceled by the user");
  }
  if (config.headers && advertiserId) {
    // eslint-disable-next-line no-param-reassign
    config.headers["Vowmade-Advertiser-Id"] = advertiserId;
  }
  if (!advertiserId && !publicEndpoints.includes(config.url!))
    throw new axios.Cancel("Operation cancelled, no advertiser");
  return config;
});

apiInstance.interceptors.response.use(undefined, (error: any) => {
  if (
    error.config?.url &&
    skipErrorHandlingEndpoints.includes(error.config.url)
  )
    return Promise.reject(error);

  if (error.response?.status === 401) {
    document.dispatchEvent(new CustomEvent("VOW:logout"));
  } else if (
    error.response?.status === 403 &&
    !publicEndpoints.some((el) => error.message.includes(el))
  ) {
    document.dispatchEvent(new CustomEvent("VOW:accessDenied"));
  } else if (error.response?.status === 404) {
    document.dispatchEvent(new CustomEvent("VOW:notFound"));
  } else if (error.response?.status === 500) {
    eventBus.openErrorModal(ErrorsModal[500]);
  } else if (error.response?.status === 429) {
    eventBus.openErrorModal(ErrorsModal[429]);
  } else if (error.response?.status === 503) {
    document.dispatchEvent(new CustomEvent("VOW:serviceUnavailable"));
  } else if (error.message === "Operation cancelled, no advertiser") return;
  else if (error.response?.status !== 400) {
    eventBus.openErrorModal(ErrorsModal.unexpectedError);
    return;
  }
  return Promise.reject(error);
});

export default apiInstance;

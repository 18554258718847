import classNames from "classnames";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Carousel } from "common/components/Carousel";
import {
  CarouselButtonNext,
  CarouselButtonPrev,
  CarouselCounter,
  CarouselSlides,
} from "common/components/Carousel/components";
import { Icon } from "common/components/Icon";

import { TGlobalMessage } from "../../../types";
import { MessageActions } from "./components";

type Props = {
  severity: "info" | "warning";
  messages: TGlobalMessage[];
};

export const MessageBar: React.FC<Props> = ({ severity, messages }) => {
  if (messages.length === 0) return <div className="hidden" />;

  return (
    <div
      data-testid={`${severity}-message-bar`}
      style={{ scrollbarGutter: "stable" }}
      className={classNames(
        "w-full bg-gradient-135 px-3 lg:px-8 py-3 flex items-center overflow-y-auto",
        {
          "from-cream-300 to-cream-400": severity === "warning",
          "from-blue-20 to-blue-30": severity === "info",
        },
      )}
    >
      <Carousel
        items={messages}
        renderSlide={(item: TGlobalMessage) => (
          <ReactMarkdown
            key={item.id}
            data-testid="global-message-copy"
            className="text-start [&>p]:overflow-hidden [&>p]:text-ellipsis [&>p]:text-blue-900 [&>p]:whitespace-nowrap"
          >
            {item.summary}
          </ReactMarkdown>
        )}
      >
        <div className="text-blue-900 ">
          <Icon name={severity} className="mr-[5px]" />
        </div>
        <div className="grow mr-[5px] w-1/2">
          <CarouselSlides />
        </div>
        <div className="mr-2.5">
          <CarouselCounter />
        </div>
        <div
          className={classNames("flex items-center gap-x-2 mr-2.5", {
            hidden: messages.length === 1,
          })}
        >
          <CarouselButtonPrev
            additionalClasses="text-blue-900"
            dataTestId="prev-global-message"
            size="small"
          >
            <Icon name="caretLeft" />
          </CarouselButtonPrev>
          <CarouselButtonNext
            additionalClasses="text-blue-900"
            dataTestId="next-global-message"
            size="small"
          >
            <Icon name="caretRight" />
          </CarouselButtonNext>
        </div>
        <MessageActions severity={severity} />
      </Carousel>
    </div>
  );
};

import { useLocation, useNavigate } from "react-router-dom";

import { getNearIndexRoute } from "App/components/AppRoutes/utils";
import { DropdownState } from "common/components/form/Dropdown/DropdownState";
import { useFormatMessage } from "common/internationalization";
import { Advertiser, UNASSIGNED_AGENCY, User } from "common/types";
import {
  getAdvertiser,
  getIsAdvertiserDropdownDisabled,
  getUser,
  useAppSelector,
} from "modules/store";

export const AdvertiserDropdown = () => {
  const user = useAppSelector(getUser);
  const advertiser = useAppSelector(getAdvertiser);
  const isAdvertiserDropdownDisabled = useAppSelector(
    getIsAdvertiserDropdownDisabled,
  );
  // use standard useNavigate here instead of useNavigateWithAdvertiser to not overwrite new advertiser with current advertiser (merging search params works so)
  const navigate = useNavigate();
  const location = useLocation();
  const formatMessage = useFormatMessage();

  /**
   * Select callback for dropdown, that updates the advertiser id in search params.
   * @param advertiser selected Advertiser option
   */
  const handleSelect = (newId: string) => {
    const newAdvertiser =
      user.advertiser_accounts.find((el) => el.id === newId) ||
      user.advertiser_accounts[0];
    // When advertiser ID changes
    if (newAdvertiser.id !== advertiser?.id) {
      // Advertiser must be on the list as it is selection from dropdown only
      const advertiserObj: Advertiser = user.advertiser_accounts.find(
        (a) => a.id === newAdvertiser.id,
      ) as Advertiser;

      const nearIndexRoute = getNearIndexRoute(location.pathname);
      if (nearIndexRoute)
        navigate(
          { pathname: nearIndexRoute, search: `?aid=${advertiserObj.id}` },
          {
            replace: true,
          },
        );
      else
        navigate(
          { pathname: location.pathname, search: `?aid=${advertiserObj.id}` },
          {
            replace: true,
          },
        );
    }
  };

  const getAdvertiserOptions = (user: User) => {
    if (user.user_type === "staff") {
      return (user.agencies || []).map((el) => ({
        label:
          el.agency.name !== UNASSIGNED_AGENCY.name
            ? el.agency.name
            : formatMessage("agency.unassigned"),
        value: el.agency.id,
        children: el.advertiser_accounts.map((a) => ({
          label: a.name,
          value: a.id,
        })),
      }));
    }
    return user.advertiser_accounts.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  };

  return (
    <div className="flex items-center self-stretch">
      <div className="h-[36px] w-[260px] bg-white-200">
        <DropdownState<string>
          selected={advertiser.id}
          setSelected={handleSelect}
          options={getAdvertiserOptions(user)}
          defaultValue={advertiser.id}
          testId="advertiser-dropdown"
          size="advertiser"
          iconLeftName="identificationCard"
          searchOff
          disabled={isAdvertiserDropdownDisabled}
          hideMainCheckboxes
        />
      </div>
    </div>
  );
};

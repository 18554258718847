import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Tooltip } from "common/components/Tooltip";
import { MessageKey, useFormatMessage } from "common/internationalization";
import {
  getHrefNavbarLink,
  preventRedirectToSamePage,
} from "common/utils/navigation";
import { clearAllTablesData, useAppDispatch } from "modules/store";

type Props = {
  name: string;
  iconSrc: string;
  iconActiveSrc: string;
  iconAlt: string;
  basicUrl: string;
  isActive: boolean;
  showName?: boolean;
  theme?: "blue" | "violet";
};

const defaults: {
  theme: "blue" | "violet";
  showName: boolean;
} = {
  theme: "blue",
  showName: false,
};

export const NavigationItem: React.FC<Props> = (initProps) => {
  const props = { ...defaults, ...initProps };
  const formatMessage = useFormatMessage();

  const title = `pageTitle.${props.name.toLowerCase()}` as MessageKey;
  const hoverColor = props.theme === "blue" ? "blue-950" : "violet-550";

  return (
    <Tooltip
      testId={title}
      placement="right"
      theme="custom"
      behaviour="sticky"
      classNames={{
        wrapper: "w-full",
        content: classNames(
          `bg-${hoverColor} rounded-none rounded-r-lg h-[44px] px-6 flex shadow-none`,
          {
            "invisible opacity-0": props.isActive,
          },
        ),
      }}
      content={
        <div
          className={`text-14 text-white bg-${hoverColor} font-bold flex items-center`}
        >
          {formatMessage(title)}
        </div>
      }
      trigger={<NavItemTooltipTrigger {...props} title={title} />}
    />
  );
};

const NavItemTooltipTrigger: React.FC<Props & { title: MessageKey }> = (
  props,
) => {
  const dispatch = useAppDispatch();
  const formatMessage = useFormatMessage();

  return (
    <Link
      to={getHrefNavbarLink(props.basicUrl)}
      onClick={(e) => {
        preventRedirectToSamePage(e, props.basicUrl);
        dispatch(clearAllTablesData());
      }}
      className={classNames("flex gap-x-2 py-3 items-center", {
        "hover:bg-blue-950": !props.isActive && props.theme === "blue",
        "hover:bg-violet-550": !props.isActive && props.theme === "violet",
      })}
      data-testid={`nav-option-${props.name.toLowerCase()}`}
    >
      <div
        className={classNames("w-full h-full flex justify-center items-center")}
      >
        <div className="w-5 h-5">
          <img
            src={props.isActive ? props.iconActiveSrc : props.iconSrc}
            alt={props.iconAlt}
          />
        </div>
        {props.showName && (
          <h2 className="font-bold text-16 leading-[2.25rem]">
            {formatMessage(props.title)}
          </h2>
        )}
      </div>
    </Link>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import classNames from "classnames";
import { useContext, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";

import { ModalProps, ModalLayout } from "common/components/layout/components";
import { ReactPortal } from "common/components/ReactPortal";

import { ModalContext } from "../context";
import { ModalFooter } from "./ModalFooter";

import "./ModalContent.scss";

export type ModalContentProps = ModalProps & {
  wrapperId: string;
  closeOnBackdropClick?: boolean;
  hideBackdrop?: boolean;
  footer?: JSX.Element;
  hidden?: boolean;
};

export const ModalContent: React.FC<ModalContentProps> = ({
  wrapperId,
  closeOnBackdropClick = false,
  hideBackdrop = false,
  footer,
  hidden = false,
  alwaysFullWidth = false,
  alwaysFullHeight = false,
  ...modalProps
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const { show, setShow, onClose, onCloseForce } = useContext(ModalContext);

  useEffect(() => {
    setTimeout(() => setShow(true));
  }, []);

  return (
    <ReactPortal wrapperId={wrapperId}>
      <CSSTransition
        in={show && !hidden}
        timeout={{ enter: 0, exit: 200 }}
        classNames="modal"
        onExited={onCloseForce}
        nodeRef={nodeRef}
      >
        <div
          onClick={() => closeOnBackdropClick && onClose()}
          data-testid="modal-backdrop"
          className={classNames(
            "absolute top-0 left-0 w-screen h-screen flex items-center justify-center z-[1000] px-[3vw] py-[3vh] overflow-hidden",
            {
              "bg-gray-900-t-50": !hideBackdrop,
              "cursor-pointer": closeOnBackdropClick,
              hidden,
            },
          )}
        >
          <div
            ref={nodeRef}
            className={classNames("modal", {
              "w-full": alwaysFullWidth,
              "h-full": alwaysFullHeight,
            })}
            data-testid="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <ModalLayout
              footer={
                footer || (
                  <div className="pt-[5vh] px-6 pb-6">
                    <ModalFooter fullWidth />
                  </div>
                )
              }
              {...modalProps}
              alwaysFullWidth
              alwaysFullHeight={alwaysFullHeight}
            />
          </div>
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

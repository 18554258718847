/* eslint-disable @typescript-eslint/no-unused-vars */
import { Square } from "@phosphor-icons/react";

import { colors } from "common/styles/colors";

import { CheckedProps } from "../types";

export const CheckboxUnChecked = ({
  checkBgColor,
  checkColor,
  checkBorderColor = colors.blue[700],
  ...props
}: CheckedProps) => (
  <div className="relative">
    <Square size={24} weight="thin" color={checkBorderColor} {...props} />
  </div>
);

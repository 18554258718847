import Lottie from "react-lottie";

import loading from "./loading.json";

export const LoadingPage = () => (
  <div className="w-screen h-screen flex justify-center items-center">
    <Lottie
      isClickToPauseDisabled
      options={{
        loop: true,
        autoplay: true,
        animationData: loading,
      }}
      height={120}
      width={120}
    />
  </div>
);

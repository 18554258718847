import { createSafeContext } from "common/utils/createSafeContext";

import { TCarouselContext } from "./types";

export const [CarouselContext, useCarouselContext] =
  createSafeContext<TCarouselContext>("Carousel", {
    items: [],
    renderSlide: () => <div />,
    selectedIndex: 0,
    setSelectedIndex: () => {},
  });

/* eslint-disable @typescript-eslint/no-explicit-any */

import classNames from "classnames";
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
} from "react-hook-form";

import { ERROR_MESSAGE_CLASS_NAME } from "common/constants";
import {
  useFormatMessage,
  messageKeys,
  MessageKey,
} from "common/internationalization";

const ERROR_MESSAGES: Record<string, MessageKey> = {
  required: "common.error.input.required",
  pattern: "common.error.input.validation.incorrectPattern",
  videoOneCategory: "common.error.videoFormatExactOneProductCategory",
};

function formatErrors(errors: string[]) {
  return (
    <>
      {errors.map((error) => (
        <div key={error}>{error}</div>
      ))}
    </>
  );
}

export const getMessage = (errors: FieldValues | string[]) => {
  if (Array.isArray(errors)) {
    return formatErrors(errors);
  }
  if (errors.message) {
    if (Array.isArray(errors.message)) {
      return formatErrors(errors.message);
    }
    return errors.message;
  }
  if (errors.type in ERROR_MESSAGES) {
    return ERROR_MESSAGES[errors.type];
  }
  return "";
};

type FormErrorProps = {
  name: string;
  errors?:
    | FieldError
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined
    | string
    | string[];
  classname?: string;
};

export const FormError = ({
  name,
  errors,
  classname = "text-10 leading-5 text-left text-red-100 m-0 min-h-4",
}: FormErrorProps) => {
  const formatMessage = useFormatMessage();

  if (!errors) {
    return <span className="h-3" />;
  }

  const errs = typeof errors === "string" ? errors : getMessage(errors);

  return (
    <div
      data-testid={`${name}-error-message`}
      className={classNames(ERROR_MESSAGE_CLASS_NAME, classname)}
      style={{ minHeight: "12px" }}
    >
      {messageKeys.includes(errs) ? formatMessage(errs) : errs}
    </div>
  );
};

/* eslint-disable react/jsx-no-useless-fragment */
import { PropsWithChildren } from "react";

import { useFeature } from "common/hooks/useFeature";
import { useUserPermissions } from "common/hooks/useUserPermissions";
import { FeatureName } from "common/types";
import { getAdvertiser, useAppSelector } from "modules/store";

import { Scope } from "./types";

type Props = PropsWithChildren & {
  scopes?: Scope[];
  flagName?: FeatureName;
  replace?: JSX.Element;
  disabled?: boolean;
  hideForDisabledAdvertiser?: boolean;
};

export const RestrictedDisplay: React.FC<Props> = ({
  children,
  scopes,
  flagName,
  replace,
  disabled = false,
  hideForDisabledAdvertiser,
}) => {
  const featureFlag = useFeature(flagName, !flagName);
  const advertiser = useAppSelector(getAdvertiser);
  const permissionGranted = useUserPermissions(scopes);

  if (
    !permissionGranted ||
    !featureFlag ||
    disabled ||
    (hideForDisabledAdvertiser && advertiser?.status === "Disabled")
  ) {
    if (replace) return <>{replace}</>;
    return <></>;
  }

  return <>{children}</>;
};

import { get, patch, post, remove } from "common/api";
import {
  EditCampaignFlightPayload,
  EditCampaignFlightResponse,
  EditFlightMarketBudgetPayload,
  EditFlightMarketBudgetResponse,
  GetStrategyFlightRangeQueryParams,
  GetStrategyFlightRanges,
  StrategyFlightRange,
  StrategyFlightRangeAdd,
} from "modules/Strategies/SingleStrategy/tabs/SingleStrategyPlanner/types";

export const getFlightRanges = (
  strategyId: string,
  params: GetStrategyFlightRangeQueryParams,
) =>
  get<GetStrategyFlightRanges>(`/strategies/${strategyId}/flight-ranges/`, {
    params,
  });

export const addFlightRange = (params: {
  strategyId: string;
  payload: StrategyFlightRangeAdd;
}) =>
  post<StrategyFlightRange, StrategyFlightRangeAdd>(
    `/strategies/${params.strategyId}/flight-ranges/`,
    params.payload,
  );

export const editFlightRange = (params: {
  strategyId: string;
  flightRangeId: string;
  payload: Partial<StrategyFlightRangeAdd>;
}) =>
  patch<StrategyFlightRange, Partial<StrategyFlightRangeAdd>>(
    `/strategies/${params.strategyId}/flight-ranges/${params.flightRangeId}/`,
    params.payload,
  );

export const deleteFlightRange = (params: {
  strategyId: string;
  flightRangeId: string;
}) =>
  remove(
    `/strategies/${params.strategyId}/flight-ranges/${params.flightRangeId}/`,
  );

export type EditCapaignFlightRequestParams = {
  flightId: string;
  payload: EditCampaignFlightPayload;
};
export const editCampaignFlight = (params: EditCapaignFlightRequestParams) =>
  patch<EditCampaignFlightResponse, EditCampaignFlightPayload>(
    `/campaign-flight-ranges/${params.flightId}/`,
    params.payload,
  );

export type EditFlightMarketBudgetRequestParams = {
  strategyId: string;
  flightId: string;
  payload: EditFlightMarketBudgetPayload;
};
export const editFlightMarketBudget = (
  params: EditFlightMarketBudgetRequestParams,
) =>
  patch<EditFlightMarketBudgetResponse, EditFlightMarketBudgetPayload>(
    `/strategies/${params.strategyId}/flight-ranges/budget/${params.flightId}/`,
    params.payload,
  );

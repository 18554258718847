import { IconName } from "common/components/Icon";
import { formatMessage } from "common/internationalization";

import { Goals, Metrics } from "./types";

export const metricTabs = [
  {
    name: Goals.AWARENESS,
    label: formatMessage(`strategy.goals.${Goals.AWARENESS}`),
    icon: Goals.AWARENESS as IconName,
  },
  {
    name: Goals.CONSIDERATION,
    label: formatMessage(`strategy.goals.${Goals.CONSIDERATION}`),
    icon: Goals.CONSIDERATION as IconName,
  },
  {
    name: Goals.CONVERSION,
    label: formatMessage(`strategy.goals.${Goals.CONVERSION}`),
    icon: Goals.CONVERSION as IconName,
  },
];

// ADSP Default Metrics
// https://docs.google.com/spreadsheets/d/1x4rOZCMeneSy2StNztQBIwtQBr1pbjPmMzNMKspzLpg/edit?pli=1&gid=0#gid=0

const defaultTableMetricsShared = [
  Metrics.IMPRESSIONS,
  Metrics.VR,
  Metrics.ECPM,
  Metrics.CTR,
  Metrics.DPVR,
];

const defaultTableMetricsAwareness = [
  ...defaultTableMetricsShared,
  Metrics.VCR,
];

const defaultTableMetricsConversion = [
  ...defaultTableMetricsShared,
  Metrics.ROAS,
  Metrics.OFF_AMAZON_ROAS,
  Metrics.PRODUCT_SALES,
  Metrics.OFF_AMAZON_PRODUCT_SALES,
  Metrics.OFF_AMAZON_PURCHASES,
  Metrics.PURCHASES,
  Metrics.OFF_AMAZON_PURCHASES_CPA,
];

const defaultTableMetricsConsideration = [
  ...defaultTableMetricsShared,
  Metrics.VCR,
];

export const tableMetricValuesMap = {
  [Goals.AWARENESS as string]: defaultTableMetricsAwareness,
  [Goals.CONVERSION as string]: defaultTableMetricsConversion,
  [Goals.CONSIDERATION as string]: defaultTableMetricsConsideration,
};

export const defaultPodMetrics = [
  Metrics.IMPRESSIONS,
  Metrics.CLICK_THROUGHS,
  Metrics.PURCHASES,
  Metrics.ECPM,
  Metrics.ECPC,
  Metrics.CTR,
  Metrics.ROAS,
];

export const defaultGraphMetrics = [
  Metrics.SALES,
  Metrics.ROAS,
  Metrics.ACOS,
  Metrics.TOTAL_COST,
];

export const CURRENCY_METRICS = [
  Metrics.SALES,
  Metrics.TOTAL_COST,
  Metrics.TOTAL_SALES,
  Metrics.COMBINED_PRODUCT_SALES,
  Metrics.OFF_AMAZON_PRODUCT_SALES,
  Metrics.PRODUCT_SALES,
  Metrics.CPA,
  Metrics.CPSU,
  Metrics.CPVC,
  Metrics.ECPM,
  Metrics.ECPC,
  Metrics.CPDPV,
  Metrics.NEW_TO_BRAND_SALES,
  Metrics.ADDED_TO_SHOPPING_CART_CPA,
  Metrics.APPLICATION_CPA,
  Metrics.CHECKOUT_CPA,
  Metrics.CONTACT_CPA,
  Metrics.LEAD_CPA,
  Metrics.OFF_AMAZON_PURCHASES_CPA,
  Metrics.PAGE_VIEW_CPA,
  Metrics.SEARCH_CPA,
  Metrics.SIGN_UP_CPA,
  Metrics.SUBSCRIBE_CPA,
];

export const PERCENTAGE_METRICS = [
  Metrics.ACOS,
  Metrics.DPVR,
  Metrics.CVR,
  Metrics.CTR,
  Metrics.VCR,
  Metrics.VR,
];

export const NUMBER_METRICS = [
  Metrics.IMPRESSIONS,
  Metrics.CLICK_THROUGHS,
  Metrics.PURCHASES,
  Metrics.TOTAL_PURCHASES,
  Metrics.COMBINED_PURCHASES,
  Metrics.OFF_AMAZON_PURCHASES,
  Metrics.CONVERSIONS,
  Metrics.DETAIL_PAGE_VIEWS,
  Metrics.VIDEO_START,
  Metrics.VIDEO_COMPLETE,
  Metrics.VIEWABLE_IMPRESSIONS,
  Metrics.MEASURABLE_IMPRESSIONS,
  Metrics.SIGN_UP,
  Metrics.UNITS_SOLD,
  Metrics.TOTAL_UNITS_SOLD,
  Metrics.COMBINED_UNITS_SOLD,
  Metrics.NEW_TO_BRAND_UNITS_SOLD,
  Metrics.TOTAL_NEW_TO_BRAND_UNITS_SOLD,
  Metrics.OFF_AMAZON_UNITS_SOLD,
  Metrics.NEW_TO_BRAND_PURCHASES,
  Metrics.PAGE_VIEW,
  Metrics.ADDED_TO_SHOPPING_CART,
  Metrics.SEARCH,
  Metrics.CHECKOUT,
  Metrics.APPLICATION,
  Metrics.SUBSCRIBE,
  Metrics.CONTACT,
  Metrics.LEAD,
  Metrics.ROAS,
  Metrics.T_ROAS,
  Metrics.C_ROAS,
  Metrics.OFF_AMAZON_ROAS,
  Metrics.NEW_TO_BRAND_ROAS,
  Metrics.TOTAL_NEW_TO_BRAND_ROAS,
];

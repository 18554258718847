export const getElementText: (el: JSX.Element) => string = (el) => {
  const element = el as unknown as HTMLElement;
  if (element && element.textContent?.trim())
    return element.textContent?.trim();
  if (el && el?.props?.children) {
    if (typeof el.props.children === "string") {
      return el.props.children.trim();
    }
    if (Array.isArray(el.props.children)) {
      [...el.props.children].forEach((child) => {
        if (typeof child === "string") return child;
        return getElementText(child as unknown as JSX.Element);
      });
    }
    return getElementText(el.props.children as unknown as JSX.Element);
  }
};

import { forwardRef } from "react";

import { GenericPopover } from "./components";
import { PopoverInstanceProps, PopoverRef } from "./types";

export const OptionsPopover = forwardRef<PopoverRef, PopoverInstanceProps>(
  (props, ref) => (
    <GenericPopover
      {...props}
      innerRef={ref}
      popover={
        <div className="drop-shadow-md bg-white rounded-4px">
          {props.popover}
        </div>
      }
    />
  ),
);

import { IconProps } from "@phosphor-icons/react";

import { countries } from "common/constants";
import { Country } from "common/types";

import { CountryIcon } from "./components";
import { icons } from "./icons";
import { CheckedProps } from "./types";

export type IconName = keyof typeof icons;

export type IconSize =
  | "xxxsmall"
  | "xxsmall"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "checkbox"
  | "xxlarge";

const getIconSizeToPx = (size: IconSize): number => {
  switch (size) {
    case "xxxsmall":
      return 10;
    case "xxsmall":
      return 12;
    case "xsmall":
      return 14;
    case "small":
      return 16;
    case "medium":
      return 18;
    case "large":
      return 20;
    case "xlarge":
      return 24;
    case "checkbox":
      return 26.67;
    case "xxlarge":
      return 32;
    default:
      return 18;
  }
};

export interface IIcon extends CheckedProps {
  name: IconName | Country;
  alt?: string;
  size?: IconSize;
  color?: string;
}

/**
 * This is main icon component, that should be used for every icon usage.
 * You can easly extend this component with more photoshor icons,
 * by updating icons object.
 */
export const Icon: React.FC<IIcon> = ({
  name,
  alt,
  size = "medium",
  ...iconProps
}) => {
  if (countries.includes(name as Country)) {
    return <CountryIcon name={name as Country} alt={alt} {...iconProps} />;
  }

  const PhosphorIcon: React.FC<IconProps> = icons[name as IconName];
  return <PhosphorIcon alt={alt} size={getIconSizeToPx(size)} {...iconProps} />;
};

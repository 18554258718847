import classNames from "classnames";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { useHandleClickOutside } from "common/hooks/useHandleClickOutside";

import { PopoverContextProvider } from "../PopoverContextProvider";
import { PopoverProps } from "../types";
import { PopoverPortalWrapper } from "./PopoverPortalWrapper";

export const GenericPopover: React.FC<PopoverProps> = ({
  innerRef,
  reference,
  popover,
  testId,
  placement = "bottom-start",
  referenceStyling = "",
  offset,
  onChange,
  disabled = false,
  ...wrapperProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const referenceRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const [popoverPlacementState, setPopoverPlacementState] = useState<{
    visibility: "hidden" | "visible";
    placementUpdated: boolean;
  }>({ visibility: "hidden", placementUpdated: false });
  const { styles, attributes, update, state } = usePopper(
    referenceRef.current,
    listRef.current,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset,
          },
        },
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            boundary: document.querySelector("#root") || document.body,
            padding: 15,
          },
        },
      ],
    },
  );
  useHandleClickOutside(listRef, () => togglePopover(false), [referenceRef]);

  useImperativeHandle(innerRef, () => ({
    openPopover() {
      togglePopover(true);
    },
    closePopover() {
      togglePopover(false);
    },
  }));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (update) {
      // needs to be wrapped in timeout to be rendered after preventOverflow calculations
      timeout = setTimeout(() => {
        update();
      }, 10); // 10 due to safari related problems
    }
    if (popoverPlacementState.placementUpdated) {
      // show popover once placement is marked as updated
      setPopoverPlacementState({
        placementUpdated: true,
        visibility: "visible",
      });
    } else if (isOpen) {
      // updating the placement in the separate render gives rerender to update placement of the popover properly before making it visible
      setPopoverPlacementState((prev) => ({
        ...prev,
        placementUpdated: !!state?.placement,
      }));
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    update,
    isOpen,
    listRef.current?.clientWidth,
    listRef.current?.clientHeight,
  ]);

  const togglePopover = (value?: boolean) => {
    const newValue = value !== undefined ? value : !isOpen;
    if (onChange) onChange(newValue);
    setPopoverPlacementState({
      placementUpdated: false,
      visibility: "hidden",
    });
    setIsOpen(newValue);
  };

  return (
    <PopoverContextProvider isOpen={isOpen} togglePopover={togglePopover}>
      <div className={referenceStyling} ref={referenceRef}>
        <button
          className={classNames("w-full h-full flex", {
            "border-blue-500": isOpen,
            "cursor-not-allowed": disabled,
          })}
          data-testid={`toggle-popover-${testId}`}
          id={`toggle-popover-${testId}`}
          onClick={(e) => {
            e.stopPropagation();
            togglePopover();
          }}
          disabled={disabled}
        >
          {reference}
        </button>
      </div>
      <PopoverPortalWrapper
        isOpen={isOpen}
        popoverWrapperId={`react-portal-popover-${testId}`}
        popoverRef={listRef}
        popoverStyles={styles}
        popoverAttributes={attributes}
        {...wrapperProps}
      >
        <div
          data-testid={`content-popover-${testId}`}
          style={{
            minWidth: referenceRef.current?.clientWidth,
            visibility: popoverPlacementState.visibility,
          }}
        >
          {popover}
        </div>
      </PopoverPortalWrapper>
    </PopoverContextProvider>
  );
};

import * as routes from "./routes";

export const getPathEnd = (path: string) => path.split("/").pop();

export const getRelativePath = (paths: { path: string; basePath: string }) => {
  let relativePath = paths.path.replace(paths.basePath, "");
  if (relativePath[0] === "/") relativePath = relativePath.slice(1);
  return relativePath;
};

export const getNearIndexRoute = (path: string) => {
  if (!path.startsWith("/")) {
    throw Error("Path must starts with '/'");
  }
  const [, root] = path.split("/", 2);
  let matchingRoute = "";

  switch (`/${root}`) {
    case routes.DASHBOARD_ROOT: {
      routes.dashboardIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    case routes.STRATEGY_ROOT: {
      routes.strategyIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    case routes.AUDIENCE_ROOT: {
      routes.audienceIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    case routes.CREATIVE_ROOT: {
      routes.creativeIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    case routes.REPORTING_ROOT: {
      routes.reportingIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    case routes.PROFILE_ROOT: {
      routes.profileIndexRoutes.forEach((indexRoute) => {
        if (path.startsWith(indexRoute)) {
          matchingRoute = indexRoute;
          return;
        }
      });
      break;
    }
    default: {
      throw Error(
        `Seems '${path}' doesn't match any route that might include index page.`,
      );
    }
  }
  return matchingRoute;
};
